<template>
	<div class="login" :class="{ 'active-form': active_form, 'has-default-room': $route.params.room_code }">

		<div class="version"><span class="label">Version:</span> {{ version }}</div>

		<div class="head">
			<p class="text">Who will you be playing as?</p>
		</div>
		
		<transition name="form-slide-up">
			<form v-show="active_form" class="form" :class="{ error: error }" @submit.prevent="submit()">
				<!-- <div v-if="active_form == 'game-master'" class="lines lines-game-master">
					<div class="line">
						<label for="" class="label">
							<span v-if="forgot_password">Email</span>
							<span v-else>Username</span>
						</label>
						<ui-input v-model="username" placeholder="Example : john@doe.com" />
						<p v-if="errors.username" class="error-message">{{ errors.username }}</p>
					</div>
					<div v-show="!forgot_password" class="line">
						<label for="" class="label">Password</label>
						<ui-input v-model="password" type="password" placeholder="Your private password" />
						<p v-if="errors.password" class="error-message">{{ errors.password }}</p>
					</div>
					<div v-show="!forgot_password" class="line forgot-password">
						<span class="link" v-hammer:tap="() => forgot_password = !forgot_password">Forgot password?</span>
					</div>
					<div v-show="forgot_password" class="line help">
						<p>
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-help-circle"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
							You will receive a request to change your password by email.
						</p>
					</div>
				</div> -->
				<div v-if="active_form == 'player'" class="lines lines-player">
					<div class="line">
						<label v-if="$route.params.room_code" for="" class="label">Type in your login ID</label>
						<label v-else for="" class="label">Login ID</label>
						<ui-input v-model="student_number" placeholder="Example : 1234" autofocus />
						<p v-if="errors.student_number" class="error-message">{{ errors.student_number }}</p>
					</div>
					<div class="line line-room-code">
						<label for="" class="label">Room code</label>
						<ui-input v-model="room_code" placeholder="Example : ABCDEFG" />
						<p v-if="errors.room_code" class="error-message">{{ errors.room_code }}</p>
					</div>
				</div>
				<div class="buttons">
					<button type="button" v-show="forgot_password" class="button active red" v-hammer:tap="() => forgot_password = false">Cancel</button>
					<button type="submit" class="button" :class="{ loading, active: active_button }">Submit</button>
				</div>
				<div v-if="forgot_password_sended" class="forgot-password-sended">
					<div class="sended-title">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
						<p>Sent</p>
					</div>
					<p>Check your email inbox to change your password.</p>
				</div>
				<div class="close" v-hammer:tap="() => select_form('')"><ui-icon name="close" :size="16" /></div>
			</form>
		</transition>

		<div class="hexagons">
			<div class="hex player" :class="{ active: active_form == 'player' }" v-hammer:tap="() => select_form('player')">
				<svg class="hexagon" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path fill="currentColor" d="m485.291 129.408-224-128c-3.285-1.877-7.296-1.877-10.581 0l-224 128c-3.328 1.899-5.376 5.44-5.376 9.259v234.667c0 3.819 2.048 7.36 5.376 9.259l224 128c1.643.939 3.456 1.408 5.291 1.408s3.648-.469 5.291-1.408l224-128c3.328-1.899 5.376-5.44 5.376-9.259v-234.667c-.001-3.819-2.049-7.36-5.377-9.259z"/></g></svg>
				<ui-icon class="icon-default" name="login-player" :size="96" />
				<span class="text">Participant</span>
			</div>
			<a :href="dashboardUrl" class="hex game-master" :class="{ active: active_form == 'game-master' }">
				<svg class="hexagon" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path fill="currentColor" d="m485.291 129.408-224-128c-3.285-1.877-7.296-1.877-10.581 0l-224 128c-3.328 1.899-5.376 5.44-5.376 9.259v234.667c0 3.819 2.048 7.36 5.376 9.259l224 128c1.643.939 3.456 1.408 5.291 1.408s3.648-.469 5.291-1.408l224-128c3.328-1.899 5.376-5.44 5.376-9.259v-234.667c-.001-3.819-2.049-7.36-5.377-9.259z"/></g></svg>
				<ui-icon class="icon-default" name="login-game-master" :size="96" />
				<span class="text">Facilitator</span>
			</a>
		</div>

		<div class="footer">
			<span class="link" v-hammer:tap="() => goto('teacher-signup')">Register for an account</span>
			<div class="separator">•</div>
			<span class="link" v-hammer:tap="() => soloGamesVisible = true">Play solo</span>
			<div class="separator">•</div>
			<a class="link" href="https://ithrivegames.org/" target="blank">I'd like to learn more</a>
			<div class="separator">•</div>
			<a class="link" href="https://ithrivegames.org/privacy-policy/" target="blank">Privacy Policy</a>
		</div>

		<transition name="modal-solo-games">
			<div v-if="soloGamesVisible" class="modal-solo-games">
				<div class="bg" v-hammer:tap="() => soloGamesVisible = false"></div>
				<div class="modal-solo-games-content">
					<h1 class="title">Select a scenario</h1>
			
					<div class="scenarios">
						<div class="scenario">
							<div class="name" v-hammer:tap="() => $router.push('/selling-dreams')">Selling Dreams</div>
						</div>
						<div class="scenario">
							<div class="name" v-hammer:tap="() => $router.push('/disastermind')">Disaster Mind</div>
						</div>
						<div class="scenario">
							<div class="name" v-hammer:tap="() => $router.push('/jury-duty')">Jury Duty</div>
						</div>
					</div>
			
					<div class="close" v-hammer:tap="() => soloGamesVisible = false"><ui-icon name="close-blue-dark" :size="16" /></div>
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import UiInput from '@/components/ui/ui-input'

export default {
	name: 'login',
	data() {
		return {
			loading: false,
			forgot_password: false,
			forgot_password_sended: false,
			active_form: '',
			error: false,
			errors: { username: "", password: "", student_number: "", room_code: "" },
			student_number: '',
			room_code: '',
			username: '',
			password: '',
			soloGamesVisible: false,
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		version() {
			return process.env.VUE_APP_VERSION
		},
		dashboardUrl() {
			return process.env.VUE_APP_DASHBOARD_URL
		},
		active_button() {
			if (this.active_form == 'player')
				return (this.student_number && this.room_code)
			else if (this.active_form == 'game-master') {
				if (this.forgot_password) {
					return this.username !== ''
				}
				return (this.username && this.password)
			}

			return false
		}
	},
	watch: {
		$route() {
			if (this.$route.params.room_code) {
				this.active_form = 'player'
				this.room_code = this.$route.params.room_code
			}
		},
		active_form() {
			if (this.active_form) {
				this.loading = false
				this.forgot_password = false
				setTimeout(() => {
					let el = this.$el.querySelector('.form .lines-' + this.active_form + ' .line:first-child input')
					if (el) el.focus()
				}, 100)
			}
		},
		forgot_password() {
			this.loading = false
			this.forgot_password_sended = false
			setTimeout(() => {
				let el = this.$el.querySelector('.form .lines-' + this.active_form + ' .line:first-child input')
				if (el) el.focus()
			}, 10)
		}
	},
	methods: {
		select_form(form) {
			this.active_form = form
		},
		async submit() {
			if (this.forgot_password) {
				this.loading = true
				let r = await Apollo.mutate('START_RESET_PASSWORD', { to: this.username })
				if (r) {
					this.forgot_password_sended = true
				}
				this.loading = false
			}

			else {
				this.errors = { username: "", password: "", student_number: "", room_code: "" }
				// this.$store.dispatch('set_app', { loading: true })

				let values = { type: this.active_form }

				if (this.active_form == 'player') {
					values.student_number = this.student_number
					values.room_code = this.room_code
				}
				else if (this.active_form == 'game-master') {
					values.username = this.username
					values.password = this.password
				}

				this.loading = true
				let r = await this.$store.dispatch('login', values)
				if (r.status == 'error') {
					this.errors[r.field] = r.message
				}
				this.loading = false
			}
		},
		goto(name) {
			this.$router.push({ name })
		}
	},
	created() {
		if (this.$route.params.room_code) {
			this.active_form = 'player'
			this.room_code = this.$route.params.room_code
		}
		if (this.$route.params.student_number) {
			this.active_form = 'player'
			this.student_number = this.$route.params.student_number
			if (this.room_code && this.student_number) {
				this.submit()
			}
		}
	},
	components: { UiInput }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


#app.tablet-vertical
	.login .head .text
		font-size 3.2rem
			
#app.mobile
	.login
		transform translateY(40px)
		&.active-form
			.hexagons
				.hex
					display none
					&.active
						display flex
						transform translate(0, -200px)
						
						svg
							transform scale(0.9)
							color var(--color-login-hexagon-hover)
						.ui-icon
							transform translateY(8px) scale(0.9)
		.form
			left calc(50% - 160px)
			max-width 320px
			padding 64px 24px 24px 24px
		.head
			margin 0 0 300px 0
			.text
				font-size 2.8rem
				white-space normal
				line-height 32px
		.hexagons
			.hex
				left calc(50% - 90px)
				top calc(50% - 90px)
				width 180px
				&.player
					transform translate(-90px, 0)
				&.game-master
					transform translate(90px, 0)
				.text
					font-size 1.4rem
					margin 0 0 16px 0
				.ui-icon
					transform scale(0.9)
		.footer
			transform translate(-50%, -40px)
#app.not-touch
	.login
		.hexagons
			.hex:hover svg
				color var(--color-login-hexagon-hover)


.form-slide-up-enter-active
	transition 0.25s easeOutQuart
.form-slide-up-leave-active
	transition 0.1s easeOutQuart
.form-slide-up-enter, .form-slide-up-leave-to
	opacity 0
	transform translateY(40px)


.login
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	background-color var(--color-bg)
	// background-color c(bg)
	font-size 1.6rem
	flex center center
	flex-direction column
	align-content center
	&.active-form
		.head
			opacity 0
		.hexagons
			.hex
				display none
				pointer-events none
				&.active
					display flex
					transform translate(0, -200px)
					
					svg
						transform scale(0.9)
						color var(--color-login-hexagon-hover)
					.text
						opacity 1
					.ui-icon
						transform translateY(16px)
	&.has-default-room
		.form .line.line-room-code
			display none
	.version
		position absolute
		left 16px
		top 16px
		text-transform uppercase
		opacity 0.5
		font-size 1.4rem
		font-weight 700
		font-family var(--josefin)
		user-select none
		color var(--color-primary)
		.label
			font-size 1.2rem
	.head
		margin 0 0 420px 0
		text-align center
		transiton opacity 0.25s easeOutQuart
		.text
			margin 0 0 32px 0
			font-size 4.4rem
			font-family var(--josefin)
			text-transform uppercase
			line-height 54px
			white-space nowrap
			color var(--color-primary)
			&:before
				content ''
				position absolute
				bottom -16px
				left 20%
				right 20%
				height 2px
				background-color var(--color-primary)
			
	.hexagons
		position absolute
		left 0
		right 0
		top 50%
		width 100%
		height 0
		flex center center
		.hex
			position absolute
			left calc(50% - 120px)
			top calc(50% - 120px)
			width 240px
			height @width
			flex center center
			flex-direction column
			// background url(../assets/img/hexagon.svg) center center no-repeat
			// background-size 100% auto
			transition transform 0.25s easeOutQuart
			cursor pointer
			&.player
				transform translate(-120px, 0)
			&.game-master
				transform translate(120px, 0)
			&.active
				cursor default
			.hexagon
				absolute 0
				width 100%
				height 100%
				color var(--color-login-hexagon-bg)
			.text
				display block
				margin 16px 0 0 0
				font-weight 700
				text-transform uppercase
				font-size 2rem
				color var(--color-login-hexagon-text)
				font-family var(--josefin)
				text-align center
			svg
				transition transform 0.25s easeOutQuart
			.ui-icon
				color var(--color-login-hexagon-text)
				transition transform 0.25s easeOutQuart
			
	.form
		position absolute
		left calc(50% - 175px)
		top calc(50% - 160px)
		// left 50%
		// top 50%
		width 350px
		// transform translate(-50%, -50%)
		// height ((64px + 16px) * 2 + 48px + 64px + 40px)
		padding 64px 40px 40px 40px
		background var(--color-login-form-bg)
		box-shadow 0 0 0 2px var(--color-login-form-border)
		color var(--color-login-form-text)
		border-radius 8px
		&.error
			box-shadow 0 0 0 4px var(--color-error)
			animation shake 0.25s infinite
		.line
			margin 0 0 16px 0
			.error-message
				margin 4px 0 0 0
				color var(--color-error)
				font-size 1.2rem
				line-height 14px
				font-weight 700
				text-transform uppercase
			&.forgot-password
				text-align right
				font-size 1.4rem
				line-height 1em
				.link
					cursor pointer
					opacity 0.5
					&:hover
						opacity 1
			&.help
				user-select none
				p
					padding-left 34px
					font-size 14px
					color var(--color-text)
					line-height 17px
					>>> svg
						position absolute
						left 0
						top calc(50% - 14px)
						width 28px
						height 28px
		.label
			display block
			width 100%
			line-height 24px
			// opacity 0.5
			font-family var(--josefin)
			color var(--color-login-label-text)
		.ui-input
			background-color var(--color-input-text-bg)
			height 40px
			padding 0 16px
			color var(--color-input-text-text)
			border-radius 4px
			&:focus
				background-color var(--color-input-text-bg)
			&::-webkit-input-placeholder
				color var(--color-input-text-text)
				opacity 0.25
			&::-moz-placeholder
				color var(--color-input-text-text)
				opacity 0.25
			&:-ms-input-placeholder
				color var(--color-input-text-text)
				opacity 0.25
			&:-moz-placeholder
				color var(--color-input-text-text)
				opacity 0.25
		.buttons
			padding 8px 0 0 0
			flex center center
			.button
				display inline-block
				height 40px
				padding 0 16px
				margin 0 4px
				border none
				background-color var(--color-button-disabled)
				border-radius 4px
				outline none
				cursor not-allowed
				text-transform uppercase
				font inherit
				color var(--color-button-disabled-text)
				font-size 1.6rem
				font-weight 700
				font-family var(--josefin)
				line-height (@height + 3px)
				&.active
					background var(--color-button-bg)
					color var(--color-button-text)
					cursor pointer
				&.red
					background-color var(--color-button-error)
					color var(--color-button-error-text)
				&:active
					transform translateY(1px)
				&.loading
					overflow hidden
					cursor not-allowed
					&:before
						opacity 1
				&:before
					content ''
					opacity 0
					absolute 0
					background var(--color-button-bg) url(../assets/img/button-loader.svg) center center no-repeat
					background-size auto 8px
		.forgot-password-sended
			absolute 24px
			background-color @background
			flex center center
			flex-direction column
			text-align center
			line-height 1.2em
			color gray_light
			.sended-title
				flex center center
				flex-direction column
				color var(--color-text)
				font-size 24px
				line-height 1em
				font-family var(--josefin)
				text-transform uppercase
				>>> svg
					width 40px
					height 40px
					margin 0 0 8px 0
				> p
					margin 0 0 8px 0
		.close
			position absolute
			right 0
			top 0
			width 48px
			height 48px
			flex center center
			cursor pointer
			color var(--color-text)
			transition 0.1s easeOutQuart
			opacity 0.25
			&:hover
				transform scale(1.1)
				opacity 1
			&:active
				transform scale(0.9)

	.footer
		position absolute
		left 50%
		bottom 24px
		padding-top (@bottom - 8px)
		transform translateX(-50%)
		flex center center
		color var(--color-text)
		.link
			// width 200px
			margin 0 16px
			opacity 0.5
			white-space nowrap
			cursor pointer
			&:first-child
				text-align right
			&:left-child
				text-align left
			&:hover
				opacity 1
		.separator
			opacity 0.5
			user-select none

.modal-solo-games
	fixed 0
	flex center center
	&.modal-solo-games-enter-active
		transition 0.25s easeOutQuart
		> .bg
			transition 0.25s easeOutQuart
		> .modal-solo-games-content
			transition 0.25s easeOutBack
	&.modal-solo-games-enter
		> .bg
			opacity 0
		> .modal-solo-games-content
			opacity 0
			transform scale(0.9)
	&.modal-solo-games-leave-active
		transition 0.25s easeOutQuart
		> .bg, > .modal-solo-games-content
			transition 0.25s easeOutQuart
	&.modal-solo-games-leave-to
		> .bg
			opacity 0
		> .modal-solo-games-content
			opacity 0
			transform scale(0.95)
	> .bg
		content ''
		absolute 0
		background-color var(--color-infobox-bg)
	> .modal-solo-games-content
		width calc(100% - 32px)
		max-width 500px
		padding 48px
		border-radius 8px
		background-color var(--color-infobox-content-bg)
		box-shadow 0 0 0 4px var(--color-infobox-content-border)
		color var(--color-infobox-content-text)
		> .title
			margin 0 0 16px 0
			color var(--color-infobox-content-h1)
			font-family var(--josefin)
			font-weight 700
			text-transform uppercase
			text-align center
		.scenarios
			flex start start column
			gap 8px
			.scenario
				width 100%
				&:hover > .name
					transform scale(1.025)
					filter brightness(1.1)
				> .name
					flex center center
					height 48px
					padding 0 16px
					border-radius 8px
					cursor pointer
					text-align center
					background-color var(--color-infobox-content-h1)
					color var(--color-infobox-content-bg)
		.close
			position absolute
			right -12px
			top -12px
			width 40px
			height 40px
			border-radius 50%
			background-color var(--color-infobox-content-button-bg)
			color var(--color-infobox-content-button-text)
			flex center center
			cursor pointer
			box-shadow 0 0 0 4px var(--color-infobox-content-button-text)
			&:active
				transform translateY(1px)

@keyframes shake
	0%
		transform translate(2px, 0) rotate(0deg)
	10%
		transform translate(-1px, 0) rotate(-1deg)
	20%
		transform translate(-3px, 0) rotate(1deg)
	30%
		transform translate(0px, 0) rotate(0deg)
	40%
		transform translate(1px, 0) rotate(1deg)
	50%
		transform translate(-1px, 0) rotate(-1deg)
	60%
		transform translate(-3px, 0) rotate(0deg)
	70%
		transform translate(2px, 0) rotate(-1deg)
	80%
		transform translate(-1px, 0) rotate(1deg)
	90%
		transform translate(2px, 0) rotate(0deg)
	100%
		transform translate(1px, 0) rotate(-1deg)
		

</style>
