<template>
	<div id="app" :class="[ app.device, 'perf-' + app.performance, touch_class, { 'mobile-keyboard': app.mobile_keyboard, 'ipad-theme': app.is_ipad_theme } ]">
		
		<div v-if="logged_user && logged_user.type === 'player'" id="main" ref="main">

			<div class="main-player-content">
				<div class="mobile-frame">
					<div class="speaker"></div>
					<div class="home-button"></div>
					<div class="volume-button"></div>
				</div>
				<router-view />
			</div>

			<transition name="chirp">
				<chirp v-if="chirp && chirp.route" />
			</transition>

			<main-bar v-if="game.room" />
			<main-menu v-if="game.room && !app.is_ipad_theme" type="game" />

			<new-messages-notification v-if="app.is_ipad_theme" />

			<modal-image-viewer v-if="app.image_viewer_url" :url="app.image_viewer_url" />
			
			<!-- <main-bar v-if="game.room && game.room.state != 'stopped'" /> -->
			<!-- <main-menu v-if="game.room && game.room.state != 'stopped'" /> -->
			<waiting-screen />

			<transition name="video-player">
				<video-player v-if="game.room && game.playing_video" :slug="game.playing_video" :light="game.room.state !== 'running'" />
			</transition>
		</div>

		<div v-if="logged_user && logged_user.type === 'game-master'" id="main" ref="main">
			<div class="main-master-content">
				<router-view />
			</div>
			<main-bar v-if="game.room" />
			<main-menu type="master" />
		</div>

		<div v-if="!logged_user" id="main" ref="main">
			<router-view />
		</div>
		
		<ui-info-box v-for="info_box in app.info_boxes" :info_box="info_box" :key="info_box.id" />

		<transition name="algorithmic-results" mode="out-in">
			<algorithmic-results v-if="app.algorithmic_results && app.algorithmic_results.question" :key="app.algorithmic_results.question.id" />
		</transition>

		<transition name="tuto">
			<tuto v-if="app.current_tuto" :tuto="app.current_tuto" @close="end_tuto" />
		</transition>

		<ui-tooltip v-if="app.current_tooltip && app.current_tooltip.id" :key="app.current_tooltip.id" :tooltip="app.current_tooltip" />

		<main-loader-screen v-if="app.main_loader" />

		<ui-offline-bar />

		<transition name="settings">
			<settings v-if="app.settings" />
		</transition>

		<transition-group name="notifications" class="notifications" tag="div">
			<ui-notification v-for="notification in app.notifications" :key="notification.id" :notification="notification" />
		</transition-group>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Sounds from '@/js/sounds'
import MainBar from '@/components/main-bar'
import MainMenu from '@/components/main-menu'
import Chirp from '@/components/game/chirp/chirp'
import NewMessagesNotification from '@/components/game/new-messages-notification'
import ModalImageViewer from '@/components/game/modal-image-viewer'
import WaitingScreen from '@/components/game/waiting-screen'
import VideoPlayer from '@/components/game/video-player'
import Settings from '@/components/game/settings'
import MainLoaderScreen from '@/components/main-loader-screen'
import UiNotification from '@/components/ui/ui-notification'
import UiOfflineBar from '@/components/ui/ui-offline-bar'
import UiInfoBox from '@/components/ui/ui-info-box'
import UiTooltip from '@/components/ui/ui-tooltip'
import AlgorithmicResults from '@/components/algorithmic-results'
import Tuto from '@/components/tuto'
import Feature from 'feature.js'

export default {
	name: 'app',
	data() {
		return {
			height: false
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'logged_user', 'chirp', 'progress', 'current_question' ]),
		is_dev() {
			return process.env.NODE_ENV === 'development'
		},
		touch_class() {
			if (Feature.touch) return 'touch'
			else return 'not-touch'
		}
	},
	watch: {
		'app.theme'(newValue, oldValue) {
			document.querySelector('body').classList.remove(oldValue)
			document.querySelector('body').classList.add(newValue)
			document.querySelector('html').classList.remove('is-theme-' + oldValue)
			document.querySelector('html').classList.add('is-theme-' + newValue)
			localStorage.setItem('ithrive-theme', newValue)
		},
		logged_user() {
			this.resize()
			if (this.logged_user.type === 'player') {
				if (this.$route.name === 'chirp-feed') this.$store.dispatch('set_chirp_route', { route: 'feed' })
				else if (this.$route.name === 'chirp-messages') this.$store.dispatch('set_chirp_route', { route: 'messages' })
			}
		},
		'app.main_loader'() {
			Sounds.set_ready(true)
		},
		'chirp.messages'() {
			if (this.logged_user.type === 'player') {
				this.$store.dispatch('update_received_files')
			}
		}
	},
	methods: {
		end_tuto({ slug }) {
			this.$store.dispatch('end_tuto', { slug })
		},
		// start_tuto(data) {
		// 	this.$store.dispatch('start_tuto', data)
		// },
		resize() {
			let main = this.$refs.main
			if (!main) main = this.$el
			if (main) {
				let device = 'mobile'
				if (main.clientWidth > 1200) device = 'desktop'
				else if (main.clientWidth > 900) device = 'tablet-horizontal'
				else if (main.clientWidth > 600) device = 'tablet-vertical'
				this.height = main.clientHeight

				// Mobile horizontal
				if (device === 'mobile' || device === 'tablet-vertical') {
					if (main.clientHeight + main.clientHeight / 3 < main.clientWidth && !this.app.mobile_keyboard) this.$store.dispatch('set_app', { mobile_keyboard: true })
					if (main.clientHeight + main.clientHeight / 3 >= main.clientWidth && this.app.mobile_keyboard) this.$store.dispatch('set_app', { mobile_keyboard: false })
					// if (main.clientHeight < main.clientWidth) device = 'mobile'
				}

				if (device != this.app.device) this.$store.dispatch('set_app', { device: device })
			}
		},
		cancel_pinch() {
			
		},
		cancel_tap(e) {
			e.preventDefault()
			return false
		},
		keypress(e) {
			if (e.keyCode === 27) {
				this.$store.commit('EDIT_APP_STATE', { settings: !this.app.settings })
			}
		},
	},
	async mounted() {
		this.resize()
		window.addEventListener('resize', this.resize)

		await this.$store.dispatch('init_app')
	},
	created() {
		this.$store.commit('EDIT_APP_STATE', { theme: localStorage.getItem('ithrive-theme') ? localStorage.getItem('ithrive-theme') : 'dark' })
		window.addEventListener('keyup', this.keypress)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resize)
		window.removeEventListener('keyup', this.keypress)
	},
	components: { MainMenu, MainBar, Chirp, Settings, AlgorithmicResults, ModalImageViewer, NewMessagesNotification, UiOfflineBar, UiNotification, Tuto, WaitingScreen, VideoPlayer, MainLoaderScreen, UiInfoBox, UiTooltip }
}
</script>

<style lang="stylus">
@import './assets/css/variables'
@import './assets/css/reset'
@import './assets/css/vuebar'

// default = json('default.json', { hash: true })
// light = json('light.json', { hash: true })



body
	font-family var(--poppins)
	color #fff
	font-weight 400
	background-color var(--color-bg)

	&.light
		color var(--color-text)
	
#app
	font-size 1.4rem
	&.ipad-theme
		background url('./assets/img/main-bg.jpg') center center no-repeat
		background-size cover
		.main-player-content
			absolute auto
			left 50%
			top 50%
			transform translate(-50%, -50%)
			height 500px
			width 900px
			background-color var(--color-bg)
			&:after
				content ''
				absolute -32px
				background radial-gradient(circle at right bottom, alpha(#fff, 0%) 75%, alpha(#fff, 2%) 75%)
				pointer-events none
			.mobile-frame
				absolute -32px
				border 32px solid #111
				border-radius 16px
				box-shadow 0 0 16px 8px alpha(#fff, 50%)
				.speaker
					position absolute
					left -20px
					top calc(50% - 32px)
					height 64px
					width 8px
					border-radius 8px
					background-color #000
					box-shadow inset 1px 1px lighten(#111, 5%)
					&:before
						content ''
						position absolute
						left 0
						top calc(100% + 8px)
						width 8px
						height 8px
						border-radius 50%
						background-color #000
						box-shadow inset 1px 1px lighten(#111, 5%)
				.volume-button
					position absolute
					left 80px
					top -38px
					flex start start
					gap 8px
					&:before
						content ''
						width 40px
						height 6px
						background-color #222
						border-radius 2px 2px 0 0
						box-shadow inset 1px 1px lighten(#222, 50%)
					&:after
						content ''
						width 40px
						height 6px
						background-color #222
						border-radius 2px 2px 0 0
						box-shadow inset 1px 1px lighten(#222, 50%)
	


.main-player-content, .main-master-content
	position absolute
	left 0
	top 80px
	right 0
	bottom 80px
	height calc(100% - 160px)
	background-color var(--color-bg)
		

.theme
	position absolute
	left 0
	top 0
	width 56px
	height 56px
	padding 16px
	color var(--color-theme-text)
	cursor pointer
	transition 0.1s easeOutQuart
	z-index 2000
	&:hover
		transform scale(1.2)

.main-master-content
	left 0
	bottom 0
	height 100%

#app.mobile
	&.mobile-keyboard
		.main-player-content
			height 100%
			bottom 0
		.main-bar
			display none
	.main-player-content
		bottom 64px
		top auto
		height calc(100% - (64px + 64px))


.notifications
	position fixed
	right 16px
	top 16px
	z-index 5000

</style>
