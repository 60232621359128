<template>
	<div class="main-view">
		<div class="content" :class="scenarioSlug">

			<div v-if="scenarioSlug === 'selling-dreams'" class="texts">
				<p class="title">WELCOME TO SELLING DREAMS</p>
				<p>We are SEED Designers with lived experience of the systems of inequities in this country. Adults try to sell us dreams all the time—programs to train us, conversations to heal us, jobs to give us financial stability. But do they deliver? Play to find out.</p>
				<p>In this game, you will be the GUARDIAN. You’ll experience what it’s like to engage with young people in the system. Login below with your name. The game takes a few seconds to launch and then a video will pop up to give you some instructions for how to play.</p>
				<p>What dreams will you be selling?</p>
			</div>
			<div v-if="scenarioSlug === 'disastermind'" class="texts">
				<img class="logo" src="@/assets/img/disaster-mind/logo-disaster-mind.png" alt="DISASTER MIND">
				<p class="title">WELCOME TO DISASTER MIND</p>
			</div>
			<div v-if="scenarioSlug === 'jury-duty'" class="texts">
				<!-- us-chamber-of-commerce-foundation.png -->
				<!-- jury-duty-summons.png -->
				<!-- ithrive-games.png -->
				<img class="logo" src="@/assets/img/jury-duty/jury-duty-summons.png" alt="Jury Duty Summons">
				<p class="mentions">All characters, events, and scenarios depicted in this game are purely fictional. Any resemblance to actual persons, living or dead, or actual events, is purely coincidental. The content of this game is intended for educational purposes only and does not represent or reflect any real-life situations, legal proceedings, or individuals.</p>
				<!-- <p class="title">Jury Duty</p> -->
			</div>
			<form class="form" @submit.prevent="submit" ref="form">
				<!-- <div class="line">
					<div class="label">Your name</div>
					<ui-input v-model="username" placeholder="Enter your name here" />
				</div> -->
				<div v-if="scenarioSlug === 'disastermind'" class="line">
					<div class="label">Your role</div>
					<div class="radios">
						<div v-for="r in roles" :key="r.id" class="radio" @click="role = role === r.id ? null : r.id">
							<div class="checkbox" :class="{ checked: role === r.id }"></div>
							<div class="text">
								<p class="name" v-html="r.name"></p>
								<p class="description" v-html="r.description"></p>
							</div>
						</div>
					</div>
					<!-- <ui-select v-model="role" placeholder="Select your role" :options="" /> -->
				</div>
				<div class="line captcha">
					<div class="label"><canvas ref="canvas" height="23" width="400"></canvas></div>
					<ui-input v-model="captcha" class="jCaptcha" :class="{ shaking: shakingCaptcha }" placeholder="Result" />
				</div>
				<button type="submit" class="button" :class="{ disabled: !submitable }">
					<span v-if="!loading">Start Game</span>
					<svg v-if="!loading" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
					<svg v-else class="loading" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
						<path fill="currentColor" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
							<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
						</path>
					</svg>
				</button>
			</form>

			<div v-if="scenarioSlug === 'disastermind'" class="footer">
				<img src="@/assets/img/disaster-mind/logo-ithrive.png" alt="iThrive SIM">
				<img src="@/assets/img/disaster-mind/logo-fema.svg" alt="FEMA">
			</div>
			<div v-if="scenarioSlug === 'jury-duty'" class="footer">
				<template v-if="[ 'light', 'red-paper', 'brown' ].includes(app.theme)">
					<img src="@/assets/img/jury-duty/ithrive-games.png" alt="iThrive Games">
					<img src="@/assets/img/jury-duty/us-chamber-of-commerce-foundation.png" alt="US Chamber of Commerce Foundation">
				</template>
				<template v-if="app.theme === 'dark'">
					<img src="@/assets/img/jury-duty/ithrive-games-light.png" alt="iThrive Games">
					<img src="@/assets/img/jury-duty/us-chamber-of-commerce-foundation-light.png" alt="US Chamber of Commerce Foundation">
				</template>
			</div>

			<div v-if="loading" class="loading-overlay"></div>
			
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import UiInput from '@/components/ui/ui-input'
import { slugify } from '@/js/utils/utils'
// import jCaptcha from 'js-captcha'

let numbers = []

export default {
	name: 'view-quick-game',
	data() {
		return {
			loading: false,
			username: 'player',
			role: null,
			captcha: '',
			shakingCaptcha: false,
			roles: [
				{ id: 972, name: 'Player 1', description: "You start the game at home with your sister." },
				{ id: 1030, name: 'Player 2', description: "You start the game at a community center with other volunteers." }
			]
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		submitable() {
			return this.username && this.captcha && this.roleId
		},
		scenarioSlug() {
			return this.$route.params.pathMatch
		},
		scenarioId() {
			if (this.scenarioSlug === 'selling-dreams') return 12
			if (this.scenarioSlug === 'ctec') return 22
			if (this.scenarioSlug === 'jane') return 9
			if (this.scenarioSlug === 'disastermind') return 21
			if (this.scenarioSlug === 'jury-duty') return 24
			return null
		},
		roleId() {
			if (this.scenarioSlug === 'selling-dreams') return 491
			if (this.scenarioSlug === 'ctec') return 990
			if (this.scenarioSlug === 'jane') return 416
			if (this.scenarioSlug === 'jury-duty') return 1072
			if (this.scenarioSlug === 'disastermind' && this.role) return parseInt(this.role)
			return null
		}
	},
	watch: {
		shakingCaptcha() {
			setTimeout(() => {
				this.shakingCaptcha = false
			}, 500)
		}
	},
	methods: {
		async submit() {
			if (!this.loading && this.username && this.scenarioId) {
				if (numbers[0] + numbers[1] === parseInt(this.captcha)) {
					this.loading = true
					localStorage.removeItem('sre-user')
					localStorage.removeItem('sre-first-solo-launch')
					const studentNumber = slugify(this.username)
					const roomId = await Apollo.mutate('ADD_ROOM', { name: "Quick room", owner_id: 2, scenario_id: this.scenarioId })
					if (roomId) {
						const room = await Apollo.query('GET_ROOM', { id: roomId })
						if (room && room.pin) {
							const player = await Apollo.mutate('ADD_PLAYER', { room_id: roomId, student_number: studentNumber, student_name: this.username })
							if (player) {
								await Apollo.mutate('SET_PLAYER_ROLE', { player_id: parseInt(player.id), role_id: this.roleId })
								Apollo.mutate('START_ROOM_SESSION', { id: roomId })
								this.$router.push({ name: 'login', params: { room_code: room.pin, student_number: studentNumber } })
								setTimeout(() => {
									Apollo.mutate('SET_ROOM_VIDEO_EVENT', { room_id: parseInt(roomId), key: 'intro' })
								}, 5000)
							}
						}
					}
				} else {
					this.shakingCaptcha = true
					this.generate_captcha()
					this.captcha = ''
				}
				this.loading = false
			}
		},
		focus() {
			document.querySelector('input')?.focus()
		},
		generate_captcha() {
			numbers = [ Math.floor(Math.random() * 9) + 1, Math.floor(Math.random() * 9) + 1 ]
			const canvas = this.$refs.canvas
			const ctx = canvas.getContext('2d')
			ctx.clearRect(0, 0, canvas.width, canvas.height)
			ctx.font = "20px Arial"
			ctx.fillStyle = this.app.theme === 'light' ? '#4d4e4e' : this.app.theme === 'brown' ? '#2A1A0A' : "#fff"
			ctx.fillText(numbers[0] + ' + ' + numbers[1] + ' =', 0, 20)
		}
	},
	mounted() {
		this.focus()
		this.generate_captcha()
	},
	components: { UiInput }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

.main-view
	width 100%
	height 100%
	.content
		width 100%
		height 100%
		padding-bottom 64px
		background-color var(--color-bg)
		font-size 1.6rem
		flex center center column
		&.jury-duty
			.texts img
				height 280px
			.footer
				img
					height 96px
					width auto
					gap 16px
		.texts
			width 100%
			flex center center column
			margin 0 0 32px 0
			max-width 600px
			user-select none
			img
				height 180px
				width auto
				margin 0 auto
				transform translateY(-64px)
			p
				width 100%
				margin 0 0 8px 0
				color var(--color-theme-text)
				opacity 0.75
				text-align left
				&.title
					font-size 3.2rem
					font-family var(--josefin)
					color var(--color-theme-text)
					opacity 1
				&.mentions
					font-style italic
		.form
			width 600px
			flex center center column
			.line
				width 100%
				margin 0 0 32px 0
				&.captcha
					width 100%
				.label
					width 100%
					margin 0 0 4px 0
					font-family Arial
					font-size 2rem
					color var(--color-theme-text)
					canvas
						height 23px
						width 400px
				.radios
					margin-top 8px
					padding 16px
					background-color var(--color-login-form-bg)
					border-radius 4px
					.radio
						flex flex-start flex-start
						gap 12px
						margin 0 0 24px 0
						line-height 20px
						cursor pointer
						&:last-child
							margin-bottom 0
						&:hover .checkbox
							// border-color var(--color-settings-content-border)
							opacity 1
						.checkbox
							width 32px
							height 32px
							flex-shrink 0
							background center center no-repeat
							background-size 24px
							border 3px solid var(--color-theme-text)
							opacity 0.5
							border-radius 50%
							&.checked
								border-color var(--color-settings-content-border)
								background-color var(--color-settings-content-border)
								background-image url('../assets/img/check-dark.svg')
								opacity 1
						.text
							display block
							min-height 32px
							.name
								margin 0 0 4px 0
								text-transform uppercase
								font-family var(--josefin)
							.description
								opacity 0.5
				.ui-input, .ui-select
					padding 0 24px
					height 64px
					font-size 2rem
					color var(--color-login-form-text)
					background-color var(--color-login-form-bg)
					border-radius 4px
					// height auto
					appearance none
					&.shaking
						animation shake 0.25s linear infinite
						box-shadow 0 0 0 4px var(--color-error)
						@keyframes shake
							0%, 100%
								transform translate(0, 0)
							15%
								transform translate(-2px, 1px)
							25%
								transform translate(0px, -1px)
							50%
								transform translate(-1px, 2px)
							65%
								transform translate(-2px, -1px)
							75%
								transform translate(2px, 1px)
			.button
				display inline-block
				height 56px
				padding 0 24px
				border-radius 4px
				flex center center
				background none
				border none
				cursor pointer
				font-family var(--josefin)
				font-size 2rem
				line-height 1em
				background-color var(--color-button-bg)
				color var(--color-button-text)
				text-transform uppercase
				gap 4px
				&:hover, &:active
					background-color var(--color-button-bg-hover)
				&.disabled
					background-color var(--color-button-disabled)
					color var(--color-button-disabled-text)
					&:hover, &:active
						background-color var(--color-button-disabled)
				> span
					top 2px
				svg
					width 32px
					height 32px
					&.loading
						fill var(--color-login-form-text)
						transform scale(2)
			.hint
				position absolute
				left 0
				right 0
				top 100%
				padding 8px
				text-align center
				color var(--color-login-form-text)
				opacity 0.35
				text-transform uppercase
				user-select none
		
		.footer
			position absolute
			left 0
			bottom 32px
			right 0
			flex center center
			gap 32px
			img
				height 64px
				width auto

	.loading-overlay
		absolute 0
		background-color var(--color-bg)
		opacity 0.5
		cursor wait

::-webkit-input-placeholder
	color var(--color-login-form-text)
	opacity 0.25
	user-select none
::-moz-placeholder
	color var(--color-login-form-text)
	opacity 0.25
	user-select none
:-ms-input-placeholder
	color var(--color-login-form-text)
	opacity 0.25
	user-select none
:-moz-placeholder
	color var(--color-login-form-text)
	opacity 0.25
	user-select none


@media (max-height: 860px)
	.main-view .content
		padding-bottom 96px
		.texts img
			position fixed
			left 32px
			top 32px
			transform none
			width 300px
			height auto !important

	@media (max-width: 1300px)
		.main-view .content .texts img
			left 16px
			top 16px
			width 200px
	@media (max-width: 1100px)
		.main-view .content .texts img
			display none
		



</style>
