<template>
	<div v-if="file" class="file-reader" :class="file.file_type">
		<div class="page">
			<!-- <div class="page-type-title">{{ file.file_type | file_type_to_str }}</div> -->
			<div class="page-content">
				<p class="title">{{ file.title }}</p>
				<ui-text :text="file.data" />
			</div>
			<!-- <div class="send-button" v-hammer:tap="() => send()">Send</div> -->
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import UiText from '@/components/ui/ui-text'

export default {
	name: 'file-reader',
	computed: {
		...mapGetters([ 'files', 'logged_user']),
		// documents() {
		// 	if (this.type == 'confidential') return this.files.documents.filter((doc) => { return doc.file_type == '0' })
		// 	if (this.type == 'secret') return this.files.documents.filter((doc) => { return doc.file_type == '1' })
		// 	if (this.type == 'top-secret') return this.files.documents.filter((doc) => { return doc.file_type == '2' })
		// 	if (this.type == 'memo') return this.files.documents.filter((doc) => { return doc.file_type == '3' })
		// 	return []
		// },
		file() {
			if (this.$route.params.file_id) return this.files.documents.find((d) => { return d.id == this.$route.params.file_id })
			return null
		}
	},
	// watch: {
	// 	documents(newValue, oldValue) {
	// 		this.read_documents()
	// 	}
	// },
	filters: {
		file_type_to_str(value) {
			if (value == 'confidential') return "Confidential"
			if (value == 'secret') return "secret"
			if (value == 'top-secret') return "Top secret"
			if (value == 'memo') return "Memo"
			return "Document"
		}
	},
	methods: {
		read_documents() {
			// this.documents.forEach((doc) => {
				if (!this.file.read) this.$store.dispatch('read_document', { document: this.file })
				Apollo.mutate('READ_PLAYER_FILE', { file_id: parseInt(this.file.id), player_id: parseInt(this.logged_user.id) })
			// })
		},
		send() {
			this.$store.dispatch('set_chirp_route', { name: 'players', params: { file: this.file } })
		}
	},
	mounted() {
		this.read_documents()
	},
	components: { UiText }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.mobile
	.file-reader
		.page
			&:first-child
				margin-top 0
			&:last-child
				margin-bottom 0

#app.mobile, #app.tablet-vertical, #app.tablet-horizontal, #app.ipad-theme
	.file-reader
		margin 0 auto


.file-reader
	width calc(100% - 24px)
	height 100%
	max-width 600px
	padding 32px 0 0 0
	&.secret
		.page
			background-color var(--color-doc-secret)
			.page-content
				color var(--color-doc-secret)
	&.top-secret
		.page
			background-color var(--color-doc-top-secret)
			.page-content
				color var(--color-doc-top-secret)
	&.confidential
		.page
			background-color var(--color-doc-confidential)
			.page-content
				color var(--color-doc-confidential)
	&.memo
		.page
			background-color var(--color-doc-memo)
			.page-content
				color var(--color-doc-memo)
	.page
		width 100%
		min-height 750px
		background-color blue
		padding 24px
		&:last-child
			margin-bottom 32px
		.page-type-title
			height 80px
			line-height (@height - 4px)
			text-transform uppercase
			font-weight 700
			font-size 6.4rem
			text-align center
			white-space nowrap
			font-family var(--josefin)
			color #fff
		.page-content
			background-color #fff
			// padding 32px 32px 96px 32px
			padding 32px
			color blue
			height calc(100% - 80px)
			min-height (750px - (0px + 48px))
			.title
				margin 0 0 16px 0
				text-align center
				text-transform uppercase
				font-size 2.4rem
				font-weight 700
			>>> .ui-text
				ul, ol
					margin-bottom 16px
					li
						margin-bottom 4px
						&:last-child
							margin-bottom 0
				a
					text-decoration underline
					&:hover
						text-decoration none
		.send-button
			position absolute
			right 40px
			bottom 40px
			height 48px
			padding 0 52px 0 24px
			border-radius 4px
			background green url(../../../assets/img/plane-white.svg) right 16px center no-repeat
			background-size 24px auto
			color #fff
			cursor pointer
			text-transform uppercase
			font-size 1.8rem
			font-weight 700
			font-family var(--josefin)
			line-height (@height + 3px)

</style>
