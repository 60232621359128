import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloLink } from "apollo-link"
import { purify } from '@/js/utils/utils'
import VueApollo from 'vue-apollo'
import gql from 'graphql-tag'
import introspectionQueryResultData from '@/apollo/fragmentTypes.json'
import store from '@/store/store'
import Vue from 'vue'

const httpEndpoint = process.env.VUE_APP_APPOLO_ENDPOINT
const httpLink = new HttpLink({
	uri: httpEndpoint,
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData
})

const authLink = setContext((_, {headers}) => {
	return {
		headers: {
			'x-api-key': process.env.VUE_APP_APPOLO_API_KEY
		}
	}
})

const link = ApolloLink.from([ authLink, httpLink ])

const defaultOptions = {
	httpLinkOptions: {
		credentials: 'same-origin',
	},
	httpEndpoint,
	wsEndpoint: null,
	persisting: false,
	websocketsOnly: false,
	ssr: false,
	defaultHttpLink: false,
	link,
	cache: new InMemoryCache({
		fragmentMatcher
	}),
}

function createProvider(options = {}) {
	const {apolloClient, wsClient} = createApolloClient({
		...defaultOptions,
		...options,
	})
	
	apolloClient.wsClient = wsClient
	
	const apolloProvider = new VueApollo({
		defaultClient: apolloClient,
		defaultOptions: {
			$query: {
				loadingKey: 'loading',
				fetchPolicy: 'cache-and-network',
			},
		},
		errorHandler(error) {
			// eslint-disable-next-line no-console
			console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
		},
	})
	return apolloProvider
}


const apolloClient = createProvider().defaultClient


const Apollo = {}

Apollo.query = async function(query_name, variables) {
	let params = {
		fetchPolicy: 'network-only',
		query: require('@/apollo/queries/' + query_name + '.gql')
	}
	if (variables) params.variables = variables

	// if (variables)
	// 	console.info('🤖 [ REQUEST ] ' + query_name, variables)
	// else
	// 	console.info('🤖 [ REQUEST ] ' + query_name)

	const request = await apolloClient.query(params).catch(error => {
		console.error('[ REQUEST ERROR ] ' + query_name, variables)
		console.error(error)
		store.dispatch('add_notification', { status: 'error', message: error })
	})

	return request.data.response
}

Apollo.mutate = async function(mutation_name, variables) {
	let params = { mutation: require('@/apollo/mutations/' + mutation_name + '.gql') }
	if (variables) params.variables = variables

	// if (variables)
	// 	console.info('🤖 [ REQUEST ] ' + mutation_name, variables)
	// else
	// 	console.info('🤖 [ REQUEST ] ' + mutation_name)

	const request = await apolloClient.mutate(params).catch(error => {
		console.error('[ REQUEST ERROR ] ' + mutation_name, variables)
		console.log(error)
		store.dispatch('add_notification', { status: 'error', message: error })
	})

	return request.data.response
}

export default Apollo