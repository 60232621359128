<template>
	<div class="reset-password">
		<div class="content">
			<div v-if="token && uid" class="left">
				<div class="title">Change your password</div>
				<form class="form" @submit.prevent="submit">
					<div class="line">
						<label class="label">New password</label>
						<ui-input type="password" v-model="password" placeholder="Password" />
					</div>
					<div class="line">
						<label class="label">Confirmation</label>
						<ui-input type="password" v-model="confirmation" placeholder="Confirmation" />
					</div>
					<div class="line">
						<p class="error">{{ error }}</p>
					</div>
					<div class="buttons">
						<button type="submit" class="button" :class="{ loading, active: active_button }">Change password</button>
					</div>
					<div v-if="success" class="success">
						<ui-icon name="check-2" :size="64" />
						<p>Your password has been changed correctly.</p>
						<p>You can now use it to log in.</p>
						<div class="login-button" v-hammer:tap="() => $router.push({ name: 'login' })">Log in</div>
					</div>
				</form>
			</div>
			<div v-else class="left">
				<div class="invalid">
					<ui-icon name="cancel-gray" :size="64" />
					<p>You must use the link in the email you received to change your password.</p>
				</div>
			</div>
		</div>
		<div class="logo"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import UiInput from '@/components/ui/ui-input'

export default {
	name: 'reset-password',
	data() {
		return {
			loading: false,
			token: '',
			uid: '',
			password: '',
			confirmation: '',
			success: false,
			error: ''
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		active_button() {
			return this.token && this.uid && this.password && this.password === this.confirmation
		}
	},
	methods: {
		async submit() {
			if (this.active_button) {
				this.loading = true
				let r = await Apollo.mutate('END_RESET_PASSWORD', { uid: this.uid, token: this.token, new_password: this.password })
				if (r && r.status) {
					this.success = true
				} else
					this.error = r.message
				this.loading = false
			}
		}
	},
	created() {
		if (this.$route.query) {
			this.token = this.$route.query.token
			this.uid = this.$route.query.uid
		}
	},
	components: { UiInput }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.reset-password
		.logo
			right auto
			left calc(50% - 65px)

.reset-password
	background-color darken(blue_darker, 15%)
	display block
	height 100%
	width 100%
	flex center center
	padding-bottom 64px

	.content
		flex center center
		// padding 40px 24px
		width 100%
		height 370px
		.left
			width 300px
			flex center center
			flex-direction column
			.invalid
				text-align center
				font-size 2.4rem
				line-height 1.2em
				font-family var(--josefin)
				color #979797
				user-select none
				>>> .ui-icon
					margin 0 0 8px 0
			.title
				width 100%
				margin 0 0 24px 0
				text-align center
				color blue_lighter
				font-size 2rem
				font-family var(--josefin)
				text-transform uppercase
			.form
				width 100%
				background-color #143d4e
				box-shadow 0 0 0 2px blue_lighter
				text-align left
				padding 32px
				border-radius 8px
				.line
					margin 0 0 16px 0
					.label
						display block
						color blue_lighter
						text-transform uppercase
					>>> .ui-input
						background-color desaturate(blue_light, 50%)
						height 40px
						padding 0 16px
						color blue_dark
						border-radius 4px
						&:focus
							background-color #fff
							&::-webkit-input-placeholder
								color blue_dark
							&::-moz-placeholder
								color blue_dark
							&:-ms-input-placeholder
								color blue_dark
							&:-moz-placeholder
								color blue_dark
						&::-webkit-input-placeholder
							color #fff
							opacity 0.25
						&::-moz-placeholder
							color #fff
							opacity 0.25
						&:-ms-input-placeholder
							color #fff
							opacity 0.25
						&:-moz-placeholder
							color #fff
							opacity 0.25
					.error
						color red
						text-transform uppercase
						font-weight 700
				.buttons
					padding 8px 0 0 0
					flex center center
					.button
						display inline-block
						height 40px
						padding 0 16px
						margin 0 4px
						border none
						background-color #999
						border-radius 4px
						outline none
						cursor not-allowed
						text-transform uppercase
						font inherit
						color #777
						// font-size 1.4rem
						font-weight 700
						font-family var(--josefin)
						line-height (@height + 3px)
						&.active
							background #4CAF50
							color #fff
							cursor pointer
						&.red
							background-color red
						&:active
							transform translateY(1px)
						&.loading
							overflow hidden
							cursor not-allowed
							&:before
								opacity 1
						&:before
							content ''
							opacity 0
							absolute 0
							background desaturate(#4CAF50, 50%) url(../../assets/img/button-loader.svg) center center no-repeat
							background-size auto 8px

				.success
					absolute 8px
					background-color #143d4e
					flex center center column
					text-align center
					color blue_lighter
					font-size 1.8rem
					line-height 1.2em
					>>> .ui-icon, p
						margin 0 0 8px 0
					.login-button
						height 40px
						margin 8px 0 0 0
						padding 0 16px
						border-radius 4px
						background-color blue_lighter
						color blue_darker
						cursor pointer
						font-weight 700
						font-family var(--josefin)
						line-height (@height + 4px)
						text-transform uppercase
						&:hover
							background-color darken(blue_lighter, 10%)
						&:active
							background-color darken(blue_lighter, 15%)
							transform translateY(2px)
		.right
			width 200px
			height 100%
			flex flex-start flex-content
			
	.logo
		position absolute
		right 16px
		bottom 16px
		width 130px
		height 126px
		background url(../../assets/img/logo-small.png) center center no-repeat
		background-size contain


@media (max-height 560px)
	.reset-password
		.logo
			display none


</style>
