<template>
	<div v-if="file" class="file-memory">
		<div class="page">
			<div class="page-type-title">Memory #1</div>
			<div class="page-content">
				<div class="picture"></div>
				<p class="title">{{ file.title }}</p>
				<ui-text :text="file.data" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiText from '@/components/ui/ui-text'

export default {
	name: 'file-memory',
	computed: {
		...mapGetters([ 'files' ]),
		file() {
			if (this.$route.params.file_id) {
				return this.files.memories.find((d) => { return d.id == this.$route.params.file_id })
			}
			return null
		}
	},
	methods: {
		// read_documents() {
		// 	this.documents.forEach((doc) => {
		// 		if (!doc.read) this.$store.dispatch('read_document', { document: doc })
		// 	})
		// },
	},
	// mounted() {
	// 	this.read_documents()
	// },
	components: { UiText }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.mobile
	.file-memory
		.page
			&:first-child
				margin-top 0
			&:last-child
				margin-bottom 0

#app.mobile, #app.tablet-vertical, #app.tablet-horizontal
	.file-memory
		margin 0 auto


.file-memory
	width calc(100% - 24px)
	height 100%
	max-width 600px
	padding 32px 0 0 0
	&.memo
		.page
			background-color var(--color-doc-memo)
			.page-content
				color var(--color-doc-memo)
	&.top-secret
		.page
			background-color var(--color-doc-top-secret)
			.page-content
				color var(--color-doc-top-secret)
	&.confidential
		.page
			background-color var(--color-doc-confidential)
			.page-content
				color var(--color-doc-confidential)
	&.secret
		.page
			background-color var(--color-doc-secret)
			.page-content
				color var(--color-doc-secret)
	.page
		width 100%
		min-height 750px
		background-color blue
		padding 24px
		&:last-child
			margin-bottom 32px
		.page-type-title
			height 80px
			line-height (@height - 4px)
			text-transform uppercase
			font-weight 700
			font-size 6.4rem
			text-align center
			white-space nowrap
			font-family var(--josefin)
		.page-content
			// background-color #fff
			padding 0 16px 16px 16px
			color gray_light
			height calc(100% - 80px)
			min-height (750px - (80px + 48px))
			.picture
				height 300px
				margin 0 0 24px 0
				background-color blue_lighter
			.title
				margin 0 0 16px 0
				text-align center
				text-transform uppercase
				font-size 2.4rem
				font-weight 700
				font-family var(--josefin)
			>>> .ui-text
				ul, ol
					margin-bottom 16px
					li
						margin-bottom 4px
						&:last-child
							margin-bottom 0
				a
					text-decoration underline
					&:hover
						text-decoration none

</style>
