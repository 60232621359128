<template>
	<div class="room-game-access">
		
		<div class="tabs">
			<ui-link class="tab" :to="'/master/rooms/' + game.room.id + '/game-access/decisions'">Decisions</ui-link>
			<ui-link class="tab" :to="'/master/rooms/' + game.room.id + '/game-access/news'">News</ui-link>
			<ui-link class="tab" :to="'/master/rooms/' + game.room.id + '/game-access/chirps'">Chirps</ui-link>
			<ui-link class="tab" :to="'/master/rooms/' + game.room.id + '/game-access/private-messaging'">Private messaging</ui-link>
			<ui-link class="tab" :to="'/master/rooms/' + game.room.id + '/game-access/group-messaging'">Group messaging</ui-link>
			<!-- <ui-link class="tab" :to="'/master/rooms/' + game.room.id + '/game-access/student-assessments'">Student assessments</ui-link> -->
		</div>

		<div class="room-game-access-content" :class="{ 'has-background': hasBackground, 'has-padding': hasPadding }">
			<div class="scroll" v-scrollbar>
				<div>
					<div class="room-game-access-inner-scroll">

						<div v-if="player_filter" class="current-filter">
							Filter enabled : "{{ player_filter.student_name }}"
							<div class="close" v-hammer:tap="reset_filter"><ui-icon name="close-blue-dark" :size="12" /></div>
						</div>

						<news v-if="$route.name === 'room-game-access-news'" :player_filter="player_filter" :view_filter="view_filter" />
						<decisions v-if="$route.name === 'room-game-access-decisions'" :questions="questions" :days="days" :player_filter="player_filter" :view_filter="view_filter" />
						<chirps v-if="$route.name === 'room-game-access-chirps'" :player_filter="player_filter" :view_filter="view_filter" />
						<private-messaging v-if="$route.name === 'room-game-access-private-messaging'" :player_filter="player_filter" :view_filter="view_filter" />
						<group-messaging v-if="$route.name === 'room-game-access-group-messaging'" :player_filter="player_filter" :view_filter="view_filter" :channel_filter="channel_filter" />
						<student-assessments v-if="$route.name === 'room-game-access-student-assessments'" :player_filter="player_filter" :view_filter="view_filter" />
						
					</div>
				</div>
			</div>
		</div>

		<div class="room-game-access-side">
			<div class="nb-in-rooms">
				<div class="label">Students currently in game room:</div>
				<div class="value">00</div>
			</div>
			<div class="form search">
				<vue-simple-suggest v-model="player_filter" :list="players" display-attribute="student_name" :filter-by-query="true" mode="select" :remove-list="!autocomplete_visible">
					<input type="text" v-model="q" class="input" placeholder="Search by student">
					<template slot="suggestion-item" slot-scope="{ suggestion }">
						<div class="student-name">{{ suggestion.student_name }}</div>
						<div class="role-name">{{ suggestion.role.name }}</div>
					</template>
				</vue-simple-suggest>
				<div class="submit" v-hammer:tap="reset_filter">
					<ui-icon v-if="!q && !player_filter" name="search-blue-dark" :size="20" />
					<ui-icon v-else name="close-blue-dark" :size="16" />
				</div>

				<select v-if="$route.name === 'room-game-access-decisions'" class="input select" v-model="day_filter">
					<option value="">Filter by episode</option>
					<option v-for="day in select_days" :key="day" :value="day">Episode {{ day + 1}} <span v-if="day === game.room.episode.episode_number">(current)</span></option>
				</select>

				<select v-if="$route.name === 'room-game-access-group-messaging'" class="input select" v-model="channel_filter">
					<option value="">Filter by channel</option>
					<option v-for="channel in channels" :key="channel" :value="channel">Channel: {{ channel }}</option>
				</select>

				<div class="checkbox" v-hammer:tap="() => check('all')">
					<span class="check" :class="{ checked: view_filter === 'all' }"></span><span class="text">View all content</span>
				</div>
				
				<div class="checkbox" v-hammer:tap="() => check('student')">
					<span class="check" :class="{ checked: view_filter === 'student' }"></span><span class="text">View only student content</span>
				</div>
				
			</div>
			<div v-if="$route.name === 'room-game-access-news'" class="journalism-awards">
				<div class="title">Journalism Awards</div>
				<div class="text">
					At the end of the simulation, awards will be given out to the best news outlet as a whole and to the best written article (may be from a different outlet than "the best"). You decide these factors by awarding as many likes <ui-icon name="chirp/like-on" :size="16" /> has you desire to articles published by participants. The article with the most likes by you will be awarded best article and the outlet with the most cumulative likes accros all articles will be chosen for best outlet.
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import News from '@/components/master/room/game-access/news'
import Decisions from '@/components/master/room/game-access/decisions'
import Chirps from '@/components/master/room/game-access/chirps'
import PrivateMessaging from '@/components/master/room/game-access/private-messaging'
import GroupMessaging from '@/components/master/room/game-access/group-messaging'
import StudentAssessments from '@/components/master/room/game-access/student-assessments'
import VueSimpleSuggest from 'vue-simple-suggest'

export default {
	name: 'room-game-access',
	data() {
		return {
			q: '',
			player_filter: null,
			view_filter: 'all',
			autocomplete_visible: true,
			day_filter: '',
			channel_filter: '',
		}
	},
	computed: {
		...mapGetters([ 'game', 'master', 'chirp' ]),
		select_days() {
			return [ ...new Set(this.master.questions.map((q) => q.day)) ].sort().reverse()
		},
		days() {
			return [ ...new Set([ ...this.questions.map((q) => q.day), this.game.room.episode.episode_number ]) ].sort().reverse()
		},
		questions() {
			// return this.master.questions.filter((q) => q.day < this.game.room.episode.episode_number || (q.day === this.game.room.episode.episode_number))
			// const t = this.game.room.timer_total - this.game.room.timer
			// let questions = this.master.questions.filter((q) => true)
			let questions = this.master.questions.filter((q) => {
				return ((q.question_category === 'CO') || (q.question_category === 'OP' && this.game.room.questions.includes(parseInt(q.id)))) &&
					(
						(q.day < this.game.room.episode.episode_number) ||
						(q.day === this.game.room.episode.episode_number && this.game.room.timer_total - this.game.room.timer > 0 && q.timer <= this.game.room.timer_total - this.game.room.timer) ||
						(q.day === this.game.room.episode.episode_number && this.game.room.timer_total - this.game.room.timer === 0 && (this.game.room.state === 'running' || this.game.room.state === 'ending' || this.game.room.state === 'paused'))
					)
			})

			if (this.day_filter !== '') {
				questions = questions.filter((q) => q.day === this.day_filter)
			}
			if (this.player_filter) {
				questions = questions.filter((q) => {
					return q.deciders.includes(parseInt(this.player_filter.role.id)) || q.contributors.includes(parseInt(this.player_filter.role.id))
				})
			}

			return questions.sort((a, b) => { return (a.timer > b.timer) ? -1 : 1 })
		},
		players() {
			return this.game.players.filter((p) => p.student_name !== null)
		},
		hasBackground() {
			return [ 'room-game-access-chirps', 'room-game-access-private-messaging', 'room-game-access-group-messaging' ].includes(this.$route.name)
		},
		hasPadding() {
			return [ 'room-game-access-private-messaging', 'room-game-access-group-messaging' ].includes(this.$route.name)
		},
		channels() {
			return [ ... new Set(this.chirp.messages.filter(m => m.channel).map(m => m.channel)) ]
			// return [ "test", "ok" ]
		}
	},
	watch: {
		q() {
			if (this.q) this.autocomplete_visible = true
			else this.autocomplete_visible = false
		},
		player_filter() {
			this.autocomplete_visible = false
		}
	},
	methods: {
		check(value) {
			// this.view_filter = (this.view_filter === value) ? null : value
			this.view_filter = value
		},
		reset_filter() {
			this.q = ''
			this.player_filter = null
		}
	},
	created() {
		if (this.$route.name === 'room-game-access') {
			this.$store.dispatch('load_chirps')
			this.$router.push({ path: '/master/rooms/' + this.game.room.id + '/game-access/decisions' })
		}
	},
	components: { News, Decisions, Chirps, PrivateMessaging, GroupMessaging, StudentAssessments, VueSimpleSuggest }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.room-game-access
	height 100%
	padding 16px 0 0 0
	.tabs
		flex flex-start flex-end
		height 48px
		border-bottom 2px solid blue_lighter
		.tab
			display inline-block
			height 100%
			width 145px
			padding-top 2px
			margin 0 0 0 8px
			background-color blue_light
			color blue_dark
			flex center center
			flex-wrap wrap
			text-align center
			font-family var(--josefin)
			font-weight 700
			text-transform uppercase
			cursor pointer
			&:last-child
				width 148px
			&:hover
				background-color lighten(blue_light, 10%)
			&.active
				background-color blue_lighter
	.room-game-access-content
		margin-left 8px
		height calc(100% - 48px)
		max-width ((120px + 8px) * 6 - 8px)
		&.has-background
			background-color darken(blue, 25%)
		&.has-padding
			.room-game-access-inner-scroll
				padding 32px 16px
				.current-filter
					margin-left 0
					margin-right 0
		.room-game-access-inner-scroll
			padding 32px 0
			.current-filter
				height 32px
				background-color green
				padding 0 16px
				margin -16px 16px 16px 16px
				border-radius 8px
				line-height @height
				.close
					position absolute
					right 0
					top 0
					height 32px
					width 32px
					flex center center
					cursor pointer
					border-radius 0 8px 8px 0
					&:hover
						background-color alpha(#fff, 25%)
	.room-game-access-side
		position absolute
		left ((120px + 8px) * 6 - 8px + 32px)
		top 80px
		width 300px
		padding 16px 0 0 0
		height calc(100% - 104px)
		.nb-in-rooms
			flex center center
			margin 0 0 24px 0
			padding 8px 16px
			border-radius 8px
			border 2px solid blue_lighter
			.label
				width 75%
				text-transform uppercase
				color blue_lighter
				line-height 18px
				font-size 1.6rem
				font-family var(--josefin)
				transform translateY(3px)
			.value
				width 25%
				text-align right
				font-size 3.2rem
		.form
			.input
				width 100%
				height 40px
				padding 0 40px 0 16px
				margin 0 0 24px 0
				border none
				background #E1E6E8
				border-radius 40px
				font inherit
				color dark
				outline none
				&.select
					appearance none
					background-image url('../../../assets/img/chevron-down-dark.svg')
					background-position right 12px center
					background-repeat no-repeat
					background-size 24px auto
			>>> .suggestions
				position absolute
				left 0
				top 42px
				right 0
				color blue_dark
				z-index 2
				border-radius 8px
				line-height 16px
				.suggest-item
					padding 8px 12px
					border-bottom 1px dashed #ccc
					cursor pointer
					background-color #E1E6E8
					list-style-type none
					&:first-child
						border-top-left-radius 8px
						border-top-right-radius 8px
					&:last-child
						border none
						border-bottom-left-radius 8px
						border-bottom-right-radius 8px
					&.hover
						background-color red
						color #fff
					.student-name
						font-weight 700
					.role-name
						opacity 0.5
			.submit
				position absolute
				right 0
				top 0
				width 40px
				height 40px
				flex center center
				cursor pointer
				color dark
			.checkbox
				flex flex-start flex-start
				margin 0 0 16px 0
				cursor pointer
				font-size 1.6rem
				line-height 28px
				&:hover
					.check
						box-shadow inset 0 0 0 3px blue_lighter
				.check
					width 28px
					height 28px
					margin 0 8px 0 0
					box-shadow inset 0 0 0 2px blue_lighter
					border-radius 50%
					&.checked
						box-shadow inset 0 0 0 3px blue_lighter
						&:before
							content ''
							absolute 8px
							background-color blue_lighter
							border-radius 50%
		.journalism-awards
			margin 24px 0 0 0
			.title
				padding 0 0 8px 0
				margin 0 0 8px 0
				color blue_lighter
				border-bottom 2px solid blue_lighter
				font-size 2rem
			.text
				color #BEBEBE
				font-size 1.5rem
				line-height 24px
				>>> .ui-icon
					margin 0 2px
					transform translateY(2px)
	
</style>
