<template>
	<div class="attributes">

		<div v-if="!sum_values" class="no-values">Not applicable for this current scenario</div>

		<div v-show="app.device !== 'mobile' || !selected_attribute" class="items">
			<div v-for="attribute in attributes" v-if="attribute && attribute.slug && attribute.value > 0 && attribute.value < 6" class="item" :class="attribute.slug" :key="attribute.slug" v-hammer:tap="() => set_attribute(attribute)">
				<attribute-icon :attribute="attribute" />
				<div class="content">
					<p class="name">{{ attribute.name }}</p>
					<p v-if="attribute['star-' + attribute.value]" class="description">{{ attribute['star-' + attribute.value] }}</p>
				</div>
			</div>
		</div>

		<div v-if="app.device === 'mobile' && selected_attribute" class="mobile-attribute-window" :class="selected_attribute.slug">
			<div class="name">{{ selected_attribute.name }}</div>
			<div class="content" v-scrollbar>
				<div>
					<attribute-icon :attribute="selected_attribute" />
					<p v-if="selected_attribute['star-' + selected_attribute.value]" class="description">{{ selected_attribute['star-' + selected_attribute.value] }}</p>
				</div>
			</div>
		</div>

		<div v-if="selected_attribute" class="close" v-hammer:tap="() => set_attribute('')"></div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import attributes_data from '@/data/attributes.json'
import AttributeIcon from '@/components/game/profile/details/attribute/attribute-icon'

export default {
	name: 'attributes',
	data() {
		return {
			attributes: [],
			selected_attribute: false
		}
	},
	computed: {
		...mapGetters([ 'app', 'logged_user' ]),
		sum_values() {
			return this.attributes.reduce((acc, att) => acc += att.value, 0)
		}
	},
	methods: {
		set_attribute(attribute) {
			if (this.app.device == 'mobile') {
				if (attribute) {
					this.$router.push({ path: '/game/profile/attributes/' + attribute.slug })
				}
				else {
					this.$router.push({ path: '/game/profile/attributes' })
				}
				this.selected_attribute = attribute
			}
		}
	},
	created() {
		this.attributes = []

		attributes_data.forEach((att) => {
			let attribute = { ...att }
			if (this.logged_user.role['att_' + attribute.slug]) {
				attribute.value = parseInt(this.logged_user.role['att_' + attribute.slug])
			}
			if (attribute.value < 1) attribute.value = 1
			if (attribute.value > 5) attribute.value = 5

			this.attributes.push(attribute)
		})
		if (this.$route.params.attribute_slug) this.selected_attribute = this.attributes.find((a) => { return a.slug == this.$route.params.attribute_slug })

	},
	components: { AttributeIcon }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'




#app.mobile
	.attributes
		height 100%
		.items
			height 100%
			padding 0
			display grid
			grid-template-columns 33.3333% 33.3333% 33.3333%
			grid-template-rows 33.3333% 33.3333% 33.3333%
			justify-items center
			align-items center
			.item
				width 100%
				margin 0
				padding 8px 0
				text-align center
				cursor pointer
				&.autority
					grid-column 1
					grid-row 1
					transform translateY(40px)
				&.transparency
					grid-column 2
					grid-row 2
				&.experience
					grid-column 3
					grid-row 1
					transform translateY(40px)
				&.ambition
					grid-column 1
					grid-row 3
					transform translateY(-40px)
				&.loyalty
					grid-column 3
					grid-row 3
					transform translateY(-40px)
				.attribute-icon
					margin 0 auto 6px auto
					transform scale(1.3)
				.content
					.name
						white-space normal
						line-height 20px
						margin 0
					.description
						display none


#app.touch
	.attributes
		.items
			.item:active
				opacity 0.5
				

.no-values
	width 100%
	margin 200px 0 0 0
	text-align center
	font-family var(--josefin)
	font-size 1.6rem
	line-height 1.4em
	text-transform uppercase
	color var(--color-primary)
	// opacity 0.25
	user-select none

.attributes
	.items
		padding 40px 0 0 0
		.item
			margin 0 0 32px 0
			padding 0 0 0 106px
			&.autority
				color #6CCFE2
			&.transparency
				color #60BE81
			&.experience
				color #9AE4D2
			&.ambition
				color #7CA1C7
			&.loyalty
				color #AE8FAE
			.content
				.name
					margin 0 0 4px 0
					font-size 1.6rem
					line-height 20px
					white-space nowrap
				.description
					color #D6D6D6
					font-size 1.4rem
	.mobile-attribute-window
		position absolute
		left 0
		right 0
		top 72px
		bottom 20px
		// height calc(100% - 100px)
		padding 16px
		background-color #143d4e
		border 2px solid blue_lighter
		border-radius 8px
		&.authority
			color #6CCFE2
			border-color #6CCFE2
			.name
				border-color #6CCFE2
		&.transparency
			color #60BE81
			border-color #60BE81
			.name
				border-color #60BE81
		&.experience
			color #9AE4D2
			border-color #9AE4D2
			.name
				border-color #9AE4D2
		&.ambition
			color #7CA1C7
			border-color #7CA1C7
			.name
				border-color #7CA1C7
		&.loyalty
			color #AE8FAE
			border-color #AE8FAE
			.name
				border-color #AE8FAE
		.name
			padding 0 0 16px 0
			margin 0 0 16px 0
			border-bottom 2px solid blue_lighter
			font-size 2.4rem
			line-height 24px
			text-align center
		.content
			// padding 16px 0 0 0
			height calc(100% - 58px)
			.attribute-icon
				float left
				margin 8px 24px 0 0
				transform scale(1.3)
			.description
				color gray_light
				font-size 1.6rem
				line-height 24px
			
.close
	position absolute
	right -4px
	top 16px
	height 48px
	width 48px
	border-radius 50%
	cursor pointer
	background #fff url(../../../../assets/img/close-blue-dark.svg) center center no-repeat
	background-size 16px 16px
		
</style>
