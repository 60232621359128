import { render, staticRenderFns } from "./validate-roles.vue?vue&type=template&id=12a6700e&scoped=true&"
import script from "./validate-roles.vue?vue&type=script&lang=js&"
export * from "./validate-roles.vue?vue&type=script&lang=js&"
import style0 from "./validate-roles.vue?vue&type=style&index=0&id=12a6700e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a6700e",
  null
  
)

export default component.exports