<template>
	<div class="ui-tooltip" v-html="tooltip.text" :class="tooltip.position || ''" :style="{ left: left + 'px', top: top + 'px' }"></div>
</template>

<script>
export default {
	name: 'ui-tooltip',
	data() {
		return {
			left: 0,
			top: 0
		}
	},
	props: {
		tooltip: { default: null },
	},
	mounted() {
		if (this.tooltip?.ref) {
			const tooltip_rect = this.$el?.getBoundingClientRect()
			const ref = this.tooltip.ref?.getBoundingClientRect()
			if (tooltip_rect && ref) {
				this.left = ref.left + (ref.width / 2) - (tooltip_rect.width / 2)
				if (this.tooltip.position === 'bottom') {
					this.top = ref.top + ref.height + 8
				} else {
					this.top = ref.top - (tooltip_rect.height) - 8
				}
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.ui-tooltip
	position fixed
	left 0
	top 0
	max-width 300px
	padding 12px 16px
	border-radius 8px
	background-color #222
	color #fff
	z-index 1000
	pointer-events none
	text-align center
	box-shadow 0 0 4px alpha(#fff, 25%)
	&:after
		content ''
		position absolute
		left calc(50% - 8px)
		top calc(100% - 1px)
		width 0
		height 0
		border-style solid
		border-width 8px 8px 0 8px
		border-color #222 transparent transparent transparent
	&.bottom:after
		transform rotate(180deg)
		top auto
		bottom calc(100% - 1px)


</style>
