<template>
	<div class="main-menu" :class="[ { expanded: expanded, ready: is_playing }, type ]">
		<div class="menu">
			<div class="menu-content" v-if="type === 'game'">

				<ui-link to="/game/files" class="menu-item files" :disabled="ending_decision">
					<ui-icon class="icon" name="menu/menu-files" :size="40" />
					<span class="name">Files</span>
					<div v-if="new_files > 0" class="new">{{ new_files }}</div>
				</ui-link>

				<ui-link v-if="game.scenario.enable_news" to="/game/news" class="menu-item" :disabled="!is_playing || ending_decision">
					<ui-icon class="icon" name="menu/menu-news" :size="40" />
					<span class="name">News</span>
					<div v-if="new_stories > 0" class="new">{{ new_stories }}</div>
				</ui-link>

				<ui-link to="/game/decisions" class="menu-item menu-item-decision" :disabled="!is_playing || ending_decision">
					<ui-icon class="icon" name="menu/menu-decision" :size="40" />
					<span class="name">Decision</span>
					<div v-if="new_decisions" class="new">{{ new_decisions }}</div>
				</ui-link>

				<ui-link v-if="game.scenario.enable_stats" to="/game/stats" class="menu-item" :disabled="!is_playing || ending_decision">
					<ui-icon class="icon" name="menu/menu-stats" :size="40" />
					<span class="name">Stats</span>
				</ui-link>

				<ui-link to="/game/profile" class="menu-item menu-item-profile" :disabled="ending_decision">
					<ui-icon class="icon" name="menu/menu-profile" :size="40" />
					<span class="name">Profile</span>
				</ui-link>

				<div class="separator" v-if="game.scenario.enable_chirp"></div>

				<div v-if="game.scenario.enable_chirp" class="menu-item menu-item-chirp" :class="{ active: chirp.route && chirp.route.name === 'feed', disabled: !is_playing || ending_decision }" v-hammer:tap="() => toggle_chirp('feed')">
					<ui-icon v-if="app.theme === 'brown'" class="icon" :class="{ shake: new_chirps > 0 }" name="menu/menu-jury" :size="40" />
					<ui-icon v-else class="icon" :class="{ shake: new_chirps > 0 }" name="menu/menu-chirp" :size="40" />
					<span class="name">{{ app.theme === 'red-paper' ? 'Newswire' : app.theme === 'brown' ? 'Jury' : 'Chirp' }}</span>
					<div v-if="new_chirps > 0" class="new">{{ new_chirps }}</div>
				</div>

				<div v-if="game.scenario.enable_chirp" class="menu-item menu-item-chirp menu-item-messages" :class="{ active: chirp.route && (chirp.route.name === 'messages' || chirp.route.name === 'conversation'), disabled: !is_playing || ending_decision }" v-hammer:tap="() => toggle_chirp('messages')">
					<ui-icon class="icon" :class="{ shake: new_messages > 0 }" name="chirp/chirp-messages" :size="40" />
					<span class="name">Messages</span>
					<div v-if="new_messages > 0" class="new">{{ new_messages }}</div>
				</div>

				<!-- <ui-link to="/game/goals" class="menu-item">
					<ui-icon class="icon" name="menu/menu-goals" :size="40" />
					<span class="name">Goals</span>
				</ui-link> -->

			</div>
			<div class="menu-content" v-if="type === 'master'">

				<ui-link to="/master/rooms" class="menu-item">
					<ui-icon class="icon" name="menu/menu-sessions" :size="32" />
					<span class="name">Sessions</span>
				</ui-link>

				<ui-link to="/master/scenarios" class="menu-item">
					<ui-icon class="icon" name="menu/menu-library" :size="32" />
					<span class="name">Library of scenarios</span>
				</ui-link>

				<ui-link to="/master/resources" class="menu-item">
					<ui-icon class="icon" name="menu/menu-resources" :size="32" />
					<span class="name">Educator resources</span>
				</ui-link>

				<ui-link to="/master/contacts" class="menu-item">
					<ui-icon class="icon" name="menu/menu-contacts" :size="32" />
					<span class="name">Contact</span>
				</ui-link>

			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Sounds from '@/js/sounds'

export default {
	name: 'main-menu',
	data() {
		return {
			expanded: false
		}
	},
	props: {
		type: { default: 'game' }
	},
	computed: {
		...mapGetters([ 'app', 'game', 'chirp', 'logged_user', 'files', 'current_question', 'progress', 'current_question_conversation' ]),
		new_chirps() {
			return this.chirp.feed.filter((m) => { return !m.read_by.includes(this.logged_user.id) && m.sender.id !== this.logged_user.id }).length
		},
		new_messages() {
			return this.chirp.messages.filter((m) => { return !m.read_by.includes(this.logged_user.id) && m.sender.id !== this.logged_user.id }).length +
				this.new_decision_conversation
		},
		new_stories() {
			return this.chirp.stories.filter((m) => { return !m.read_by.includes(this.logged_user.id) && m.sender.id != this.logged_user.id }).length
		},
		new_files() {
			return this.files.documents.filter((doc) => { return doc && !doc.read }).length
				+ this.files.images.filter((image) => { return image && !image.read }).length
				+ this.files.inventory.filter((inv) => { return inv && !inv.read }).length
				+ this.files.memories.filter((memory) => { return memory && !memory.read }).length
		},
		new_decisions() {
			if (this.current_question) {
				let answer = this.current_question.answer.find((a) => { return this.progress.selected_answers.includes(parseInt(a.id)) })
				if (!answer) return 1
			}
			return 0
		},
		new_decision_conversation() {
			if (this.current_question_conversation) {
				let answer = this.current_question_conversation.answer.find((a) => { return this.progress.selected_answers.includes(parseInt(a.id)) })
				if (!answer) return 1
			}
			return 0
		},
		is_playing() {
			// if (process.env.NODE_ENV === 'development') return true
			return this.game.room && (this.game.room.state === 'running' || this.game.room.state === 'paused')
		},
		ending_decision() {
			if (this.game.room && this.current_question && !this.current_question.answer.find(a => this.progress.selected_answers.includes(parseInt(a.id)))) {
				return this.current_question.timeout - (this.game.room.timer_total - (this.game.room.timer + this.current_question.timer)) <= 15
			}
			return false
		},
	},
	watch: {
		$route() {
			if (this.app.device === 'mobile' || this.app.device === 'tablet-vertical' || this.app.device === 'tablet-horizontal') {
				if (this.$route.name === 'chirp-feed') {
					this.$store.dispatch('set_chirp_route', { name: 'feed' })
				} else if (this.$route.name === 'chirp-messages') {
					this.$store.dispatch('set_chirp_route', { name: 'messages' })
				} else {
					if (this.chirp.route) this.$store.commit('EDIT_CHIRP_STATE', { route: null })
				}
			}
		},
		new_decisions(newValue, oldValue) {
			if (newValue > oldValue) Sounds.play('notification')
		},
		new_decision_conversation(newValue, oldValue) {
			if (newValue > oldValue) Sounds.play('notification')
		}
	},
	methods: {
		toggle_chirp(route) {
			if (this.is_playing && !this.ending_decision) {
				if (this.app.device === 'mobile' || this.app.device === 'tablet-vertical' || this.app.device === 'tablet-horizontal') {
					this.$router.push({ path: '/game/chirp/' + route })
					this.$store.dispatch('set_chirp_route', { name: route })
				} else {
					if (this.chirp.route && this.chirp.route.name === route) {
						this.$store.commit('EDIT_CHIRP_STATE', { route: null })
					} else {
						this.$store.dispatch('set_chirp_route', { name: route })
					}
				}
				// this.retract()
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


body.red-paper
	.main-menu .menu .menu-content .menu-item
		&.active
			opacity 1
			.icon
				color var(--color-chirp-primary)
		.icon
			color var(--color-primary)
		.new
			font-family var(--poppins)
			font-size 1.4rem
			line-height 1em
			padding-bottom 2px

#app.mobile
	&.mobile-keyboard
		.main-menu
			display none
	.main-menu
		position absolute
		left 0
		top auto
		bottom 0
		right 0
		width 100%
		height 64px
		padding 0
		box-shadow none
		font-size 1.2rem
		.menu
			height 100%
			box-shadow 0 -2px var(--color-menu-text)
			.menu-content
				.menu-item
					height 52px
					width 20%
					padding 0
					flex center center
					flex-direction column
					&.menu-item-chirp
						width auto
					.name
						display block
					.new
						top 8px
						left 59%
						width 20px
						height 20px
					.icon
						width 24px !important
						height 24px !important
						transform translateY(4px)

				.separator
					display none

#app.tablet-vertical, #app.mobile
	.main-menu
		.menu
			.menu-content
				.menu-item, .menu-item.menu-item-chirp
					width 100%
				.separator
					display none

#app.touch
	.main-menu
		.menu
			.menu-item:active
				background-color alpha(#fff, 10%)
		

#app.ipad-theme
	.main-menu
		background alpha(#fff, 10%)
		backdrop-filter blur(10px)


.main-menu
	position absolute
	left 0
	top 0
	right 0
	width 100%
	height 80px
	// padding 24px 0 0 0
	background-color var(--color-menu-bg)
	// box-shadow inset -4px 0 0 0 var(--color-menu-border)
	color var(--color-menu-text)
	text-transform uppercase
	font-weight 500
	text-align left
	// transition width 0.25s easeOutQuart
	font-weight 600
	font-family var(--josefin)
	z-index 3
	user-select none
	&.master
		.menu
			.menu-item
				padding-right 4px
	.menu
		height 100%
		width 100%
		.menu-content
			flex flex-end center
			height 100%
			width 100%
			.menu-item
				flex flex-start center
				flex-direction column
				// padding 16px 0
				// height 92px
				// padding 0 0 0 24px
				cursor pointer
				width 110px
				// font-size 1.8rem
				&.disabled
					opacity 0.1
					cursor not-allowed
				&.active
					opacity 0.4
				&.files
					.icon
						transform translateY(-4px)
				&.notes
					.icon
						transform translateY(-6px)
				&.menu-item-chirp
					width 200px
				.icon
					display block
					width 40px
					height 40px
					margin 0 0 8px 0
					&.shake
						animation shake 1s infinite linear
				.name
					display block
					width 100%
					text-align center
				.new
					position absolute
					left 60px
					top 16px
					width 24px
					height 24px
					padding-top 2px
					border-radius 50%
					flex center center
					box-shadow 0 0 0 3px var(--color-menu-bg)
					background-color var(--color-menu-new-badge-bg)
					font-size 1.8rem
					color var(--color-menu-new-badge-text)
			.separator
				display inline-block
				height calc(100% - 16px)
				width 2px
				margin 0 16px
				background-color var(--color-menu-border)



@keyframes shake
	0%
		transform rotate(0deg)
	45%
		transform rotate(0deg)
	46%
		transform rotate(-10deg)
	54%
		transform rotate(10deg)
	55%
		transform rotate(0deg)
	100%
		transform rotate(0deg)
		

</style>
