<template>
	<div class="room-menu">
		<div class="room-state">{{ game.room.state }}</div>
		<div class="main-title"><ui-icon name="login-game-master" :size="32" />{{ current_room_name }}</div>
		<div class="menu">
			<div class="root" :class="{ group: $route.name === 'rooms' || $route.name === 'new-room' }">
				<ui-link to="/master/rooms" class="item first-group-item" >
					<div class="group-dot"></div>
					<ui-icon name="master/rooms" :size="32" />Sessions
				</ui-link>
				<ui-link v-show="$route.name === 'rooms' || $route.name === 'new-room'" to="/master/rooms/add" class="item green new-game last-group-item">
					<div class="group-dot"></div>
					<ui-icon name="master/new-game" :size="32" /> New game
				</ui-link>
			</div>
			<div class="group">
				<ui-link :to="'/master/rooms/' + game.room.id + '/roles'" class="item first-group-item">
					<div class="group-dot"></div>
					<ui-icon name="master/roles" :size="32" /> Player Roles
					<ui-link :to="'/master/rooms/' + game.room.id" class="close"></ui-link>
				</ui-link>
				<ui-link :to="'/master/rooms/' + game.room.id + '/game-access'" class="item" :class="{ 'last-group-item': !game.scenario.enable_stats }">
					<div class="group-dot"></div>
					<ui-icon name="master/access" :size="32" /> Game Access
					<ui-link :to="'/master/rooms/' + game.room.id" class="close"></ui-link>
				</ui-link>
				<ui-link v-if="game.scenario.enable_stats" :to="'/master/rooms/' + game.room.id + '/stats'" class="item last-group-item">
					<div class="group-dot"></div>
					<ui-icon name="menu/menu-stats" :size="32" /> Stats
					<ui-link :to="'/master/rooms/' + game.room.id" class="close"></ui-link>
				</ui-link>

				<div class="separator"></div>

				<ui-link :to="'/master/rooms/' + game.room.id + '/launch-videos'" class="item blue">
					<ui-icon name="profile/profile-video" :size="32" />
					<span>Video launcher</span>
				</ui-link>


				<ui-link v-if="game.room.state === 'running'" :to="'/master/rooms/' + game.room.id + '/stop'" class="item yellow">
					<ui-icon name="master/pause" :size="32" />
					<span>Pause game</span>
				</ui-link>

				<ui-link v-else-if="game.room.state === 'paused' || game.room.state === 'stopped'" :to="'/master/rooms/' + game.room.id + '/start'" class="item green">
					<ui-icon name="master/play" :size="32" />
					<span v-if="game.room.state === 'paused'">Resume game</span>
					<span v-else>Start game</span>
				</ui-link>

				<ui-link v-else-if="game.room.state === 'ending' && scenario && game.room.episode.episode_number < scenario.episodes.length - 1" :to="'/master/rooms/' + game.room.id + '/next'" class="item green">
					<ui-icon name="master/next-episode" :size="32" />
					<span>Next episode</span>
				</ui-link>
			</div>

			<!-- <ui-link to="/master/wiki" class="item"><ui-icon name="master/roles-wiki" :size="32" /> Roles wiki <ui-link to="/master" class="close"></ui-link></ui-link> -->
			<!-- <ui-link to="/master/tutorial" class="item"><ui-icon name="master/tutorial" :size="32" /> Tutorial <ui-link to="/master" class="close"></ui-link></ui-link> -->
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'master-menu',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'master', 'game' ]),
		current_room_name() {
			if (this.game.room) {
				let name = this.game.room.name
				if (name.length > 18) return name.substr(0, 18) + '...'
				return name
			}
			return ''
		},
		is_game_access() {
			return this.$route.matched.findIndex((r) => r.name === 'room-game-access') > -1
		},
		scenario() {
			return this.master.scenarios.find(s => parseInt(s.id) === parseInt(this.game.room.scenario_id))
		}
	},
	// methods: {
	// 	start_game() {
	// 		this.$store.dispatch('start_game_session')
	// 	}
	// }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.mobile
	.room-menu
		width 100%
		padding 24px
		.menu
			.item
				&.active
					transform none
					.close
						display none
				&.new-game
					display none
		.logout
			left calc(50% - 50px)



.room-menu
	.room-state
		text-transform uppercase
		color blue_lighter
		font-weight 700
		margin-left 48px
		font-family var(--josefin)
		font-size 1.1rem
	.menu
		flex flex-start flex-start
		flex-direction column
		.root
			width 100%
			transition 0.25s easeOutQuart
			.item
				.group-dot
					display none
		.group
			width 100%
			transform translateX(24px)
			transition 0.25s easeOutQuart
			&.root
				.item
					.group-dot
						display block
		> .item
			&.active
				background-color lighten(blue, 5%)
				cursor default
				transform translateX(24px)
				.close
					display block
		
		.separator
			height 1px
			width 100%
			margin 16px 0
			background-color #4B8194
			
		.item
			background-color blue
			height 64px
			width 100%
			margin 0 0 8px 0
			padding 6px 24px 0 24px
			border-radius 4px
			flex flex-start center
			cursor pointer
			transition transform 0.25s easeOutQuart
			font-size 2rem
			text-transform uppercase
			white-space nowrap
			font-family var(--josefin)
			&.active
				.close
					display block
			// &.new-game-enter-active, &.new-game-leave-active
			// 	transition 0.25s easeOutQuart
			// &.new-game-enter, &.new-game-leave-to
			// 	opacity 0
			// 	transform translateX(-24px)
			// &.new-game-enter-active
			// 	transition-delay 0.15s

			&:hover
				background-color lighten(blue, 5%)
			&.green
				background-color #3FB143
				&:hover
					background-color lighten(#3FB143, 10%)
			&.yellow
				background-color #E5A207
				&:hover
					background-color lighten(#E5A207, 10%)
			&.blue
				background-color blue_light
				&:hover
					background-color lighten(blue_light, 10%)
				
			&.first-group-item
				.group-dot:before
					top 50%
			&.last-group-item
				.group-dot:before
					bottom 50%
			&.active
				.group-dot:after
					background-color blue_lighter
					box-shadow 0 0 0 4px blue_dark
					transform scale(1)
			.group-dot
				position absolute
				left -24px
				top 0
				bottom 0
				width 24px
				height 100%
				&:before
					content ''
					position absolute
					left 7px
					top -4px
					bottom -4px
					width 2px
					background-color blue_lighter
				&:after
					content ''
					position absolute
					left 0
					top calc(50% - 8px)
					width 16px
					height 16px
					background-color blue_dark
					border-radius 50%
					box-shadow inset 0 0 0 2px blue_lighter, 0 0 0 8px blue_dark
					transform scale(0.5)
					transition transform 0.25s easeOutQuart

			.ui-icon
				margin -6px 16px 0 0
			.close
				display none
				position absolute
				right -20px
				top calc(50% - 20px)
				width 40px
				height 40px
				border-radius 50%
				cursor pointer
				background #fff url(../../../assets/img/close-blue-dark.svg) center center no-repeat
				background-size 16px auto
				box-shadow 0 0 0 4px blue_dark
				&.return
					background-image url(../../../assets/img/arrow-left.svg)
					background-size 24px auto

	
</style>
