<template>
	<form class="new-room" @submit="submit($event)">
		<p class="main-title">Adding a session</p>
		
		<div class="line">
			<label class="label">Room name</label>
			<ui-input v-model="name" placeholder="Room name" />
		</div>
		<div class="line">
			<label class="label">Scenario</label>
			<ui-select v-model="scenario" :options="master.scenarios" placeholder="Choose a scenario" />
		</div>
		<!-- <div class="line">
			<label class="label">Number of players</label>
			<p class="description">You can add more player once inside the room.</p>
			<div class="counter">
				<span class="arrow left" v-hammer:tap="() => decrement()"></span>
				<input type="text" class="no" v-model="nb_players">
				<span class="arrow right" v-hammer:tap="() => increment()"></span>
			</div>
		</div> -->
		<div class="buttons">
			<ui-button type="submit" :inactive="!name || !scenario" v-hammer:tap="() => add_room()" :loading="loading"><span>Submit</span></ui-button>
		</div>
	</form>
</template>

<script>
import { mapGetters } from 'vuex'
// import Apollo from '@/apollo/apollo-client'
import UiInput from '@/components/ui/ui-input'
import UiSelect from '@/components/ui/ui-select'
import UiButton from '@/components/ui/ui-button'

export default {
	name: 'new-room-window',
	data() {
		return {
			loading: false,
			name: "",
			scenario: "",
			nb_players: 1,
			// scenarios: []
		}
	},
	computed: {
		...mapGetters([ 'app', 'master' ])
	},
	methods: {
		decrement() {
			if (this.nb_players > 1) this.nb_players--
		},
		increment() {
			this.nb_players++
		},
		submit(e) {
			e.preventDefault()
			this.add_room()
		},
		async add_room() {
			if (this.name && this.scenario && !this.loading) {
				this.loading = true
				await this.$store.dispatch('create_room', { name: this.name, scenario_id: this.scenario })
				this.$router.go(-1)
			}
		}
	},
	async mounted() {
		this.$el.querySelector('input').focus()
		// this.scenarios = await Apollo.query('ALL_SCENARIOS')
	},
	components: { UiInput, UiButton, UiSelect }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.new-room
	width 100%
	.main-title
		margin 0 0 16px 0
		font-size 1.8rem
		text-transform uppercase
		color blue_lighter
	.text
		margin 0 0 16px 0
		color gray_light
		font-size 1.6rem
		line-height 22px
	.line
		margin 0 0 16px 0
		width 100%
		.label
			display block
			width 100%
			text-align left
			text-transform uppercase
			color blue_lighter
		.description
			color gray
			text-align left
		.ui-input, .ui-select
			background-color alpha(#fff, 15%)
			height 48px
			width 100%
			border-radius 8px
			padding 0 24px
			font-size 1.6rem
			color #fff
			-webkit-appearance none
			-moz-appearance none
			appearance none
			&:focus
				background-color alpha(#fff, 20%)
		.counter
			flex center center
			margin 24px 0
			height 64px
			text-align center
			color blue_lighter
			.arrow, .no
				display inline-block
				height 64px
				width 64px
				// margin 0 8px
			.no
				display inline-block
				width 80px
				background none
				border none
				padding 6px 0 0 0
				font inherit
				color inherit
				text-align center
				font-size 4rem
				font-family var(--josefin)
				line-height 68px
				&:hover
					background-color alpha(#fff, 5%)
				&:focus
					background-color alpha(#fff, 5%)
			.arrow
				cursor pointer
				&:before
					content ''
					position absolute
					left (32px - 12px)
					top (32px - 16px)
					width 0
					height 0
					border-style solid
				&.left:before
					border-width 16px 24px 16px 0
					border-color transparent blue_lighter transparent transparent
				&.right:before
					border-width 16px 0 16px 24px
					border-color transparent transparent transparent blue_lighter
	.buttons
		>>> .ui-button
			&.inactive
				.button
					background-color gray
			.button
				border-radius 8px
				background-color #3FB143
				text-transform uppercase
				font-size 1.8rem
				font-family var(--josefin)
				.loader
					border-radius 8px
					&.loading
						background-color #3FB143
				> span
					transform translateY(2px)

			
::-webkit-input-placeholder
	color alpha(#fff, 25%)
	opacity 1
::-moz-placeholder
	color alpha(#fff, 25%)
	opacity 1
:-ms-input-placeholder
	color alpha(#fff, 25%)
	opacity 1
:-moz-placeholder
	color alpha(#fff, 25%)
	opacity 1

	
</style>
