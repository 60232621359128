<template>
	<div v-if="scenario" class="scenario-item">
		<div class="header">{{ scenario.name }}</div>

		<div v-show="!expanded" class="shrink-content">
			<div class="info">
				<div class="label">Subject</div>
				<div class="value small">{{ scenario.subject ? scenario.subject : '-' }}</div>
			</div>
			<div class="info">
				<div class="label">Amount of players</div>
				<div class="value">{{ scenario.amount_of_player ? scenario.amount_of_player : '-' }}</div>
			</div>
			<div class="info">
				<div class="label">Recommended grade</div>
				<div class="value">{{ scenario.recommended_for ? scenario.recommended_for : '-' }}</div>
			</div>
			<div class="button">
				<button :block="true" color="blue-light" @click="toggle">Show more</button>
			</div>
		</div>

		<div v-show="expanded" class="expanded-content">
			<div class="description">
				<p>{{ scenario.description }}</p>
				<p>{{ scenario.scenario_overview }}</p>
			</div>
			<div class="info info-1">
				<div class="label">Subject</div>
				<div class="value small">{{ scenario.subject ? scenario.subject : '-' }}</div>
			</div>
			<div class="info info-2">
				<div class="label">Amount of players</div>
				<div class="value">{{ scenario.amount_of_player ? scenario.amount_of_player : '-' }}</div>
			</div>
			<div class="info info-3">
				<div class="label">Recommended grade</div>
				<div class="value">{{ scenario.recommended_for ? scenario.recommended_for : '-' }}</div>
			</div>
			<div class="info info-4">
				<div class="label">Episode count</div>
				<div class="value">{{ scenario.episodes.length ? scenario.episodes.length : '-' }}</div>
			</div>
			<div class="info info-5">
				<div class="label">Episode length</div>
				<div class="value">{{ scenario.avg_episode_length ? scenario.avg_episode_length : '-' }}</div>
			</div>

			<div class="info info-6 adapted" :class="{ inactive: !scenario.adapted_online }">
				<!-- <ui-icon name="play-video" /> -->
				<span class="text">Designed for remote online play</span>
			</div>
			<div class="info info-7 adapted" :class="{ inactive: !scenario.adapted_remote }">
				<!-- <ui-icon name="play-video" /> -->
				<span class="text">Designed for in person play</span>
			</div>
			<div class="button">
				<button :block="true" color="blue-light" @click="toggle">Show less</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			expanded: false
		}
	},
	props: {
		scenario: { default: null },
	},
	methods: {
		toggle(e) {
			// e.srcEvent.propagationStopped = true
			this.expanded = !this.expanded
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

.scenario-item
	width 100%
	background-color var(--color-scenario-item-bg)
	padding 16px
	border-radius 6px
	color var(--color-scenario-item-text)
	.header
		height 40px
		margin 0 0 16px 0
		white-space nowrap
		border-bottom 2px solid var(--color-scenario-item-border)
		text-transform uppercase
		font-size 2rem
		font-family var(--josefin)
		line-height (@height - 4px)
		text-align left
	.shrink-content
		display grid
		grid-template-columns repeat(4, 1fr)
		gap 8px
		height 80px
		// .info:nth-child(1)
		// 	grid-area 1 / 1 / 2 / 3
		// .info:nth-child(2)
		// 	grid-area 1 / 3 / 2 / 5
		// .info:nth-child(3)
		// 	grid-area 1 / 5 / 2 / 6
	.expanded-content
		display grid
		grid-template-columns repeat(5, 1fr)
		// grid-template-rows repeat(3, 1fr)
		grid-template-rows repeat(3, minmax(0px, auto))
		gap 8px
		// height 200px
		.description
			grid-area 1 / 1 / 2 / 6
			// height 80px
			// flex center flex-start
			font-weight 300
			padding-bottom 8px
		.info-1
			grid-area 2 / 1 / 3 / 2
			height 120px
		.info-2
			grid-area 2 / 2 / 3 / 3
			height 120px
		.info-3
			grid-area 2 / 3 / 3 / 4
			height 120px
		.info-4
			grid-area 2 / 4 / 3 / 5
			height 120px
		.info-5
			grid-area 2 / 5 / 3 / 6
			height 120px
		.info-6
			grid-area 3 / 1 / 4 / 3
			height 56px
		.info-7
			grid-area 3 / 3 / 4 / 5
			height 56px
		.button
			grid-area 3 / 5 / 4 / 6
			height 56px
			
	.info
		padding 8px
		background-color var(--color-scenario-item-value)
		border-radius 4px
		text-align center
		&.adapted
			flex center center
			text-align left
			line-height 1.2em
			padding 8px 16px
			cursor default
			&.inactive
				opacity 0.25
				text-decoration line-through
			.ui-icon
				margin 0 16px 0 0
		.label
			display block
			height 24px
			margin 0 0 4px 0
			border-bottom 1px solid var(--color-scenario-item-border)
			text-transform uppercase
			font-size 1.2rem
			line-height @height
			white-space nowrap
		.value
			height calc(100% - 25px)
			font-size 2.2rem
			flex center center
			text-align center
			line-height 1em
			&.small
				font-size 1.4rem
				line-height 1.2em
	
	.button button
		width 100%
		height 100%
		background var(--color-scenario-item-button-bg)
		border none
		outline none
		border-radius 4px
		text-transform uppercase
		cursor pointer
		font inherit
		font-family var(--josefin)
		text-transform uppercase
		font-size 1.8rem
		color inherit
		&:hover
			background-color var(--color-scenario-item-button-bg-hover)

</style>
