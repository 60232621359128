<template>
	<div class="chirps">
		<template v-if="feed.length">
			<chirp-item v-for="chirp_item in feed" :chirp_item="chirp_item" :key="chirp.id" :allow_interactions="false" />
		</template>

		<div class="no-chirps" v-else>No chirps found</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { slugify } from '@/js/utils/utils'
import ChirpItem from '@/components/game/chirp/elements/chirp-item'

export default {
	name: 'chirps',
	data() {
		return {
			
		}
	},
	props: {
		player_filter: { default: null },
		view_filter: { default: null },
	},
	computed: {
		...mapGetters([ 'chirp' ]),
		feed() {
			let feed = [ ...this.chirp.feed ]
			if (this.player_filter && this.player_filter.id) {
				feed = feed.filter((c) => {
					if (c.sender_player && c.sender_player.id) return parseInt(c.sender_player.id) === parseInt(this.player_filter.id)
					return false
				})
			}
			if (this.view_filter === 'student') {
				feed = feed.filter((c) => c.sender_player && c.sender_player.student_number && c.sender_player.student_number !== "N/A")
			}
			return feed.concat().sort((a, b) => { return (a.created > b.created) ? -1 : 1 })
		}
	},
	created() {
		
	},
	components: { ChirpItem }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.chirps
	height 100%
	.no-chirps
		width 100%
		margin 80px 0 0 0
		text-align center
		font-family var(--josefin)
		font-size 2rem
		text-transform uppercase
		color blue_lighter
		opacity 0.25


</style>
