<template>
	<div class="logout">
		<p class="title">Log out</p>
		<p class="text">You are about to log out. Login out will also result in the disconnection of all players in any active game room. Progress will be saved to a certain extent.</p>
		<p class="question">Do you want to log out?</p>
		<div class="buttons">
			<ui-button v-hammer:tap="() => no()" class="no"><span>No</span></ui-button>
			<ui-button v-hammer:tap="() => yes()" class="yes"><span>Yes</span></ui-button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiButton from '@/components/ui/ui-button'

export default {
	name: 'logout',
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters([ 'master' ])
	},
	methods: {
		no() {
			this.$router.go(-1)
		},
		yes() {
			this.$store.dispatch('logout')
		}
	},
	components: { UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.logout
	.title
		margin 0 0 24px 0
		padding 0 0 24px 0
		font-size 1.8rem
		text-transform uppercase
		color blue_lighter
		border-bottom 2px solid blue_lighter
	.text
		margin 0 0 24px 0
		color gray_light
		font-size 1.6rem
		line-height 22px
	.question
		margin 0 0 24px 0
		color blue_lighter
		font-size 1.6rem
		text-transform uppercase
		line-height 22px
	.buttons
		margin 24px 0 0 0
		>>> .ui-button
			&.no
				.button
					background-color #B93636
			.button
				margin 0 8px
				border-radius 8px
				background-color #3FB143
				text-transform uppercase
				font-size 1.8rem
				font-family var(--josefin)
				.loader
					border-radius 8px
					&.loading
						background-color #3FB143
				> span
					transform translateY(2px)

	
</style>
