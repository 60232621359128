<template>
	<div v-if="selected_question" class="decision-answers" :key="selected_question.id">

		<main-title v-if="selected_question && !correct_answer" :text="select_option_text" :closable="false" />

		<div v-if="selected_question && !correct_answer" class="answers">
			<div v-for="answer in selected_question.answer"
				:key="answer.id"
				class="answer"
				:class="{ checked: checked_answer && checked_answer.id === answer.id, unchecked: checked_answer && checked_answer.id !== answer.id }"
				v-hammer:tap="() => toggle_check_answer(answer)"
			>
				<div class="text"><ui-text :text="answer.answer" /></div>
				<div class="checkbox">
					<ui-icon v-if="checked_answer && checked_answer.id === answer.id" class="check-circle" key="a" name="check-circle" :size="30" />
					<ui-icon v-else class="circle" key="b" name="circle" :size="26" />
				</div>
			</div>
		</div>

		<div v-if="selected_question && !correct_answer && game.room.state !== 'paused'" class="buttons">
			<ui-button v-hammer:tap="() => send_answer(checked_answer)" :loading="loading" :inactive="!checked_answer"><span>Submit</span></ui-button>
		</div>

		<div v-if="game.room.state === 'paused' && !correct_answer" class="paused-overlay">
			<p>The game is paused.</p>
			<p>Awaiting for game resume...</p>
		</div>

		<transition name="outcome">
			<div v-if="(selected_answer || correct_answer)" class="outcome">
				<div v-if="selected_question.type === 'Solo' || (correct_answer && correct_answer.result_answer && correct_answer.result_answer.answer)" class="outcome-main" v-scrollbar>
					<div>

						<div v-if="outcome_view === 'outcome'" class="page-result" key="outcome">
							<div class="page-result-title">The outcome</div>
							
							<div class="page-result-subtitle">· · · Your choice · · ·</div>
							<transition name="page-result-answer" appear>
								<div class="page-result-answer">
									<ui-text v-if="selected_question.type === 'Solo'" :text="correct_answer.answer" />
									<ui-text v-else :text="correct_answer.result_answer.answer" />
								</div>
							</transition>

							<transition name="page-result-outcome" appear>
								<div class="page-result-outcome">
									<div class="text">
										<ui-text v-if="selected_question.type === 'Solo'" :text="correct_answer.feedback" />
										<ui-text v-else :text="correct_answer.result_answer.feedback" />
									</div>
									<div class="label">Results in</div>
								</div>
							</transition>

							<transition name="page-result-hourglass" appear>
								<div class="page-result-hourglass">
									<ui-hourglass />
									<p>LOADING</p>
								</div>
							</transition>
						</div>
						<div v-else-if="outcome_view === 'all_options'" class="page-result" key="all_options">
							<div class="page-result-title">All Options</div>
							
							<transition-group tag="div" name="option-answer" class="page-result-all-answers" appear>
								<div v-for="answer in all_answers" :key="answer.id" class="option-answer" :class="{ selected: progress.selected_answers.includes(parseInt(answer.id)) }">
									<div class="option-answer-position" v-html="getStrRank(all_answers_sorted_rank[parseInt(answer.id)])"></div>
									<div class="option-answer-box">
										<div class="option-answer-text">
											<ui-text :text="answer.answer" />
										</div>
										<ul class="option-answer-users">
											<li v-if="selected_question.type === 'Solo'">
												<span v-if="progress.selected_answers.includes(parseInt(answer.id))" class="users">
													<span class="user c-full">
														<ui-icon class="user-full" name="progress/user-full" size="24" />
													</span>
												</span>
												<span v-else class="users">
													<span class="user c-empty">
														<ui-icon class="user-empty" name="progress/user-empty" size="24" />
													</span>
												</span>
											</li>
											<li v-else-if="correct_answer && correct_answer.answers">
												<span class="users">
													<span v-for="(u, index) in majority_users" :key="index" class="user" :class="{ 'c-full': index >= correct_answer.answers.length - correct_answer.answers.filter((a) => { return parseInt(a) === parseInt(answer.id) }).length }">
														<ui-icon class="user-empty" name="progress/user-empty" size="24" />
														<ui-icon class="user-full" name="progress/user-full" size="24" />
														<ui-icon class="user-crown" name="progress/user-crown" size="24" />
													</span>
												</span>
											</li>
											<!-- <li v-if="progress.selected_answers.includes(parseInt(answer.id))" class="own">This was your choice</li> -->
										</ul>
									</div>
								</div>
							</transition-group>
							
						</div>

						<!-- <div class="box">
							<br>
							<div class="box-title">
								<span v-if="selected_question.type === 'Solo'">Your choice</span>
								<span v-if="selected_question.type === 'Majority'">Majority's choice</span>
								<span v-if="selected_question.type === 'Decider'">Decider's choice</span>

								<div v-if="majority_users" class="users">
									<div v-for="(c, index) in majority_users" :key="index" class="user" :class="c">
										<ui-icon class="user-empty" name="progress/user-empty" size="24" />
										<ui-icon class="user-full" name="progress/user-full" size="24" />
										<ui-icon class="user-crown" name="progress/user-crown" size="24" />
									</div>
								</div>
							</div>
							<div class="box-content">
								<div class="text">
									<ui-text v-if="selected_question.type === 'Solo'" :text="correct_answer.answer" />
									<ui-text v-else :text="correct_answer.result_answer.answer" />
								</div>
								<ul v-if="correct_answer && correct_answer.result_answer && correct_answer.answers" class="result-stats">
									<li>Has been chosen by {{ correct_answer.answers.filter((a) => { return parseInt(a) === parseInt(correct_answer.result) }).length }} of {{ correct_answer.answers.length }} deciders</li>
									<li v-if="progress.selected_answers.includes(parseInt(correct_answer.result))" class="own">This was also your choice</li>
									<li v-else class="own">This was not your choice</li>
									<li v-if="!correct_answer.majority" class="gold">This was the tiebreaker's choice</li>
								</ul>
							</div>
						</div>

						<div v-if="correct_answer.feedback || (correct_answer.result_answer && correct_answer.result_answer.feedback)" class="box">
							<div class="box-title">Outcome</div>
							<div class="box-content">
								<div class="text">
									<ui-text v-if="selected_question.type === 'Solo'" :text="correct_answer.feedback" />
									<ui-text v-else :text="correct_answer.result_answer.feedback" />
								</div>
							</div>
						</div>

						<div class="box losing">
							<div class="box-title">Not chosen</div>
							<div class="box-content">
								<div v-for="answer in wrong_answers" :key="answer.id" class="option-answer">
									<div class="text">
										<ui-text :text="answer.answer" />
									</div>
									<ul class="result-stats">
										<li v-if="correct_answer && correct_answer.answers">
											Has been chosen by {{ correct_answer.answers.filter((a) => { return parseInt(a) === parseInt(answer.id) }).length }} of {{ correct_answer.answers.length }} deciders
											<span class="users">
												<span v-for="(u, index) in majority_users" :key="index" class="user" :class="{ 'c-full': index >= correct_answer.answers.length - correct_answer.answers.filter((a) => { return parseInt(a) === parseInt(answer.id) }).length }">
													<ui-icon class="user-empty" name="progress/user-empty" size="24" />
													<ui-icon class="user-full" name="progress/user-full" size="24" />
													<ui-icon class="user-crown" name="progress/user-crown" size="24" />
												</span>
											</span>
										</li>
										<li v-if="progress.selected_answers.includes(parseInt(answer.id))" class="own">This was your choice</li>
									</ul>
								</div>
							</div>
						</div> -->

					</div>
				</div>
				<div v-else-if="selected_question.type === 'Algorithmic' && correct_answer" class="outcome-main algorithmic" v-scrollbar>
					<div>
						<div class="box">
							<br>
							<div class="box-title">
								<span>You decided on</span>
							</div>
							<div class="box-content">
								<div class="units">
									{{ algorithmic_selected_units }} units
									<span class="small">of {{ algorithmic_total_units }}</span>
								</div>
								<ui-text class="description" :text="'This represents ' + Math.round(algorithmic_selected_units * 100 / algorithmic_total_units) + '% of your yearly available units.'"></ui-text>
							</div>
							
						</div>
					</div>
				</div>
				<div v-else class="waiting">
					<ui-text :text="'# Awaiting all votes...\r\rThe decision will be updated once the time has run out.'" />
				</div>

				<transition name="toggle-outcome-button" appear>
					<div v-if="selected_question.type === 'Solo' || (correct_answer && correct_answer.result_answer && correct_answer.result_answer.answer)" class="toggle-outcome-button" v-hammer:tap="() => toggle_outcome()">
						<span v-if="outcome_view === 'outcome'">See all options</span>
						<span v-else-if="outcome_view === 'all_options'">See outcome</span>
					</div>
				</transition>
			</div>
		</transition>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import MainTitle from '@/components/game/main-title'
import UiButton from '@/components/ui/ui-button'
import UiText from '@/components/ui/ui-text'
import UiHourglass from '@/components/ui/ui-hourglass'

export default {
	name: 'decision-answers',
	data() {
		return {
			loading: false,
			checked_answer: null,
			outcome_view: 'outcome'
		}
	},
	props: {
		selected_question: { default: null },
		selected_answer: { default: null },
		correct_answer: { default: null }
	},
	computed: {
		...mapGetters([ 'game', 'progress', 'logged_user', 'current_question', 'selected_question_config' ]),
		select_option_text() {
			let str = "Select an option"
			if (this.selected_question && this.selected_question.type === 'Majority') str += " • Majority vote"
			if (this.selected_question && this.selected_question.type === 'Decider') str += " • Decider vote"
			return str
		},
		majority_users() {
			let users = []
			if (this.correct_answer && this.correct_answer.answers) {
				this.correct_answer.answers.forEach((id) => {
					if (id === this.correct_answer.result) users.push('c-full')
					else users.push('a-empty')
				})
				if (!this.correct_answer.majority) {
					let index = users.lastIndexOf('c-full')
					if (index > -1) users[index] = 'b-crown'
				}
				return users.sort()
			}
			return users
		},
		all_answers_sorted_ids() {
			if (this.correct_answer && this.correct_answer.answers) {
				const map = [ ...this.correct_answer.answers, ...this.selected_question.answer.map(a => parseInt(a.id)) ].reduce((p, c) => {
					p[c] = (p[c] || 0) + 1
					return p
				}, {})
				return Object.keys(map).sort((a, b) => map[b] - map[a])
			} else {
				const ids = this.selected_question.answer.map(a => parseInt(a.id))
				const selected_answer_id = ids.find(id => this.progress.selected_answers.includes(parseInt(id)))
				if (selected_answer_id) {
					return [ selected_answer_id, ...ids.filter(id => id !== selected_answer_id) ]
				}
				return ids
			}
		},
		all_answers_sorted_rank() {
			if (this.all_answers_sorted_ids.length) {
				let current_rank = 0
				let last_nb = null

				const rank = this.all_answers_sorted_ids.reduce((acc, id) => {
					const nb = this.correct_answer?.answers?.filter(aid => parseInt(aid) === parseInt(id))?.length || 0
					if (!last_nb || nb < last_nb) current_rank++
					acc[parseInt(id)] = current_rank
					last_nb = nb
					return acc
				}, {})
				return rank
			} else {
				return this.all_answers.reduce((acc, a) => {
					const id = parseInt(a.id)
					if (id === parseInt(this.correct_answer.id)) acc[id] = 1
					else acc[id] = 2
					return acc
				}, {})
			}
		},
		all_answers() {
			if (this.all_answers_sorted_ids.length) {
				return this.all_answers_sorted_ids.reduce((acc, id) => {
					const q = this.selected_question.answer.find(a => parseInt(a.id) === parseInt(id))
					return q ? [ ...acc, q ] : acc
				}, [])
			}
			return this.selected_question.answer
		},
		wrong_answers() {
			if (this.correct_answer) {
				const id = this.correct_answer.result ? parseInt(this.correct_answer.result) : parseInt(this.correct_answer.id)
				return this.selected_question.answer.filter((a) => parseInt(a.id) !== id)
			}
			return []
		},
		algorithmic_selected_units() {
			if (this.selected_question && this.selected_question.type === 'Algorithmic' && this.selected_question.majority_answer) {
				const a = this.selected_question.majority_answer.unit_details.find(u => parseInt(u.role_id) === parseInt(this.logged_user.role.id))
				if (a) return a.unit
			}
			return null
		},
		algorithmic_total_units() {
			if (this.selected_question && this.selected_question.type === 'Algorithmic') {
				if (this.selected_question_config && this.selected_question_config.units) {
					return this.selected_question_config.units.answers[this.selected_question_config.units.answers.length - 1].value
				}
				// const answer = this.selected_question.majority_answer ? this.selected_question.majority_answer : this.selected_question.awaiting_algorithmic_answer
				// if (answer) return answer.unit_total
			}
			return null
		}
	},
	watch: {
		'selected_question.id'() {
			this.outcome_view = 'outcome'
		}
	},
	methods: {
		getStrRank(rank) {
			if (rank) {
				if (rank === 1) return '1<sup>st</sup>'
				if (rank === 2) return '2<sup>nd</sup>'
				if (rank >= 3) return rank + '<sup>rd</sup>'
			}
			return ''
		},
		toggle_outcome() {
			if (this.outcome_view === 'outcome')
				this.outcome_view = 'all_options'
			else
				this.outcome_view = 'outcome'
		},
		show_infobox(data) {
			if (data.slug) {
				this.$store.dispatch('add_info_box', { slug: data.slug })
			}
			else if (data.question) {
				if (data.question.type === 'Majority') {
					let params = { TIE_BREAKER_ROLE_NAME: '???' }
					let tie_breaker = this.game.roles.find((r) => { return parseInt(r.id) === data.question.tiebreaker })
					if (tie_breaker && tie_breaker.name) params = { TIE_BREAKER_ROLE_NAME: tie_breaker.name }
					this.$store.dispatch('add_info_box', { slug: 'majority_intro', params })
				}
				if (data.question.type === 'Decider') {
					const decider = data.question.deciders.find((d) => { return d === parseInt(this.logged_user.role.id) })
					if (decider) this.$store.dispatch('add_info_box', { slug: 'decider' })

					const contributor = data.question.contributors.find((d) => { return d === parseInt(this.logged_user.role.id) })
					if (contributor) this.$store.dispatch('add_info_box', { slug: 'contributor' })
				}
			}
		},
		toggle_check_answer(answer) {
			if (this.checked_answer && this.checked_answer.id === answer.id)
				this.checked_answer = null
			else
				this.checked_answer = answer
		},
		// async get_algorithmic_result(question) {
		// 	if (this.selected_question.type === 'Algorithmic' && !question.awaiting_algorithmic_answer) {
		// 		const awaiting_algorithmic_answer = await Apollo.query('GET_POLLING_RESULT', { room_id: this.game.room.id, question_id: question.id, calculate: true })
		// 		this.$store.commit('EDIT_PROGRESS_QUESTION', { question, awaiting_algorithmic_answer })
		// 	}
		// },
		async send_answer(answer) {
			if (this.checked_answer) {
				if (!this.loading) {
					this.loading = true
					try {
						await this.$store.dispatch('send_answer', { question: this.selected_question, answer })
						if (this.selected_question.type !== 'Solo') {
							this.show_infobox({ slug: 'majority_awaiting_vote' })
							// await this.get_algorithmic_result(this.selected_question)
						}
					} catch (error) {
						console.log(error)
					}
					this.loading = false
				}
			}
		}
	},
	// created() {
	// 	if (this.selected_question) this.get_algorithmic_result(this.selected_question)
	// },
	components: { UiText, UiButton, MainTitle, UiHourglass }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.desktop, #app.tablet-horizontal
	.decision-answers
		.outcome
			transition 0.25s easeOutQuart
			&.outcome-enter, &.outcome-leave-to
				transform translateX(110%)
			.outcome-main
				.box
					&:before
						background-color var(--color-decisions-outcome-majority-choice-bg)
					&.losing:before
						background linear-gradient(to bottom, var(--color-decisions-outcome-majority-choice-bg) 0%, var(--color-decisions-outcome-bg) 10%, var(--color-decisions-outcome-bg) 100%)

#app.mobile, #app.tablet-vertical
	.decision-answers
		padding 0 24px
		.outcome
			min-height 200px
			.outcome-main
				padding 0 24px
				.box:before
					left -24px
					right -24px
			.waiting
				padding 0 24px
	

.decision-answers
	// min-height 100%
	// background-color darken(blue_darker, 15%)
	padding 0 32px
	background-color transparent

	.answers
		.answer
			padding 16px
			width 100%
			margin 8px 0
			flex flex-start flex-start
			border-radius 4px
			background-color var(--color-decisions-answer-bg)
			color var(--color-decisions-answer-text)
			cursor pointer
			// &:hover
			// 	.checkbox
			// 		background-color lighten(blue, 5%)
			&.checked
				background-color var(--color-decisions-answer-bg-checked)
				.checkbox
					background var(--color-decisions-answer-checkbox-bg-checked)
					color var(--color-decisions-answer-checkbox-text-checked)
			&.unchecked
				background-color var(--color-decisions-answer-bg-unchecked)
			.text
				width calc(100% - 48px)
				>>> .ui-text p:last-child
					margin 0
			.checkbox
				position absolute
				right 8px
				top 8px
				bottom 8px
				width 48px
				border-top-right-radius 4px
				border-bottom-right-radius 4px
				background var(--color-decisions-answer-checkbox-bg)
				color var(--color-decisions-answer-bg)
				flex center center
				>>> .ui-icon
					position absolute
					&.check-circle
						transform translate(2px, -2px)
	
	.buttons
		height 80px
		flex flex-end center
		margin 0 0 16px 0
		>>> .ui-button
			&.inactive
				opacity 0.25
			.button
				border-radius 4px
				background-color var(--color-decisions-answer-button-bg)
				font-size 2rem
				color var(--color-decisions-answer-button-text)
				font-weight 700
				text-transform uppercase
				font-family var(--josefin)
				padding-top 6px
				.loader.loading
					absolute 2px
					border-radius 4px
	
	.outcome
		position absolute
		top 0
		right 0
		bottom 0
		width 100%
		background-color var(--color-decisions-outcome-bg)
		.outcome-main
			position absolute
			left 0
			top 0
			right 0
			height auto !important
			width 100%
			padding 0 16px 0 32px
			background-color var(--color-decisions-outcome-bg)
			// &.algorithmic
			// 	height 100% !important
			// 	> div, .box
			// 		height 100% !important
			.users
				position absolute
				right 0
				bottom 0
				height 40px
				max-width 50%
				flex flex-end flex-end
				.user
					float left
					width 24px
					height 40px
					margin 0 0 0 4px
					background bottom center no-repeat
					background-size auto 24px
					color var(--color-decisions-character-empty)
					// background-image url('../../../assets/img/progress/user-empty.svg')
					&.c-full
						color var(--color-decisions-character-full)
						.user-empty, .user-crown
							display none
						.user-full
							display block
					&.b-crown
						color var(--color-decisions-character-tie)
						.user-empty, .user-full
							display none
						.user-crown
							display block
							transform scale(1.25)
							transform-origin bottom center
					.user-full, .user-crown
						display none
					.user-empty
						display block
			.box
				// width calc(100% - 16px)
				width 100%
				padding 16px 0
				&:before
					content ''
					absolute 0
					left -32px
					right -32px
					background-color var(--color-decisions-outcome-majority-choice-bg)
				&.losing
					padding-top 80px
					&:before
						top 0px
						// bottom -32px
						// background darken(blue_darker, 15%)
						// background linear-gradient(to bottom, blue_dark 0%, darken(blue_darker, 15%) 10%, darken(blue_darker, 15%) 100%)
						background linear-gradient(to bottom, var(--color-decisions-outcome-majority-choice-bg) 0%, var(--color-decisions-outcome-bg) 10%, var(--color-decisions-outcome-bg) 100%)
				.box-title
					margin 0 0 16px 0
					border-bottom 2px solid var(--color-decisions-title-color)
					font-size 2.4rem
					color var(--color-decisions-title-color)
					text-transform uppercase
					.users
						bottom 8px
				.box-content
					.units
						margin 32px 0 16px 0
						font-family var(--josefin)
						font-size 3.2rem
						text-transform uppercase
						.small
							font-size 1.6rem
					.option-answer
						border-bottom 2px solid var(--color-decisions-title-color)
						padding 24px 0
						&:last-child
							border none
					.text
						margin 0 0 16px 0
						&:last-child
							margin 0
						>>> .ui-text
							color var(--color-decisions-outcome-text)
							p:last-child
								margin-bottom 0
					.result-stats
						list-style disc
						margin-left 20px
						text-transform uppercase
						color var(--color-decisions-outcome-text)
						font-size 1.6rem
						.own
							color var(--color-decisions-title-color)
						.gold
							color var(--color-decisions-tie)
	
			.page-result
				// background-color red
				height 100%
				padding 32px 16px 88px 16px
				color var(--color-new-decisions-text)
				.page-result-title
					margin 0 0 24px 0
					border-bottom 2px solid var(--color-new-decisions-border)
					color var(--color-new-decisions-title)
					font-size 3.2rem
					text-transform uppercase
					text-align right
				.page-result-subtitle
					height 32px
					padding 0 8px
					margin-bottom 8px
					flex center center
					background-color var(--color-decisions-outcome-bg)
					font-size 1.8rem
					line-height 1em
					text-transform uppercase
					white-space nowrap
					color var(--color-new-decisions-border)
				.page-result-answer
					margin 0 0 48px 0
					padding 0 0 0 16px
					font-size 1.6rem
					text-align left
					&:before
						content ''
						position absolute
						left 0
						top 6px
						width 8px
						height 8px
						background-color var(--color-new-decisions-text)
						border-radius 50%
					&.page-result-answer-enter-active
						transition 0.25s easeOutQuart
					&.page-result-answer-enter
						transform translateY(-32px)
						opacity 0
				.page-result-outcome
					padding 24px
					border 2px solid var(--color-new-decisions-border)
					border-radius 16px
					font-size 1.6rem
					text-align left
					&.page-result-outcome-enter-active
						transition 0.25s easeOutQuart 0.15s
					&.page-result-outcome-enter
						transform translateY(-32px)
						opacity 0
					.label
						position absolute
						top -16px
						left 50%
						height 32px
						padding 0 8px
						flex center center
						transform translateX(-50%)
						background-color var(--color-decisions-outcome-bg)
						font-size 1.8rem
						line-height 1em
						text-transform uppercase
						white-space nowrap
						color var(--color-new-decisions-border)
						&:before
							content '· · ·'
							padding-right 12px
							font-size 20px
							transform translateY(-1px)
							color var(--color-new-decisions-border)
						&:after
							content '· · ·'
							padding-left 12px
							font-size 20px
							transform translateY(-1px)
							color var(--color-new-decisions-border)
					>>> .ui-text p
						margin-bottom 0
				.page-result-hourglass
					width 100%
					margin 64px 0 0 0
					flex center center column
					text-transform uppercase
					text-align center
					color var(--color-new-decisions-border)
					font-size 1.4rem
					&.page-result-hourglass-enter-active
						transition 0.25s easeOutQuart 0.25s
					&.page-result-hourglass-enter
						transform translateY(-32px)
						opacity 0
					> p
						font-size 2rem
				.page-result-all-answers
					.option-answer
						width 100%
						flex start start
						gap 16px
						margin 0 0 16px 0
						&.selected
							.option-answer-position
								background-color var(--color-new-decisions-option-selected-answer-position-bg)
								color var(--color-new-decisions-option-selected-answer-position-text)
							.option-answer-box
								background-color var(--color-new-decisions-option-selected-answer-bg)
								.option-answer-users li .user
									color var(--color-new-decisions-selected-character)
						&.option-answer-enter-active
							transition 0.25s easeOutQuart
							&:nth-child(2)
								transition-delay 0.05s
							&:nth-child(3)
								transition-delay 0.1s
							&:nth-child(4)
								transition-delay 0.15s
							&:nth-child(5)
								transition-delay 0.2s
							&:nth-child(6)
								transition-delay 0.25s
						&.option-answer-enter
							transform translateX(32px)
							opacity 0
						.option-answer-position
							width 40px
							height 40px
							flex center center
							flex-shrink 0
							font-size 2rem
							border-radius 50%
							background-color var(--color-new-decisions-option-answer-position-bg)
							color var(--color-new-decisions-option-answer-position-text)
							>>> sup
								font-size 1.2rem
						.option-answer-box
							width 100%
							border-radius 8px
							background-color var(--color-new-decisions-option-answer-bg)
							padding 8px
							.option-answer-text
								padding 12px
								border-radius 8px
								background-color var(--color-new-decisions-option-answer-text-bg)
								color var(--color-new-decisions-option-answer-text-text)
								>>> .ui-text p
									margin-bottom 0
							.option-answer-users
								// position absolute
								// right 0
								// bottom 0
								padding-top 4px
								height 40px
								width 100%
								flex flex-end center
								list-style none
								li
									width 24px
									height 40px
									.user
										width 24px
										height 40px
										margin 0 0 0 4px
										flex center center
										background center center no-repeat
										background-size auto 24px
										color var(--color-new-decisions-character)
										&.c-full
											.user-empty, .user-crown
												display none
											.user-full
												display block
										&.b-crown
											.user-empty, .user-full
												display none
											.user-crown
												display block
												transform scale(1.25)
												transform-origin bottom center
										.user-full, .user-crown
											display none
										.user-empty
											display block

		.toggle-outcome-button
			position fixed
			right 32px
			bottom (80px + 32px)
			height 48px
			background-color var(--color-new-decisions-toggle-button-bg)
			width 190px
			border-radius 8px
			cursor pointer
			box-shadow 0 0 0 3px var(--color-decisions-outcome-bg)
			color var(--color-new-decisions-toggle-button-text)
			font-size 1.8rem
			text-align center
			line-height @height
			text-transform uppercase
			&:hover
				transform translateY(-1px)
			&:active
				transform translateY(1px)
			&.toggle-outcome-button-enter-active
				transition 0.25s easeOutQuart 0.5s
			&.toggle-outcome-button-enter
				transform translateX(32px)
				opacity 0


	.waiting
		position absolute
		top 50%
		left 0
		right 0
		text-align center
		color var(--color-decisions-outcome-text)
		transform translateY(-50%)
		>>> h1
			margin 0 0 8px 0
			color var(--color-decisions-title-color)
	
	.paused-overlay
		absolute 0
		flex center center column
		font-family var(--josefin)
		font-size 2rem
		line-height 1.4em
		text-align center
		text-transform uppercase
		color var(--color-text)
		&:before
			content ''
			absolute 0
			background-color var(--color-bg)
			opacity 0.5


</style>
