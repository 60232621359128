<template>
	<div class="group-messaging">
		<template v-if="messages.length">
			<single-message v-for="message in messages" :message="message" type="group" :key="message.id" />
		</template>

		<div class="no-messages" v-else>No group messages found</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SingleMessage from '@/components/master/room/game-access/messaging/single-message'

export default {
	name: 'group-messaging',
	props: {
		player_filter: { default: null },
		view_filter: { default: null },
		channel_filter: { default: null },
	},
	computed: {
		...mapGetters([ 'chirp' ]),
		messages() {
			let messages = this.chirp.messages.filter(m => m.channel)

			if (this.player_filter && this.player_filter.id) {
				messages = messages.filter((m) => {
					return (m.sender && parseInt(m.sender.id) === parseInt(this.player_filter.id))
				})
			}
			if (this.view_filter === 'student') {
				messages = messages.filter((m) => m.channel && m.sender_player && m.sender_player.student_number && m.sender_player.student_number !== "N/A")
			}
			if (this.channel_filter) {
				messages = messages.filter((m) => m.channel === this.channel_filter)
			}

			return messages
		}
	},
	components: { SingleMessage }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.group-messaging
	height 100%
	padding-top 8px
	.no-messages
		width 100%
		margin 80px 0 0 0
		text-align center
		font-family var(--josefin)
		font-size 2rem
		text-transform uppercase
		color blue_lighter
		opacity 0.25

</style>
