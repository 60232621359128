<template>
	<div class="end-game">

		<div v-if="!loading" class="page">
			<div class="scroll" v-scrollbar>
				<div>
					<div class="page-content">
						<main-title icon="menu/menu-files" :text="'The outcome <span class=\'separator\'>|</span> ' + logged_user.role.name" :closable="false" />
						
						<!-- <div class="warning">
							<ui-icon name="alert-triangle" :size="64" />
							<p>There is an assessment survey for you to fill as the last category of this page. Your input helps us make a better simulation. Thank you for playing iThrive Sim!</p>
						</div> -->

						<div v-if="!isDisasterMind && !isJuryDuty" class="notation">
							<div class="label">How likely would you be to recommend this experience to a friend or a peer?</div>
							<div class="stars" @mouseleave="unstar()">
								<div v-for="i in 10" :key="i" class="star" :class="{ active: i <= star_active, over: i <= star_hover }" @mouseenter="star_hover = i" v-hammer:tap="() => set_note(i)">
									{{ i }}
								</div>
							</div>
							<div class="hints">
								<span>Not at all likely</span>
								<span>Extremely likely</span>
							</div>
						</div>

						<div class="endings">
							<div v-for="ending in endings" :key="ending.id" class="ending">
								<div v-if="ending.description" class="video-text">
									<ui-text :text="ending.description" />
								</div>
								<div v-if="video_src(ending)" class="video-player">
									<iframe v-if="is_video_iframe(ending)" ref="iframe" :src="video_src(ending)" width="100%" height="300px" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
									<ui-video v-else :src="video_src(ending)" :light="false" :autoplay="false" />
								</div>
							</div>
						</div>

						<div class="decisions">
							<p class="title">Significant decisions</p>
							<p class="sub-title">{{ questions.length }} decisions were taken. These led to the outcome of this game.</p>
							<div v-for="question in visible_questions" :key="question.id" class="decision">
								<div class="index">{{ question.index + 1 }}</div>
								<div class="decision-question">{{ question.question }}</div>
								<div class="decision-content">
									<div v-for="answer in question.answer" :key="answer.id" class="answer" :class="{ selected: progress.selected_answers.includes(parseInt(answer.id)) }">
										<span class="text">{{ answer.answer }}</span>
										<span class="your-choice">Your choice</span>
									</div>
								</div>
							</div>
							<ui-button class="show-all-questions-button" v-hammer:tap="showAllQuestionsButton">
								<template v-if="all_questions_visibles">Show significant decisions only</template>
								<template v-else>Show all decisions</template>
							</ui-button>
						</div>

						<div v-if="google_form_url" class="google-form">
							<p class="title">Answer our survey</p>
							<div class="google-form-content" v-html="google_form_url"></div>
						</div>

						<div v-if="game.room.clock_option !== 'hidden'" class="meter-box">
							<div class="label">{{ game.scenario.meter_label }}</div>
							<div class="meter">
								<div v-if="game.scenario && game.scenario.meter_status" class="public-satisfaction">
									<div class="value">
										<div class="bar">
											<div v-if="!is_co2_scenario" class="progress" :style="'width:' + public_satisfaction + '%'"></div>
											<div v-else class="progress" :class="{ green: public_satisfaction >= 80, red: public_satisfaction < 80 }" :style="'width:' + public_satisfaction + '%'"></div>
											<div class="ticks">
												<div class="tick t25"></div>
												<div class="tick t50"></div>
												<div class="tick t75"></div>
											</div>
										</div>
										<div v-if="game.room && game.room.meter_label" class="percent">{{ game.room.meter_label.replace('None', '') }}</div>
										<div v-else class="percent"></div>
									</div>
								</div>
							</div>
						</div>

						<div v-if="isDisasterMind" class="notation">
							<div class="label">How likely would you be to recommend this experience to a friend or a peer?</div>
							<div class="stars" @mouseleave="unstar()">
								<div v-for="i in 10" :key="i" class="star" :class="{ active: i <= star_active, over: i <= star_hover }" @mouseenter="star_hover = i" v-hammer:tap="() => set_note(i)">
									{{ i }}
								</div>
							</div>
							<div class="hints">
								<span>Not at all likely</span>
								<span>Extremely likely</span>
							</div>
						</div>

						<!-- <div class="boxes">
							<div v-if="most_liked_chirp" class="box">
								<div class="box-title">Most liked chirp</div>
								<div class="box-content">
									<chirp-item :chirp_item="most_liked_chirp" :allow_interactions="false" />
								</div>
							</div>
							<div class="box">
								<div class="box-title">Meter title</div>
								<div class="box-content">
									
								</div>
							</div>
							<div v-if="pulitzer_story" class="box">
								<div class="box-title">Pulitzer prize winner</div>
								<div class="box-content pulitzer">
									<div class="head">
										<p>{{ pulitzer_story.sender_player ? pulitzer_story.sender_player.name : "-" }}</p>
										<p>{{ pulitzer_story.sender_player && pulitzer_story.sender_player.role ? pulitzer_story.sender_player.role.name : "-" }}</p>
										<p>{{ pulitzer_story.sender_player && pulitzer_story.sender_player.student_name ? '(' + pulitzer_story.sender_player.student_name + ')' : "-"}}</p>
										<ui-icon v-if="pulitzer_newspaper" class="news-icon" :name="'news/' + pulitzer_newspaper" :size="88" />
									</div>
									<div class="content">
										<p>For the following article:</p>
										<p class="title">{{ pulitzer_story.title }}</p>
									</div>
									<div class="footer">
										<div class="likes">
											<ui-icon name="chirp/like-on" :size="24" />
											{{ pulitzer_story.like }}
										</div>
										<div class="button">Read the article</div>
									</div>
								</div>
							</div>

							<div v-if="most_influential_newspaper" class="box">
								<div class="box-title">Most influential news outlet</div>
								<div class="box-content newspaper">
									<ui-icon class="news-icon" :name="'news/' + most_influential_newspaper.slug" :size="220" />
									<div class="likes">
										<ui-icon name="chirp/like-on" :size="24" />
										{{ most_influential_newspaper.like }}
									</div>
								</div>
							</div>

						</div> -->

					</div>
				</div>
			</div>
		</div>

		<div v-if="loading" class="loading">
			<ui-icon name="button-loader" :size="64" />
		</div>

		<transition name="modal-back" appear>
			<div v-if="backModalVisible" class="modal-back">
				<p>Thanks for your participation.</p>
				<ui-button v-hammer:tap="() => quit()">Back to home</ui-button>
			</div>
		</transition>
		
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainTitle from '@/components/game/main-title'
import UiText from '@/components/ui/ui-text'
import UiButton from '@/components/ui/ui-button'
import ChirpItem from '@/components/game/chirp/elements/chirp-item'
import Apollo from '@/apollo/apollo-client'

export default {
	name: 'end-game',
	data() {
		return {
			loading: true,
			endings: [],
			star_active: 0,
			star_hover: 0,
			all_questions_visibles: false,
			backModalVisible: false,
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'logged_user', 'chirp', 'progress', 'questions' ]),
		visible_questions() {
			if (this.all_questions_visibles) return this.questions.map((q, index) => ({ ...q, index }))
			// return this.questions.filter(q => q && q.significant)
			return this.questions
				.filter(q => q /*&& q.significant*/) // Changer ici par le champ significant
				.map(q => ({ ...q, index: this.questions.findIndex(q2 => q2.id === q.id) || 0 }))
		},
		google_form_url() {
			if (this.endings) {
				return ( this.endings.find(e => e.google_form_url) || { google_form_url: null } ).google_form_url
			}
			return null
		},
		is_co2_scenario() {
			return this.game.scenario && this.game.scenario.enable_stats
		},
		most_liked_chirp() {
			let chirp = null
			this.chirp.feed.forEach((c) => {
				if (!chirp) chirp = c
				if (c.like > chirp.like) chirp = c
			})
			return chirp
		},
		pulitzer_story() {
			let story = null
			this.chirp.stories.forEach((s) => {
				if (!story) story = s
				if (s.like > story.like) story = s
			})
			return story
		},
		pulitzer_newspaper() {
			if (this.pulitzer_story)
				return ( this.chirp.news_papers.find((n) => this.pulitzer_story.tags.includes(n.slug)) || { slug: null }).slug
			return null
		},
		most_influential_newspaper() {
			let newspapers = {}

			this.chirp.news_papers.forEach((n) => newspapers[n.slug] = 0)
			this.chirp.stories.forEach((s) => {
				let n = ( this.chirp.news_papers.find((n) => this.pulitzer_story.tags.includes(n.slug)) || { slug: null }).slug
				if (n) newspapers[n] += s.like
			})

			let newspaper = null
			Object.keys(newspapers).forEach((slug) => {
				let like = newspapers[slug]
				if (!newspaper) newspaper = { slug, like }
				if (like > newspaper.like) newspaper = { slug, like }
			})
			return newspaper
		},
		public_satisfaction() {
			if (this.game.room && this.game.room.public_opinion) {
				return this.game.room.public_opinion
			}
			return 0
		},
		isDisasterMind() {
			return parseInt(this.game?.scenario?.id) === 21
		},
		isJuryDuty() {
			return parseInt(this.game?.scenario?.id) === 24
		}
	},
	methods: {
		showAllQuestionsButton() {
			this.all_questions_visibles = !this.all_questions_visibles
		},
		is_video_iframe(ending) {
			return (ending.video_url && ending.video_url.toLowerCase().indexOf('vimeo') > -1)
		},
		video_src(ending) {
			if (ending && ending.video_url) {
				if (this.is_video_iframe) {
					const reg = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
					const match = ending.video_url.match(reg)
					if (match && match.length > 3) {
						return 'https://player.vimeo.com/video/' + match[3] + '?autoplay=1&title=0&byline=0&portrait=0'
					}
				} else {
					const reg = /https?:\/\/drive\.google\.com\/[a-zA-Z=\/?&]+=?([a-z0-9A-Z-]+)/
					const match = ending.video_url.match(reg)
					if (match && match.length > 1) {
						return 'https://drive.google.com/uc?export=download&id=' + match[1]
					}
				}
			}
			return false
		},
		unstar() {
			this.star_hover = 0
		},
		set_note(score) {
			if (this.logged_user && this.logged_user.id) {
				this.star_active = score
				Apollo.mutate('SET_PROMOTOR_SCORE', { player_id: this.logged_user.id, score })

				if (this.isDisasterMind) this.backModalVisible = true
			}
		},
		quit() {
			if (this.isDisasterMind) {
				this.$store.dispatch('logout')
				this.$router.push({ path: '/disastermind' })
				location.reload()
			}
		}
	},
	async created() {
		this.loading = true
		this.endings = await Apollo.query('GET_ENDING', { room_id: this.game.room.id })
		this.loading = false
	},
	components: { MainTitle, UiText, UiButton, ChirpItem }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#app.ipad-theme
	.end-game .page .page-content
		padding 0 16px 0 80px
		.notation
			padding-left 16px
			padding-right 16px
		.decisions .decision .index
			left auto
			right calc(100% + 8px)
			text-align right
			width auto
		
.end-game
	display block
	height 100%
	width 100%
	.page
		height 100%
		.page-content
			max-width 900px
			width 100%
			margin 0 auto
			padding 32px 0
			>>> .main-title
				.separator
					margin 0 16px
				.ui-icon
					position absolute
					left -64px
					top 0
			
			.notation
				padding 32px 0
				width 100%
				margin 32px 0
				text-align center
				background-color var(--color-summary-row-bg)
				border-radius 4px
				.label
					margin 0 0 16px 0
					font-size 2rem
					line-height 1.4em
				.stars
					height 40px
					flex center center
					display inline-flex
					.star
						height 40px
						width 64px
						cursor pointer
						background-color var(--color-summary-note-bg)
						flex center center
						border-right 2px solid var(--color-summary-row-bg)
						font-size 2rem
						font-weight 400
						padding-top 3px
						color var(--color-summary-note-text)
						font-family var(--josefin)
						// border-right none
						&:first-child
							border-top-left-radius 8px
							border-bottom-left-radius 8px
						&:last-child
							border-top-right-radius 8px
							border-bottom-right-radius 8px
						&.active
							background-color var(--color-summary-note-bg-active)
							color var(--color-summary-note-text-active)
							font-weight 700
						&.over
							background-color var(--color-summary-note-bg-hover)
							color var(--color-summary-note-text-hover)
				.hints
					padding-top 4px
					width 640px
					margin 0 auto
					opacity 0.5
					flex space-between center
			.warning
				flex flex-note-bgt center
				padding 8px 16px
				margin 0 0 32px 0
				background-color var(--color-summary-warning-bg)
				color var(--color-summary-warning-text)
				border-radius 8px
				// border-bottom 1px solid blue_light
				font-size 1.6rem
				line-height 1.4em
				>>> .ui-icon
					margin 0 8px 0 0
			.endings .ending
				margin 0 0 32px 0
				&:last-child
					margin 0
			.video-text
				padding 0 0 32px 0
				margin 0 0 32px 0
				border-bottom 2px solid var(--color-summary-text)
				color var(--color-summary-text)
				font-size 1.6rem
				line-height 1.5em
			.video-player
				padding 0 0 32px 0
				margin 0 0 32px 0
				border-bottom 2px solid var(--color-summary-text)
				color var(--color-summary-text)
				font-size 1.6rem
				line-height 1.5em
			.google-form
				background-color #003E57
				padding 32px
				text-align center
				border-radius 4px
				margin 0 0 32px 0
				.title
					font-family var(--josefin)
					font-size 3.2rem
					margin 0 0 16px 0
				.google-form-content
					text-align center
			.meter-box
				padding 32px 0
				width 100%
				margin 32px 0
				text-align center
				background-color var(--color-summary-row-bg)
				border-radius 4px
				.label
					margin 0 0 8px 0
					font-size 2rem
					line-height 1.4em
					font-family var(--josefin)
					text-transform uppercase
				.meter
					width 100%
					height 100%
					padding 0 40px
					flex-shrink 0
					flex flex-start center
					// display flex
					color var(--color-bottom-bar-text)
					line-height 20px
					font-size 1.3rem
					line-height 1.2em
					text-transform uppercase
					font-family var(--josefin)
					.public-satisfaction
						width 100%
						.value
							flex center flex-start
							height 24px
							margin 8px 0 0 0
							line-height @height
							white-space nowrap
							.bar
								height 100%
								width calc(100% - 40px)
								border-radius 40px
								background-color var(--color-bottom-bar-meter-bg)
								overflow hidden
								.progress
									position absolute
									left 0
									top 0
									bottom 0
									border-top-left-radius 40px
									border-bottom-left-radius 40px
									background linear-gradient(to left, var(--color-bottom-bar-meter-progress-1), var(--color-bottom-bar-meter-progress-2))
									&.green
										background #8DBDBB
									&.red
										background #EC9085
								.ticks
									position absolute
									left 0
									bottom 0
									right 0
									height 6px
									.tick
										position absolute
										bottom 0
										width 2px
										height 100%
										margin-left -1px
										background-color var(--color-bottom-bar-bg)
										&.t25
											left 25%
										&.t50
											left 50%
										&.t75
											left 75%
							.percent
								width 40px
								padding-left 12px
								text-align left
								font-size 1.6rem
								white-space nowrap
			.boxes
				width 100%
				display grid
				grid-template-columns 1fr 1fr
				// grid-template-rows 1fr 1fr
				column-gap 16px
				row-gap 16px
				padding 0 0 32px 0
				margin 0 0 32px 0
				border-bottom 2px solid var(--color-summary-row-text)
				.box
					width 100%
					height 100%
					.box-title
						height 32px
						line-height (@height + 6px)
						font-family var(--josefin)
						font-size 2rem
						color var(--color-summary-row-text)
						text-transform uppercase
					.box-content
						height calc(100% - 48px)
						padding 8px
						border-radius 4px
						background-color var(--color-summary-row-bg)
						>>> .chirp-item .part-left .avatar .img .online
							box-shadow 0 0 0 4px @background-color
						&.pulitzer
							padding 16px
							.head
								display block
								border-bottom 1px solid var(--color-summary-row-text)
								padding-bottom 12px
								margin-bottom 12px
								line-height 20px
								color var(--color-summary-row-text)
								>>> .news-icon
									position absolute
									right 0
									top -16px
							.content
								display block
								color var(--color-summary-row-text)
								margin 0 0 8px 0
								.title
									margin 4px 0 0 0
									font-size 2.4rem
									line-height 1.2em
									text-transform uppercase
							.footer
								height 48px
								flex space-between center
								margin 0 0 16px 0
								.likes
									height 100%
									flex flex-note-bgt center
									>>> .ui-icon
										margin 0 8px 0 0
								.button
									height 100%
									flex center center
									padding 0 24px
									border-radius 4px
									background-color var(--color-summary-row-bg)
									text-align center
									text-transform uppercase
									cursor pointer
									&:hover
										transform translateY(-2px)
									&:active
										transform translateY(2px)
						&.newspaper
							padding 16px
							text-align center
							flex center center
							flex-direction column
							>>> .news-icon
								margin 0 auto
								transform scale(1.25)
							.likes
								width 100%
								flex center center
								>>> .ui-icon
									margin 0 8px 0 0
			.decisions
				> .title
					height 32px
					margin 0 0 16px 0
					line-height 1.5em
					// font-family var(--josefin)
					font-size 3.2rem
					color var(--color-summary-row-text)
					// text-transform uppercase
					font-weight 700
				> .sub-title
					color var(--color-summary-row-text)
					margin 0 0 24px 0
					font-size 1.6rem
					line-height 1.2em
				.decision
					background-color var(--color-summary-row-bg)
					color var(--color-summary-row-text)
					padding 16px
					border-radius 8px
					margin 0 0 24px 32px
					transition 0.25s easeOutQuart
					&.decision-leave-active
						position absolute
					&.decision-enter-from, &.decision-leave-to
						transform translateX(-16px)
						opacity 0
					.index
						position absolute
						top 8px
						left -32px
						width 32px
						text-align left
						font-size 4rem
						line-height 1em
						
						left auto
						right calc(100% + 8px)
						text-align right
						width auto
						// color var(--color-summary-row-text)
					.decision-question
						padding 0 0 4px 0
						margin 0 0 12px 0
						flex flex-note-bgt center
						border-bottom 1px solid var(--color-summary-row-text)
						font-family var(--josefin)
						font-size 2rem
						text-transform uppercase
						font-weight 400
						line-height 1.4em
						// color var(--color-summary-row-text)
					.decision-content
						padding 0 0 0 16px
						.answer
							margin 0 0 12px 0
							font-size 1.6rem
							line-height 20px
							opacity 0.5
							color var(--color-summary-row-answer-text)
							&:last-child
								margin 0
							&.selected
								opacity 1
								.your-choice
									display inline-block
							.your-choice
								display none
								height 20px
								padding 0 6px
								margin 0 0 0 8px
								background-color var(--color-summary-row-selected-answer-bg)
								border-radius 0 4px 4px 0
								text-transform uppercase
								font-family var(--josefin)
								font-size 1.2rem
								font-weight 700
								line-height 24px
								white-space nowrap
								color var(--color-summary-row-selected-answer-text)
							.text
								display inline
				.show-all-questions-button
					display block
					width 100%
			
	.loading
		absolute 0
		background-color var(--color-summary-bg)
		flex center center
		color var(--color-summary-text)

	.modal-back
		absolute 0
		background-color var(--color-summary-bg)
		flex center center column
		gap 32px
		color var(--color-summary-text)
		font-size 2.4rem
		transform scale(1)
		transition 0.25s easeOutQuart
		&.modal-back-enter
			opacity 0
			transform scale(1.25)

</style>
