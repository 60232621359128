<template>
	<div class="start-game">
		<p class="title">Go to the next episode?</p>

		<p class="text">Current episode: {{ game.room.episode.episode_number + 1 }}</p>

		<div v-if="game.room.episode.episode_number + 1 < game.scenario.episodes.length">
			<!-- <p class="text">Lorem ipsum dolor, sit amet consectetur adipisicing, elit. Facilis quaerat similique possimus, quibusdam reiciendis beatae!</p> -->
			<div class="buttons">
				<ui-button v-hammer:tap="() => no()" class="no"><span>No</span></ui-button>
				<ui-button v-hammer:tap="() => yes()" class="yes" :loading="loading"><span>Yes</span></ui-button>
			</div>
		</div>
		<div v-else>
			<p class="text">All episodes have already been played.</p>
			<div class="buttons">
				<ui-button v-hammer:tap="() => no()" class="no"><span>Close</span></ui-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiButton from '@/components/ui/ui-button'

export default {
	name: 'start-game',
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters([ 'game' ])
	},
	methods: {
		no() {
			this.$router.go(-1)
		},
		async yes() {
			this.loading = true

			await this.$store.dispatch('goto_next_episode', { room_id: this.game.room.id })
			
			this.loading = false
			this.$router.go(-1)
		}
	},
	components: { UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.start-game
	.title
		margin 0 0 24px 0
		padding 0 0 24px 0
		font-size 1.8rem
		text-transform uppercase
		color blue_lighter
		border-bottom 2px solid blue_lighter
	.text
		margin 0 0 24px 0
		color gray_light
		font-size 1.6rem
		line-height 22px
	.question
		margin 0 0 24px 0
		color blue_lighter
		font-size 1.6rem
		text-transform uppercase
		line-height 22px
	.buttons
		margin 24px 0 0 0
		>>> .ui-button
			&.no
				.button
					background-color #B93636
			.button
				margin 0 8px
				border-radius 8px
				background-color #3FB143
				text-transform uppercase
				font-size 1.8rem
				font-family var(--josefin)
				.loader
					border-radius 8px
					&.loading
						background-color #3FB143
				> span
					transform translateY(2px)

	
</style>
