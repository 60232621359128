<template>
	<div v-if="scenario" class="scenario">
		<div class="name">{{ scenario.name }}<span v-if="scenario.subject"> - {{ scenario.subject }}</span></div>
		<div class="content">
			<div class="description">{{ scenario.description }}</div>
			<div class="grid">
				<ui-icon class="main-icon" name="login-game-master" :size="150" />
				<div class="box amount-of-players">
					<div class="box-title">Amount of players</div>
					<div class="box-content">
						<div class="value">{{ scenario.amount_of_player ? scenario.amount_of_player : '' }}</div>
					</div>
				</div>
				<div class="box recommended-grade">
					<div class="box-title">Recommended grade</div>
					<div class="box-content">
						<div class="value">{{ scenario.recommended_for ? scenario.recommended_for : '' }}</div>
					</div>
				</div>
				<div class="box episode-count">
					<div class="box-title">Episode count</div>
					<div class="box-content">
						<div class="value">{{ scenario.episodes.length ? scenario.episodes.length : '' }}</div>
					</div>
				</div>
				<div class="box episode-length">
					<div class="box-title">Episode length</div>
					<div class="box-content">
						<div class="value">{{ scenario.avg_episode_length ? scenario.avg_episode_length : '' }}</div>
					</div>
				</div>
				<div class="box remote-online-play" :class="{ disabled: !scenario.adapted_online }">
					<div class="box-content"><ui-icon name="profile/profile-video" :size="40" />Adapted for remote online play</div>
				</div>
				<div class="box classroom-play" :class="{ disabled: !scenario.adapted_remote }">
					<div class="box-content"><ui-icon name="profile/profile-video" :size="40" />Adapted for the classroom</div>
				</div>
			</div>
			<div v-if="scenario.scenario_overview" class="overview" :class="{ visible: overview_visible }">
				<ui-text :text="scenario.scenario_overview" />
			</div>
			<div v-if="scenario.scenario_overview && !overview_visible" class="buttons">
				<div class="button" v-hammer:tap="() => overview_visible = true">Learn more</div>
			</div>
		</div>
	</div>
</template>

<script>
import UiText from '@/components/ui/ui-text'

export default {
	name: 'scenario',
	data() {
		return {
			overview_visible: false
		}
	},
	props: {
		scenario: { default: null }
	},
	created() {
		if (this.scenario && this.scenario.scenario_overview.length < 250) this.overview_visible = true
	},
	components: { UiText }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.scenario
	background-color blue
	padding 16px
	border-radius 6px
	.name
		padding 0 0 12px 0
		margin 0 0 16px 0
		border-bottom 1px solid blue_lighter
		font-family var(--josefin)
		font-size 20px
		color blue_lighter
		line-height 1.2em
		text-transform uppercase
	.content
		.grid
			display grid
			grid-template-columns 1fr 1fr 1fr 1fr
			grid-template-rows 1fr 1fr 1fr
			gap 12px
			// margin 0 0 16px 0
			.main-icon
				grid-column 1 / span 1
				grid-row 1 / span 3
			.box
				background-color #024864
				border-radius 4px
				text-align center
				padding 16px
				color blue_lighter
				&.disabled
					background-color alpha(#024864, 40%)
					.box-title, .box-content
						opacity 0.2
				&.amount-of-players
					grid-column 1 / span 1
					grid-row 1 / span 3
				&.recommended-grade
					grid-column 2 / span 1
					grid-row 1 / span 3
				&.episode-count
					grid-column 3 / span 1
					grid-row 1 / span 3
				&.episode-length
					grid-column 4 / span 1
					grid-row 1 / span 3
				&.remote-online-play
					grid-column 1 / span 2
					grid-row 4 / span 1
					padding 8px
				&.classroom-play
					grid-column 3 / span 2
					grid-row 4 / span 1
					padding 8px
				.box-title
					padding 0 0 4px 0
					margin 0 0 8px 0
					border-bottom 1px solid blue_lighter
					text-transform uppercase
					font-size 1.1rem
					line-height 1.2em
					font-family var(--josefin)
					color blue_lighter
				.box-content
					min-height calc(100% - 32px)
					flex center center
					font-size 1.6rem
					.value
						height 100%
						font-size 2.4rem
						&.big
							font-size 4rem
					>>> .ui-icon
						margin 0 8px 0 0
					
		.description
			margin 0 0 16px 0
			color blue_lighter
			font-size 1.6rem
			line-height 1.4em
		.overview
			margin 16px 0 0 0
			color blue_lighter
			font-size 1.6rem
			line-height 1.4em
			max-height 80px
			overflow hidden
			&.visible
				max-height none
				&:after, .more
					display none
			&:after
				content ''
				position absolute
				right 0
				left 0
				bottom 0
				height 32px
				background linear-gradient(to top, blue 50%, alpha(blue, 0))
				pointer-events none
			.more
				absolute 0
				background-color alpha(blue, 50%)
				flex center center
				padding-bottom 24px
				text-transform uppercase
				color #fff
				.button
					display inline-block
					height 48px
					padding 0 24px
					border-radius 4px
					background-color blue_lighter
					cursor pointer
					font-size 1.6rem
					font-family var(--josefin)
					color blue_dark
					font-weight 700
					text-transform uppercase
					text-align center
					line-height (@height + 2px)
					&:hover
						background-color blue_lighter
					&:active
						background-color blue_lighter
						transform translateY(2px)
		.buttons
			.button
				height 64px
				border-radius 4px
				background-color #033144
				cursor pointer
				font-size 1.6rem
				font-family var(--josefin)
				color #fff
				text-transform uppercase
				text-align center
				line-height (@height + 2px)
				&:hover
					background-color blue_dark
				&:active
					background-color blue_darker
					transform translateY(2px)

	
</style>
