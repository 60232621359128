<template>
	<div class="chirp-conversation">

		<div v-if="chirp.route.params.player" class="recipient">
			<div class="recipent-avatar"><div class="img" v-bg="{ name: chirp.route.params.player.avatar, type: 'avatar' }"></div></div>
			<div class="recipent-content">
				<p v-if="chirp.route.params.player.role && chirp.route.params.player.role.name" class="role">{{ chirp.route.params.player.role.name }}</p>
				<p v-if="chirp.route.params.player.name" class="name">
					{{ chirp.route.params.player.name }}
					<span v-if="chirp.route.params.player.student_name">({{ chirp.route.params.player.student_name }})</span>
				</p>
			</div>
		</div>

		<div v-if="chirp.route.params.channel" class="recipient">
			<div class="recipent-avatar"><div class="img">{{ avatar.initials }}</div></div>
			<div class="recipent-content">
				<p class="role">{{ chirp.route.params.channel }}</p>
			</div>
		</div>

		<div class="conversation" ref="conversation" v-scrollbar :style="{ height: conversation_height }">
			<div>
				<div v-if="messages.length" class="messages">
					<chirp-message v-for="message in messages" :key="message.id" :message="message" />
				</div>
				<div v-else class="no-messages">
					<ui-icon name="message-square" :size="64" />
					<div>No messages</div>
				</div>
			</div>
		</div>
		<div class="form" ref="form" :class="{ 'has-media': chirp.current.selected_media.length > 0, loading }">
			<div v-if="chirp.route.params.player" class="image-button" v-hammer:tap="() => show_media_selection()"><ui-icon name="image" :size="32" /></div>
			<div class="input" :class="{ 'full-width': !chirp.route.params.player }">
				<textarea placeholder="Your message here..." v-model="text" @keyup="update_height" @keydown.enter.prevent="send" rows="1" ref="textarea" class="textarea" />
				<div class="submit-button" v-hammer:tap="() => send()"><ui-icon name="send" :size="20" /></div>
			</div>
			<div v-if="loading" class="loading-overlay"><ui-icon name="button-loader" :size="48" /></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChirpMessage from '@/components/game/chirp/elements/chirp-message'
import Feature from 'feature.js'
import { get_initials } from '@/js/utils/utils'

export default {
	name: 'chirp-conversation',
	data() {
		return {
			text: '',
			loading: false,
			conversation_height: 'calc(100% - 80px - 64px)'
		}
	},
	computed: {
		...mapGetters([ 'chirp', 'logged_user' ]),
		avatar() {
			if (this.chirp.route.params.channel) {
				return { initials: get_initials(this.chirp.route.params.channel) }
			}
			return this.chirp.route.params.player.avatar
		},
		messages() {
			let messages = []

			let list_messages = this.chirp.messages

			let last_user_id = null
			list_messages.forEach((m) => {
				if (this.chirp.route.params.channel && (m.channel === this.chirp.route.params.channel) ||
					(this.chirp.route.params.player && m.recipient && (m.recipient.id === this.chirp.route.params.player.id || m.sender.id === this.chirp.route.params.player.id))
				) {
					let last_of_user = (last_user_id !== m.sender.id)
					messages.push({ ...m, last_of_user })
					last_user_id = m.sender.id
				}
			})
			// messages[messages.length - 1].last_of_user = true
			return messages.reverse()
		}
	},
	watch: {
		// 'chirp.current.selected_media.length'(newValue, oldValue) {
		// 	setTimeout(() => {
		// 		this.update_height()
		// 	}, 100)
		// },
		text() {
			this.update_height()
		},
		messages() {
			this.$nextTick(() => {
				this.scroll_to_bottom()
			})
		}
	},
	methods: {
		remove_media(media) {
			let medias = this.chirp.current.selected_media.filter((m) => { return m.id != media.id })
			this.$store.dispatch('set_chirp_active', { selected_media: medias })
		},
		show_media_selection() {
			this.$store.dispatch('set_chirp_current', { media_selection_visible: true, selected_media: [] })
		},
		scroll_to_bottom() {
			let el = this.$refs.conversation
			if (!Feature.touch) el = el.firstChild
			el.scrollTop = el.scrollHeight
		},
		update_height() {
			let textarea = this.$refs.textarea
			setTimeout(() => {
				textarea.style.cssText = 'height:auto;'
				textarea.style.cssText = 'height:' + textarea.scrollHeight + 'px'
				// if (textarea) {
				// 	this.conversation_height = 'calc(100% - ' + (textarea.scrollHeight + 48) + 'px)'
				// } else
				// 	this.conversation_height = 'calc(100% - 80px - 64px)'

				this.$nextTick(() => {
					this.scroll_to_bottom()
				})
			}, 10)

		},
		async send() {
			if (this.text && !this.loading) {
				this.loading = true
				let values = {
					title: '',
					channel: this.chirp.route.params.channel ? this.chirp.route.params.channel : '',
					message: this.text,
					tags: [],
					message_type: 'Chirp',
					medias: this.chirp.current.selected_media.map((m) => { return m.url })
				}
				if (this.chirp.route.params.player) values.recipient = this.chirp.route.params.player
				let message_id = await this.$store.dispatch('send_message', values)
				if (message_id && message_id > 0) {
					this.title = ''
					this.text = ''
					// this.$refs.textarea_content.innerHTML = ''
					// this.$store.dispatch('set_chirp_active', { selected_media: [] })
					this.$store.dispatch('add_notification', {
						status: 'success',
						message: "Sent!"
					})
				}
				this.loading = false
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.update_height()
		})

		// this.$el.addEventListener('keyup', (e) => {
		// 	if (e.keyCode == 13 && !e.shiftKey) {
		// 		this.$nextTick(() => {
		// 			this.send()
		// 		})
		// 	}
		// })
	},
	beforeDestroy() {
		if (this.chirp.current.selected_media.length) {
			this.$store.dispatch('set_chirp_active', { selected_media: [] })
		}
	},
	components: { ChirpMessage }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'

.chirp-conversation
	height 100%
	background-color var(--color-chir-bg)
	// padding 0 32px
	.recipient
		flex flex-start center
		padding 12px 16px
		height 64px
		line-height 20px
		white-space nowrap
		background-color var(--color-chirp-conversation-recipient-bg)
		color var(--color-chirp-conversation-recipient-text)
		.recipent-avatar
			width 48px
			height 48px
			margin 0 12px 0 0
			background-color var(--color-chirp-user-avatar-bg)
			border-radius 50%
			.img
				absolute 0
				background-size cover
				border-radius 50%
				text-align center
				flex center center
				font-weight 900
				font-size 1.6rem
		.recipent-content
			.role
				text-transform uppercase
			.name
				opacity 0.5
	.conversation
		height calc(100% - 80px - 64px)
		.messages
			padding 8px 16px 8px 8px
	.no-messages
		height 100%
		flex center center
		flex-direction column
		opacity 0.5
		text-transform uppercase
		font-weight 700
		font-size 1.8rem
		min-height 300px
		color var(--color-chirp-conversation-no-messages-text)
		> .ui-icon
			margin-bottom 8px
			&:after
				content ''
				position absolute
				left -50%
				top 0
				background-color var(--color-chirp-conversation-no-messages-text)
				height 5px
				width 150%
				box-shadow 0 -4px 0 0 var(--color-chirp-bg), 0 4px 0 0 var(--color-chirp-bg)
				transform rotate(45deg) translate(30%, 200%)
	.form
		position absolute
		bottom 0
		left 0
		right 0
		min-height 64px
		padding 16px
		padding-bottom 32px
		background-color var(--color-chirp-conversation-form-bg)
		flex flex-start center
		&.loading
			.loading-overlay
				absolute 16px
				bottom 32px
				background var(--color-chirp-conversation-form-bg)
				flex center center
				opacity 0.9
				color var(--color-chirp-conversation-form-loading-overlay-text)
		&:before
			content ''
			position absolute
			left 16px
			right 16px
			top 0
			height 1px
			background-color var(--color-chirp-conversation-form-border)
		.image-button
			width 32px
			height 32px
			margin 0 8px 0 0
			cursor pointer
			color var(--color-chirp-conversation-form-attachment-button)
		.input
			flex center center
			min-height 32px
			width calc(100% - 40px)
			background-color var(--color-chirp-conversation-form-input-bg)
			border-radius 24px
			color var(--color-chirp-conversation-form-input-text)
			&.full-width
				width 100%
			.textarea
				width 100%
				padding 10px 48px 8px 16px
				min-height 32px
				border none
				font inherit
				color inherit
				resize none
				line-height 16px
				overflow auto
				font-family var(--josefin)
				font-size 16px
				background none
				outline none
				overflow hidden
				&::-webkit-input-placeholder
					color var(--color-chirp-conversation-form-input-placeholder)
					opacity 1
				&::-moz-placeholder
					color var(--color-chirp-conversation-form-input-placeholder)
					opacity 1
				&:-ms-input-placeholder
					color var(--color-chirp-conversation-form-input-placeholder)
					opacity 1
				&:-moz-placeholder
					color var(--color-chirp-conversation-form-input-placeholder)
					opacity 1
				
			.submit-button
				position absolute
				right 0
				bottom 0
				width 32px
				height 32px
				flex flex-start center
				cursor pointer
		.medias
			height (96px + 16px)
			padding 8px
			flex flex-start center
			overflow auto
			-webkit-overflow-scrolling touch
			.media
				display inline-block
				width 96px
				height 96px
				margin 0 16px 0 0
				flex-shrink 0
				.img
					width 100%
					height 100%
					background center center no-repeat
					background-size cover
					border-radius 16px
				.remove
					position absolute
					right -8px
					top -8px
					width 32px
					height 32px
					background var(--color-error) url(../../../../assets/img/close.svg) center center no-repeat
					background-size 12px 12px
					border-radius 50%
					cursor pointer


</style>
