<template>
	<div class="rooms">
		<div class="side">
			<div class="scroll" v-scrollbar>
				<div>
					<div class="scroll-content">
						<div class="main-title"><ui-icon name="login-game-master" :size="32" />Sessions</div>

						<ui-link to="/master/rooms/add" class="button">
							<ui-icon name="master/new-game" :size="24" />
							<span class="text">New session</span>
						</ui-link>
						<div class="separator"></div>
						<div class="description">
							<p>After you create your game session:</p>
							<ul>
								<li>To start a single game, click summary and press start.</li>
								<li>To start multiple games simultaneously, link games by selecting each game you want to group together using the <span class="square"></span> to the left, and then click start.</li>
							</ul>
						</div>
						<ui-link :to="'/master/rooms/start-multiple/' + editable_selected_rooms.join(',')" class="button" :class="{ disabled: editable_selected_rooms.length <= 1 }">
							<ui-icon name="master/play" :size="24" />
							<span class="text">Start ({{ editable_selected_rooms.length }}) session<span v-show="editable_selected_rooms.length > 1">s</span></span>
						</ui-link>
						<div v-if="editable_selected_rooms.length > 0" class="separator"></div>
						<ui-link v-if="editable_selected_rooms.length > 0" :to="'/master/rooms/delete/' + editable_selected_rooms.join(',')" class="button red">
							<ui-icon name="master/trash" :size="24" />
							<span class="text">Delete session<span v-if="editable_selected_rooms.length > 1">s</span> ({{ editable_selected_rooms.length }})</span>
						</ui-link>
					</div>
				</div>
			</div>
		</div>
		<div class="main">
			<div class="form">
				<input class="input" placeholder="Search by name or access code" v-model="q">
			</div>
			<div class="scroll" v-scrollbar>
				<div>
					<div class="scroll-content">
						<div v-for="(room, index) in rooms" :key="room.id" class="room" :class="{ selected: selected_rooms.includes(room.id) }">
							<div class="room-header">
								<div class="no">#{{ master.rooms.length - index }}</div>
								<div class="room-title">{{ room.name }}</div>
								<div class="checkbox-wrap" v-hammer:tap="() => toggle_room(room)">
									<div class="checkbox"></div>
								</div>
							</div>
							<div class="room-content">
								<div class="scenario">
									<div class="info">
										<span class="label">Scenario :</span>
										<span class="value">{{ (master.scenarios.find((s) => parseInt(room.scenario_id) === parseInt(s.id)) || { name: '-' }).name }}</span>
									</div>
								</div>
								<div class="separator"></div>
								<div class="infos">
									<div class="info">
										<span class="label">Access Code :</span>
										<span class="value">{{ room.pin }}</span>
									</div>
									<div v-if="room.episode" class="info">
										<span class="label">Episode :</span>
										<span class="value">{{ room.episode.episode_number + 1 }}<span v-if="room.scenario && room.scenario.episodes">/{{ room.scenario.episodes.length }}</span></span>
									</div>
									<div v-if="room.created" class="info">
										<span class="label">Date created :</span>
										<span class="value">{{ room.created.substr(0, 10) }}</span>
									</div>
									<div class="info">
										<span class="label">State :</span>
										<span class="value state" :class="[ room.state, { ready: room.state === 'stopped' && room.episode.episode_number === 0 } ]">
											<span v-if="room.state === 'stopped' && room.episode.episode_number === 0">Ready</span>
											<span v-else>{{ room.state }}</span>
										</span>
									</div>
									<ui-link :to="'/master/rooms/' + room.id" class="main-button" v-hammer:tap="() => enter(room)">
										<span v-if="room.state === 'running'">Enter</span>
										<span v-else>Summary</span>
										<span v-if="entering_room === room" class="loader"><ui-icon name="button-loader" :size="40" /></span>
									</ui-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-show="master.loading" class="loading"><ui-icon name="button-loader" :size="80" /></div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { slugify } from '@/js/utils/utils'

export default {
	name: 'rooms',
	data() {
		return {
			entering_room: null,
			selected_rooms: [],
			q: ''
		}
	},
	computed: {
		...mapGetters([ 'master' ]),
		editable_selected_rooms() {
			return this.master.rooms.filter(r => this.selected_rooms.includes(r.id) && !r.deleted).map(r => r.id)
		},
		rooms() {
			let rooms = this.master.rooms.filter(r => !r.deleted).map((room) => { return { ...room, scenario: this.master.scenarios.find(s => parseInt(s.id) === parseInt(room.scenario_id)) } })
			if (this.q) {
				let regex = new RegExp(this.q.toLowerCase())
				rooms = rooms.filter((r) => {
					return r.name.toLowerCase().match(regex) || r.pin.match(regex)
				})
			}
			return [...rooms].sort((a, b) => {
				if (a.created < b.created) return 1
				if (a.created > b.created) return -1
				return 0
			})
		}
	},
	methods: {
		toggle_room(room) {
			const index = this.selected_rooms.indexOf(room.id)
			if (index > -1) {
				this.selected_rooms.splice(index, 1)
			} else
				this.selected_rooms.push(room.id)
		},
		get_nb_days(room) {
			// let scenario = this.master.scenarios.find((s) => parseInt(s.id) === parseInt(room.scenario_id))
			// if (scenario) {
			// 	let days = 0
			// 	if (scenario.day_0_duration > 0) days++
			// 	if (scenario.day_1_duration > 0) days++
			// 	if (scenario.day_2_duration > 0) days++
			// 	return days
			// }
			return '?'
		},
		enter(room) {
			this.entering_room = room
		}
	},
	mounted() {
		this.$store.dispatch('get_all_rooms')
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile, #app.tablet-vertical
	.rooms
		padding 24px
		.room
			padding 8px
			.room-content
				padding 0 0 8px 0
				.info
					.label
						width 50%
					.value
						width 50%
			.main-button
				position relative
				right auto
				top auto
				bottom auto
				width 100%
				height 48px

.rooms
	flex flex-start flex-start
	height 100%
	blue_lighter = #7FBFD3
	.side
		height 100%
		width 350px
		padding 32px
		.button
			height 64px
			width 100%
			margin 0 0 8px 0
			padding 0 24px
			background-color #3FB143
			border-radius 4px
			flex flex-start center
			cursor pointer
			// transition transform 0.25s easeOutQuart
			font-family var(--josefin)
			font-size 1.8rem
			// line-height (@height + 2px)
			text-transform uppercase
			white-space nowrap
			&.red
				background-color red
				&:hover
					background-color lighten(red, 10%)
				&:active
					background-color lighten(red, 10%)
					transform translateY(2px)
			&:hover
				background-color lighten(#3FB143, 10%)
			&:active
				background-color darken(#3FB143, 10%)
				transform translateY(2px)
			&.disabled
				opacity 0.25
				cursor not-allowed
				&:hover
					background-color #3FB143
				&:active
					background-color #3FB143
					transform none
			.ui-icon
				margin 0 12px 0 0
			.text
				padding-top 3px
		.separator
			height 1px
			width 100%
			margin 16px 0
			background-color blue_lighter
		.description
			color blue_lighter
			margin 0 0 16px 0
			font-size 1.5rem
			ul
				list-style square
				li
					margin 0 0 4px 20px
			p
				margin 0 0 4px 0
				.square
					display inline-block
					width 19px
					height 19px
					margin -4px 2px 0 2px
					transform translateY(4px)
					border 2px solid blue_lighter
	.main
		height 100%
		width calc(100% - 350px)
		max-width 800px
		padding-right 16px
		.form
			flex center flex-end
			height 72px
			padding-bottom 8px
			.input
				width 100%
				background #E1E6E8 url(../../assets/img/search-blue-dark.svg) right 16px center no-repeat
				background-size 24px
				height 40px
				padding 0 24px
				border none
				border-radius 40px
				font inherit
				color dark
		.scroll
			height calc(100% - 72px)
			.scroll-content
				padding 16px
		.loading
			absolute 0
			background-color alpha(blue_dark, 75%)
			flex center center
	.room
		margin 0 0 16px 24px
		border 1px solid blue_lighter
		border-top-right-radius 4px
		border-bottom-right-radius 4px
		border-bottom-left-radius 4px
		&.selected
			background-color darken(blue_darker, 35%)
			.room-header
				.checkbox-wrap
					background-color @background-color
					&:before
						background-color @background-color
					.checkbox
						box-shadow inset 0 0 0 3px @background-color
						background-color blue_lighter
		&:not(.selected)
			.room-header
				.checkbox-wrap:hover
					.checkbox
						background-color darken(blue_darker, 35%)
		.room-header
			height 32px
			flex space-between center
			.no
				display inline-block
				width 100px
				height 32px
				margin-left 8px
				text-align left
				line-height 36px
				padding-left 16px
				border-bottom-left-radius 4px
				background-color blue_lighter
				font-family var(--josefin)
				font-size 2.2rem
				color blue
			.room-title
				display inline-block
				width calc(100% - 116px)
				height 32px
				padding 0 0 0 16px
				line-height 36px
				background-color blue_lighter
				font-family var(--josefin)
				font-size 1.8rem
				text-transform uppercase
				color blue
			.checkbox-wrap
				position absolute
				left -32px
				top -1px
				width 32px
				height 32px
				flex center center
				background-color blue_dark
				border 1px solid blue_lighter
				cursor pointer
				border-top-left-radius 4px
				border-bottom-left-radius 4px
				&:before
					content ''
					position absolute
					right -1px
					top 0px
					bottom 0
					width 1px
					background-color blue_dark
				.checkbox
					width 20px
					height 20px
					border 2px solid blue_lighter
		.room-content
			padding 8px
			.scenario
				display block
				width 100%
				.info
					min-height 32px
					height auto
					.value
						padding-top 4px
						padding-bottom 4px
						line-height 24px
						white-space normal
			.separator
				height 1px
				width calc(100% - 116px)
				background-color blue_lighter
				margin 8px 0 8px 124px
			.infos
				width calc(100% - (150px + 8px))
			.info
				height 32px
				line-height @height
				color blue_lighter
				flex flex-start flex-start
				white-space nowrap
				.label
					display inline-block
					width 100px
					margin 0 16px 0 0
					text-align right
					font-size 1.2rem
				.value
					display inline-block
					width calc(100% - (116px))
					padding-left 8px
					// font-family var(--josefin)
					text-transform uppercase
					font-size 2rem
					&.state
						height 32px
						text-transform uppercase
						border-radius 4px
						color blue_dark
						font-family var(--josefin)
						line-height 36px
						&.ending
							background-color #C18686
						&.stopped
							background-color #C18686
						&.running
							background-color #48B871
						&.paused
							background-color #e5a207
						&.ready
							background-color blue_lighter
		.main-button
			position absolute
			left calc(100% + 8px)
			bottom 0
			height (32px * 3)
			width 150px
			flex center center
			border-radius 4px
			background-color blue
			cursor pointer
			font-family var(--josefin)
			font-size 1.8rem
			text-transform uppercase
			&:hover
				background-color blue_light
			&:active
				background-color blue
				transform translateY(2px)
			.loader
				absolute 0
				background-color blue
				flex center center
				border-radius 4px
				cursor wait

	
</style>
