<template>
	<div class="ui-button" :class="[ color, { inactive: inactive, loading: loading } ]" :style="{ width }">
		<div v-if="!to" class="button">
			<slot />
			<div v-show="loading !== null" class="loader" :class="{ loading: loading, end: is_loading_end }">
				<div class="progress"></div>
				<ui-icon name="button-loader" class="spinner" :size="40" />
			</div>
		</div>
		<router-link v-else :to="to" class="button">
			<slot />
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'ui-button',
	data() {
		return {
			is_loading_end: false,
		}
	},
	props: {
		color: { default: null },
		inactive: { default: null },
		width: { default: '' },
		loading: { default: null },
		to: { default: null },
	},
	watch: {
		loading(newValue, oldValue) {
			if (this.loading != null) {
				if (!this.loading) {
					this.is_loading_end = true
					setTimeout(() => {
						this.is_loading_end = false
					}, 500)
				}
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

body.red-paper
	.ui-button .button
		padding-bottom 6px

#app.mobile
	.ui-button.bounce
		&:before, &:after
			display none

.ui-button
	display inline-block
	border-radius 80px
	user-select none
	&.small
		.button
			padding 0 16px
	&.red
		.button
			background-color var(--color-error)
			color var(--color-button-text)
			.loader.loading
				background-color var(--color-error)
	&.green
		.button
			background-color var(--color-button-bg)
			color var(--color-button-text)
			.loader.loading
				background-color var(--color-button-bg)
	&.inactive
		.button
			cursor not-allowed
			opacity 0.25
			&:active
				transform none
			*
				cursor not-allowed
			>>> .ui-icon
				opacity 0.25
	&.bounce
		&:before, &:after
			content ''
			position absolute
			left 0
			top 0
			right 0
			bottom 0
			border-radius 50%
			cursor pointer
			pointer-events none
			box-shadow 0 0 0 2px alpha(#222, 50%)
			animation bounce_button 1s infinite linear
		&:before
			animation-delay 0s
		&:after
			animation-delay 0.25s
	&.inactive.bounce
		&:before, &:after
			display none
	&.loading .button *
		cursor wait
	.button
		flex center center
		height 48px
		padding 2px 32px 0 32px
		background-color var(--color-primary)
		border none
		cursor pointer
		border-radius 40px
		font inherit
		font-family var(--josefin)
		text-transform uppercase
		font-weight 700
		font-size 2rem
		// line-height (@height + 4px)
		color var(--color-bg)
		white-space nowrap
		text-decoration none
		// font-weight 700
		&:active
			transform translateY(1px)
		*
			cursor pointer
		.loader
			absolute 0
			overflow hidden
			border-radius 80px
			flex center center
			&.loading
				// background-color dark_light
				background-color var(--color-primary)
				color var(--color-bg)
				.progress
					transition width 2s easeOutQuart
					opacity 1
					width 90%
				.spinner
					opacity 1
			&.end
				.progress
					transition 0.5s easeOutQuart
					opacity 0
					width 100%
			.progress
				position absolute
				left 0
				top 0
				bottom 0
				background-color alpha(#fff, 25%)
				width 0
			.spinner
				opacity 0
				
				
		
@keyframes bounce_button
	0%, 100%
		transform scale(1)
		opacity 1
	100%
		transform scale(1.5)
		opacity 0
	

</style>
