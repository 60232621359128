<template>
	<div class="average-carbon-reduction-per-country">
		
		<div class="part part-chart">
			<div class="title">Average carbon reduction per country</div>

			<div class="legends-sorts">
				<div class="legends">
					<span class="legend">
						<!-- Legend | -->
						<span class="el"><span class="line"></span> Global average</span>
					</span>
				</div>

				<div class="sorts" v-hammer:tap="toggle_sort">
					<div class="sort" :class="{ active: sort === 'alpha' }"><ui-icon name="stats/sort-alpha" :size="40" /></div>
					<div class="toggle">
						<div class="round" :class="{ left: sort === 'alpha', right: sort === 'value' }"></div>
					</div>
					<div class="sort" :class="{ active: sort === 'value' }"><ui-icon name="stats/sort-value" :size="40" /></div>
				</div>
			</div>

			<div class="chart">
				<div class="bars">
					<div class="bar"><div class="bar-label">0%</div></div>
					<div class="bar"><div class="bar-label">25%</div></div>
					<div class="bar"><div class="bar-label">50%</div></div>
					<div class="bar"><div class="bar-label">75%</div></div>
					<div class="bar"><div class="bar-label">100%</div></div>
					<div v-if="global_average > 0" class="bar average" :style="{ bottom: global_average + '%' }"><div class="bar-label">{{ global_average }}%</div></div>
				</div>
				<div class="countries">
					<div class="countries-content" :class="{ scrolling: is_scrolling }" :style="{ transform: 'translateX(-' + chart_x + 'px)', width: (80 * countries.length) + 'px' }" id="chart-scroll">
						<div v-for="(country, index) in countries" :key="index" class="country">
							<div v-if="country.player" class="axis-label">{{ country.player.role.name }}<br><span v-if="country.player.student_name">({{ country.player.student_name }})</span></div>
							<div class="country-bar">
								<div class="progress" :style="{ height: (country.percents / nb_years) + '%' }">
									<span class="value">{{ Math.round(country.percents / nb_years) }}%</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="slider" v-if="slider_width < 100">
				<div class="slider-content">
					<input type="range" class="input-range" v-model="scroll" min="0" max="100" id="chart-slider" @mousedown="mousedown" @mouseup="mouseup">
					<div class="slider-thumb" :class="{ scrolling: is_scrolling }" :style="{ left: slider_left + 'px', width: slider_width + '%' }"></div>
				</div>
				<div class="arrow left" v-hammer:tap="() => scroll_to(-10)" v-hammer:press="() => press_scroll_start(-10)" v-hammer:pressup="() => press_scroll_end()"></div>
				<div class="arrow right" v-hammer:tap="() => scroll_to(10)" v-hammer:press="() => press_scroll_start(10)" v-hammer:pressup="() => press_scroll_end()"></div>
			</div>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getRandomID } from '@/js/utils/utils'

export default {
	name: 'average-carbon-reduction-per-country',
	data() {
		return {
			scroll: 0,
			press_scrolling: false,
			is_scrolling: false,
			chart_x: 0,
			slider_width: 24,
			slider_left: 0,
			countries: [],
			sort: 'alpha'
		}
	},
	computed: {
		...mapGetters([ 'game', 'progress', 'logged_user', 'master' ]),
		players() {
			return this.game.players.filter(p => !p.role.tag.includes('observer'))
		},
		questions() {
			let questions = [ ...this.progress.questions ]
			if (this.logged_user.type === 'game-master') questions = [ ...this.master.questions ]
			while (questions.length < 10) {
				questions.push({
					id: getRandomID()
				})
			}
			return questions
		},
		nb_years() {
			return this.questions.filter(q => q.majority_answer && q.majority_answer.unit_details).length
		},
		active_question_config() {
			if (this.active_question) {
				const config = this.game.scenario_config.find(c => parseInt(c.id) === parseInt(this.active_question.config_id))
				if (config && config.value) return JSON.parse(config.value)
			}
			return null
		},
		global_average() {
			const n = this.countries.reduce((acc, country) => {
				return acc + (country.percents / this.nb_years)
			}, 0)
			if (n) return Math.round(n / this.countries.length)
			return 0
		}
	},
	watch: {
		scroll() {
			this.scroll = parseInt(this.scroll)
			this.scrolling()
		}
	},
	methods: {
		toggle_sort() {
			this.sort = this.sort === 'alpha' ? 'value' : 'alpha'
			this.sort_countries()
		},
		scroll_to(value) {
			this.scroll += value
			if (this.scroll < 0) this.scroll = 0
			if (this.scroll > 100) this.scroll = 100
			this.scrolling()
		},
		press_scroll_start(value) {
			this.press_scrolling = true
			this.press_scroll(value)
		},
		press_scroll(value) {
			if (this.press_scrolling) {
				this.scroll_to(value)
				setTimeout(() => {
					this.press_scroll(value)
				}, 100)
			}
		},
		press_scroll_end() {
			this.press_scrolling = false
		},
		mousedown() {
			this.is_scrolling = true
		},
		mouseup() {
			this.is_scrolling = false
		},
		scrolling() {
			const $scrollable = document.querySelector('#chart-scroll')
			if ($scrollable) {
				this.chart_x = this.scroll * ($scrollable.clientWidth - $scrollable.parentNode.clientWidth) / 100
				this.slider_width = $scrollable.parentNode.clientWidth * 100 / $scrollable.clientWidth
				if (this.slider_width > 100) this.slider_width = 100
			}

			const $slider = document.querySelector('#chart-slider')
			if ($slider) {
				const max = $slider.clientWidth - (this.slider_width * $slider.clientWidth / 100)
				this.slider_left = this.scroll * max / 100
			}
		},
		sort_countries() {
			if (this.sort === 'alpha') {
				this.countries.sort((a, b) => {
					if (a.player.role.name > b.player.role.name) return 1
					if (a.player.role.name < b.player.role.name) return -1
					return 0
				})
			}
			else if (this.sort === 'value') {
				this.countries.sort((a, b) => {
					if (a.percents > b.percents) return -1
					if (a.percents < b.percents) return 1
					return 0
				})
			}
		}
	},
	created() {
		this.countries = []
		this.questions.filter(q => q.majority_answer && q.majority_answer.unit_details).forEach((question) => {
			question.majority_answer.unit_details.forEach((u) => {
				let config = this.game.scenario_config.find(c => parseInt(c.id) === parseInt(question.config_id))
				if (config && config.value) config = JSON.parse(config.value)
				if (config && config.decision) {
					const player = this.players.find(p => p && p.role && parseInt(p.role.id) === parseInt(u.role_id))
					if (player) {
						const decision = config.decision.find(d => d.role_id.includes(parseInt(u.role_id)))
						const max = decision ? decision.answers[decision.answers.length - 1].value : 0
						let country = this.countries.find(c => c.role_id === u.role_id)
						if (!country) {
							country = { player, role_id: u.role_id, percents: 0 }
							this.countries.push(country)
						}
						country.percents += (u.unit * 100 / max)
					}
				}
			})
		})
		this.sort_countries()
	},
	mounted() {
		this.scrolling()
		window.addEventListener('resize', this.scrolling)
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.scrolling)
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.average-carbon-reduction-per-country
	padding 0 32px
	.part
		padding 32px 0
		.label
			display block
			margin 0 0 8px 0
			color var(--color-stats-text)
			font-weight 700
			text-transform uppercase
	
		.title
			display block
			margin 0 0 16px 0
			text-transform uppercase
			font-size 1.8rem
			font-weight 400
			font-family var(--josefin)
			color var(--color-stats-text)
		
		.legends-sorts
			margin 0 0 64px 0
			flex space-between center
			.legends
				.legend
					display inline-block
					padding 8px 16px
					border-radius 4px
					background-color var(--color-stats-legend-bg)
					color var(--color-stats-legend-text)
					text-transform uppercase
					.el
						margin 0 0 0 16px
						display inline-block
						color var(--color-stats-chart-theshold)
						&:first-child
							margin-left 0
						.line
							display inline-block
							width 32px
							height 2px
							margin 0 2px 0 0
							transform translateY(-4px)
							background-color var(--color-stats-chart-theshold)
							&.dashed
								background url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E39A75FF' stroke-width='4' stroke-dasharray='4%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") top left repeat-x

			.sorts
				flex center center
				cursor pointer
				.sort
					width 40px
					height 40px
					margin 0 8px
					flex center center
					opacity 0.25
					transition opacity 0.15s easeOutQuart
					&.active
						opacity 1
					>>> .ui-icon
						.a
							fill var(--color-stats-text) !important
						.b
							fill var(--color-stats-legend-bg) !important
				.toggle
					width 48px
					height 24px
					&:before
						content ''
						absolute 0
						background-color var(--color-stats-text)
						opacity 0.25
						border-radius 40px
					.round
						width 24px
						height 24px
						background-color var(--color-stats-text)
						border-radius 100%
						transition transform 0.15s easeOutQuart
						&.right
							transform translateX(24px)
		.chart
			flex center center
			height 350px
			margin-left 40px
			margin-bottom 64px
			user-select none
			.bars
				absolute 0
				.bar
					position absolute
					left 0
					right 0
					bottom 50%
					height 0
					border-bottom 1px solid var(--color-stats-chart-bars)
					// background-color #B6C3CA
					for i in 1..7
						&:nth-child({ i })
							bottom ((i - 1) * 25%)
					&.average
						border-width 2px
						border-color var(--color-stats-chart-theshold)
						bottom 61%
						.bar-label
							color var(--color-stats-chart-theshold)
							font-weight 700
					.bar-label
						position absolute
						top 50%
						right calc(100% + 6px)
						transform translateY(-50%)
						background-color var(--color-stats-chart-bars-label-bg)
						padding 4px 6px
						box-shadow 0 0 8px 4px var(--color-stats-chart-bars-label-bg)
						text-align right
						font-size 1.2rem
						color var(--color-stats-chart-bars-label)
						font-family var(--josefin)
			.countries
				height calc(100% + 128px)
				width 100%
				padding 64px 0
				overflow hidden
				// background-color blue_lighter
				&:before
					content ''
					position absolute
					left 0
					right 0
					bottom 64px
					border-bottom 2px solid var(--color-stats-chart-axis)
				.countries-content
					height 100%
					width (80px * 40)
					flex flex-start flex-end
					transition 0.1s linear
					&.scrolling
						transition none
					.country
						height 100%
						width 80px
						flex center flex-end
						.axis-label
							position absolute
							left 0
							right 0
							top calc(100% + 8px)
							text-align center
							color var(--color-stats-chart-axis)
							font-size 1.2rem
							font-family var(--josefin)
							text-transform uppercase
						.country-bar
							height 100%
							width 40px
							flex center flex-end
							.progress
								width 100%
								color #fff
								text-align center
								background-color var(--color-stats-chart-progress)
								.value
									position absolute
									left 0
									right 0
									bottom 100%
									color var(--color-stats-chart-axis)
		.slider
			flex center center
			.slider-content
				width calc(100% - 64px)
				.input-range
					display block
					width 100%
					height 20px
					appearance none
					background-color var(--color-stats-chart-slider-bg)
					&::-webkit-slider-thumb
						appearance none
						width 30%
						height 20px
						background-color var(--color-stats-chart-slider-thumb)
						opacity 0
				.slider-thumb
					position absolute
					left 0
					top 0
					height 20px
					width 20px
					background-color var(--color-stats-chart-slider-thumb)
					pointer-events none
					transition 0.1s linear
					&.scrolling
						transition none
			.arrow
				position absolute
				top calc(50% - 12px)
				width 0
				height 0
				border-style solid
				cursor pointer
				transition 0.05s easeOutQuart
				&:hover
					transform scale(1.1)
				&:active
					transform scale(1)
				&.left
					left 0
					border-width 12px 16px 12px 0
					border-color transparent var(--color-stats-chart-slider-arrow) transparent transparent
				&.right
					right 0
					border-width 12px 0 12px 16px
					border-color transparent transparent transparent var(--color-stats-chart-slider-arrow)

	

</style>
