<template>
	<div class="global-carbon-reduction-per-year">
		
		<div class="part part-chart">
			<div class="title">Global carbon reduction per year</div>

			<div class="legends">
				<span class="legend">
					<!-- Legend | -->
					<span class="el"><span class="line"></span> Maximum</span>
					<span class="el"><span class="line dashed"></span> 80% Threshold</span>
				</span>
			</div>

			<div class="chart">
				<div class="bars">
					<div class="bar"><div class="bar-label">0</div></div>
					<div class="bar"><div class="bar-label">50</div></div>
					<div class="bar"><div class="bar-label">100</div></div>
					<div class="bar decal"><div class="bar-label">150</div></div>
					<div class="bar"><div class="bar-label">200</div></div>
					<div class="bar"><div class="bar-label">250</div></div>
					<div class="bar last"><div class="bar-label">300 units</div></div>
					<div class="bar gap"><div class="bar-label">80%</div></div>
					<div class="bar middle"><div class="bar-label">50%</div></div>
				</div>
				<div class="years">
					<div class="years-content" :class="{ scrolling: is_scrolling }" :style="{ transform: 'translateX(-' + chart_x + 'px)', width: (80 * totals.length) + 'px' }" id="chart-scroll">
						<div v-for="(total, index) in totals" :key="index" class="year">
							<div class="axis-label">Year {{ index + 1 }}</div>
							<div v-if="total" class="year-bar">
								<div class="progress" :class="{ green: total > gap, red: total <= gap }" :style="{ height: (total * 100 / max) + '%' }">
									<span class="value">{{ total }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="slider" v-if="slider_width < 100">
				<div class="slider-content">
					<input type="range" class="input-range" v-model="scroll" min="0" max="100" id="chart-slider" @mousedown="mousedown" @mouseup="mouseup">
					<div class="slider-thumb" :class="{ scrolling: is_scrolling }" :style="{ left: slider_left + 'px', width: slider_width + '%' }"></div>
				</div>
				<div class="arrow left" v-hammer:tap="() => scroll_to(-10)" v-hammer:press="() => press_scroll_start(-10)" v-hammer:pressup="() => press_scroll_end()"></div>
				<div class="arrow right" v-hammer:tap="() => scroll_to(10)" v-hammer:press="() => press_scroll_start(10)" v-hammer:pressup="() => press_scroll_end()"></div>
			</div>

		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getRandomID } from '@/js/utils/utils'

export default {
	name: 'global-carbon-reduction-per-year',
	data() {
		return {
			scroll: 0,
			press_scrolling: false,
			is_scrolling: false,
			chart_x: 0,
			slider_width: 24,
			slider_left: 0,
			max: 300,
			gap: 240
		}
	},
	computed: {
		...mapGetters([ 'progress', 'logged_user', 'master' ]),
		questions() {
			let questions = [ ...this.progress.questions ]
			if (this.logged_user.type === 'game-master') questions = [ ...this.master.questions ]
			while (questions.length < 10) {
				questions.push({
					id: getRandomID()
				})
			}
			return questions
		},
		totals() {
			const totals = []
			this.questions.forEach((question, index) => {
				totals[index] = 0
				if (question && question.majority_answer && question.majority_answer.unit_details) {
					totals[index] = question.majority_answer.unit_details.reduce((acc, u) => {
						return acc + u.unit
					}, 0)
				}
			})
			return totals
		}
	},
	watch: {
		scroll() {
			this.scroll = parseInt(this.scroll)
			this.scrolling()
		}
	},
	methods: {
		scroll_to(value) {
			this.scroll += value
			if (this.scroll < 0) this.scroll = 0
			if (this.scroll > 100) this.scroll = 100
			this.scrolling()
		},
		press_scroll_start(value) {
			this.press_scrolling = true
			this.press_scroll(value)
		},
		press_scroll(value) {
			if (this.press_scrolling) {
				this.scroll_to(value)
				setTimeout(() => {
					this.press_scroll(value)
				}, 100)
			}
		},
		press_scroll_end() {
			this.press_scrolling = false
		},
		mousedown() {
			this.is_scrolling = true
		},
		mouseup() {
			this.is_scrolling = false
		},
		scrolling() {
			const $scrollable = document.querySelector('#chart-scroll')
			if ($scrollable) {
				this.chart_x = this.scroll * ($scrollable.clientWidth - $scrollable.parentNode.clientWidth) / 100
				this.slider_width = $scrollable.parentNode.clientWidth * 100 / $scrollable.clientWidth
				if (this.slider_width > 100) this.slider_width = 100
			}

			const $slider = document.querySelector('#chart-slider')
			if ($slider) {
				const max = $slider.clientWidth - (this.slider_width * $slider.clientWidth / 100)
				this.slider_left = this.scroll * max / 100
			}
		},
	},
	mounted() {
		this.scrolling()
		window.addEventListener('resize', this.scrolling)
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.scrolling)
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.global-carbon-reduction-per-year
	padding 0 32px
	.part
		padding 32px 0
		.label
			display block
			margin 0 0 8px 0
			color var(--color-stats-text)
			font-weight 700
			text-transform uppercase
	
		.title
			display block
			margin 0 0 16px 0
			text-transform uppercase
			font-size 1.8rem
			font-weight 400
			font-family var(--josefin)
			color var(--color-stats-text)
		
		.legends
			margin 0 0 40px 0
			.legend
				display inline-block
				padding 8px 16px
				border-radius 4px
				background-color var(--color-stats-legend-bg)
				color var(--color-stats-legend-text)
				text-transform uppercase
				.el
					margin 0 0 0 16px
					display inline-block
					color var(--color-stats-chart-theshold)
					&:first-child
						margin-left 0
					.line
						display inline-block
						width 32px
						height 2px
						margin 0 2px 0 0
						transform translateY(-4px)
						background-color var(--color-stats-chart-theshold)
						&.dashed
							background url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E39A75FF' stroke-width='4' stroke-dasharray='4%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") top left repeat-x
				
		
		
		.chart
			flex center center
			height 350px
			margin-left 40px
			margin-bottom 64px
			user-select none
			.bars
				absolute 0
				.bar
					position absolute
					left 0
					right 0
					bottom 50%
					height 0
					border-bottom 1px solid var(--color-stats-chart-bars)
					// background-color #B6C3CA
					for i in 1..7
						&:nth-child({ i })
							bottom ((i - 1) * 16.666666%)
					&.last, &.gap, &.middle
						border-color var(--color-stats-chart-theshold)
						.bar-label
							color var(--color-stats-chart-theshold)
							// font-weight 700
					&.gap, &.middle
						bottom 80%
						border none
						height 2px
						border-bottom 2px dashed var(--color-stats-chart-theshold)
						// background url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E39A75FF' stroke-width='4' stroke-dasharray='4%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") top left repeat-x
					&.middle
						bottom 50%
						.bar-label
							transform translateY(0)
					&.decal
						border none
						.bar-label
							transform translateY(-100%)
							// color var(--color-stats-chart-theshold)
					.bar-label
						position absolute
						top 50%
						right calc(100% + 8px)
						transform translateY(-50%)
						text-align right
						font-size 1.2rem
						color var(--color-stats-chart-bars-label)
						font-family var(--josefin)
			.years
				height calc(100% + 128px)
				width 100%
				padding 64px 0
				overflow hidden
				&:before
					content ''
					position absolute
					left 0
					right 0
					bottom 64px
					border-bottom 2px solid var(--color-stats-chart-axis)
				.years-content
					height 100%
					width (80px * 40)
					flex flex-start flex-end
					transition 0.1s linear
					&.scrolling
						transition none
				.year
					height 100%
					width 10%
					flex center flex-end
					.axis-label
						position absolute
						left 0
						right 0
						top calc(100% + 16px)
						text-align center
						color var(--color-stats-chart-axis)
						font-size 1.2rem
						font-family var(--josefin)
						text-transform uppercase
					.year-bar
						height calc(100% - 2px)
						width 40px
						flex center flex-end
						margin-bottom 2px
						.progress
							width 100%
							color #fff
							text-align center
							&.red
								background-color var(--color-stats-chart-progress-red)
							&.green
								background-color var(--color-stats-chart-progress-green)
							.value
								position absolute
								left 0
								right 0
								bottom 100%
								// font-size 1.2rem
								color var(--color-stats-chart-axis)
		.slider
			flex center center
			.slider-content
				width calc(100% - 64px)
				.input-range
					display block
					width 100%
					height 20px
					appearance none
					background-color var(--color-stats-chart-slider-bg)
					&::-webkit-slider-thumb
						appearance none
						width 30%
						height 20px
						background-color var(--color-stats-chart-slider-thumb)
						opacity 0
				.slider-thumb
					position absolute
					left 0
					top 0
					height 20px
					width 20px
					background-color var(--color-stats-chart-slider-thumb)
					pointer-events none
					transition 0.1s linear
					&.scrolling
						transition none
			.arrow
				position absolute
				top calc(50% - 12px)
				width 0
				height 0
				border-style solid
				cursor pointer
				transition 0.05s easeOutQuart
				&:hover
					transform scale(1.1)
				&:active
					transform scale(1)
				&.left
					left 0
					border-width 12px 16px 12px 0
					border-color transparent var(--color-stats-chart-slider-arrow) transparent transparent
				&.right
					right 0
					border-width 12px 0 12px 16px
					border-color transparent transparent transparent var(--color-stats-chart-slider-arrow)

</style>
