<template>
	<div class="main-view">
		
		<div class="content">
			<form class="form" :class="{ error }" @submit.prevent="submit()">
				<h1 class="title">Game master</h1>
				<div class="lines lines-game-master">
					<div class="line">
						<label for="" class="label">
							<span v-if="forgot_password">Email</span>
							<span v-else>Username</span>
						</label>
						<ui-input v-model="username" placeholder="Example : john@doe.com" />
						<p v-if="errors.username" class="error-message">{{ errors.username }}</p>
					</div>
					<div v-show="!forgot_password" class="line">
						<label for="" class="label">Password</label>
						<ui-input v-model="password" type="password" placeholder="Your private password" />
						<p v-if="errors.password" class="error-message">{{ errors.password }}</p>
					</div>
					<div v-show="!forgot_password" class="line forgot-password">
						<span class="link" v-hammer:tap="() => forgot_password = !forgot_password">Forgot password?</span>
					</div>
					<div v-show="forgot_password" class="line help">
						<p>
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-help-circle"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
							You will receive a request to change your password by email.
						</p>
					</div>
				</div>
				<div class="buttons">
					<button type="button" v-show="forgot_password" class="button active red" v-hammer:tap="() => forgot_password = false">Cancel</button>
					<button type="submit" class="button" :class="{ loading, active: active_button }">Submit</button>
				</div>
				<div v-if="forgot_password_sended" class="forgot-password-sended">
					<div class="sended-title">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
						<p>Sent</p>
					</div>
					<p>Check your email inbox to change your password.</p>
				</div>
			</form>
		</div>
		
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import UiInput from '@/components/ui/ui-input'

export default {
	name: 'view-game-master',
	data() {
		return {
			loading: false,
			forgot_password: false,
			forgot_password_sended: false,
			error: false,
			errors: { username: "", password: "" },
			username: '',
			password: ''
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		active_button() {
			if (this.forgot_password) return this.username !== ''
			return (this.username && this.password)
		}
	},
	watch: {
		forgot_password() {
			this.loading = false
			this.forgot_password_sended = false
			setTimeout(() => {
				let el = this.$el.querySelector('.form .lines-' + this.active_form + ' .line:first-child input')
				if (el) el.focus()
			}, 10)
		}
	},
	methods: {
		async submit() {
			if (this.forgot_password) {
				this.loading = true
				let r = await Apollo.mutate('START_RESET_PASSWORD', { to: this.username })
				if (r) {
					this.forgot_password_sended = true
				}
				this.loading = false
			}

			else {
				this.errors = { username: "", password: "" }

				this.loading = true
				let r = await this.$store.dispatch('login', { type: 'game-master', username: this.username, password: this.password })
				if (r.status == 'error') {
					this.errors[r.field] = r.message
				}
				this.loading = false
			}
		},
	},
	components: { UiInput }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

.main-view
	width 100%
	height 100%
	.content
		width 100%
		height 100%
		background-color var(--color-bg)
		// background-color c(bg)
		font-size 1.6rem
		flex center center
		flex-direction column
		align-content center
		.form
			position absolute
			left calc(50% - 175px)
			top calc(50% - 160px)
			// left 50%
			// top 50%
			width 350px
			// transform translate(-50%, -50%)
			// height ((64px + 16px) * 2 + 48px + 64px + 40px)
			padding 40px
			background var(--color-login-form-bg)
			box-shadow 0 0 0 2px var(--color-login-form-border)
			color var(--color-login-form-text)
			border-radius 8px
			&.error
				box-shadow 0 0 0 4px var(--color-error)
				animation shake 0.25s infinite
			> .title
				display block
				margin 0 0 24px 0
				text-transform uppercase
				font-size 3.2rem
				line-height 1em
				color var(--color-login-form-text)
			.line
				margin 0 0 16px 0
				.error-message
					margin 4px 0 0 0
					color var(--color-error)
					font-size 1.2rem
					line-height 14px
					font-weight 700
					text-transform uppercase
				&.forgot-password
					text-align right
					font-size 1.4rem
					line-height 1em
					.link
						cursor pointer
						opacity 0.5
						&:hover
							opacity 1
				&.help
					user-select none
					p
						padding-left 34px
						font-size 14px
						color var(--color-text)
						line-height 17px
						>>> svg
							position absolute
							left 0
							top calc(50% - 14px)
							width 28px
							height 28px
			.label
				display block
				width 100%
				line-height 24px
				opacity 0.5
			.ui-input
				background-color var(--color-input-text-bg)
				height 40px
				padding 0 16px
				color var(--color-input-text-text)
				border-radius 4px
				&:focus
					background-color var(--color-input-text-bg)
				&::-webkit-input-placeholder
					color var(--color-input-text-text)
					opacity 0.25
				&::-moz-placeholder
					color var(--color-input-text-text)
					opacity 0.25
				&:-ms-input-placeholder
					color var(--color-input-text-text)
					opacity 0.25
				&:-moz-placeholder
					color var(--color-input-text-text)
					opacity 0.25
			.buttons
				padding 8px 0 0 0
				flex center center
				.button
					display inline-block
					height 40px
					padding 0 16px
					margin 0 4px
					border none
					background-color var(--color-button-disabled)
					border-radius 4px
					outline none
					cursor not-allowed
					text-transform uppercase
					font inherit
					color var(--color-button-disabled-text)
					font-size 1.6rem
					font-weight 700
					font-family var(--josefin)
					line-height (@height + 3px)
					&.active
						background var(--color-button-bg)
						color var(--color-button-text)
						cursor pointer
					&.red
						background-color var(--color-button-error)
						color var(--color-button-error-text)
					&:active
						transform translateY(1px)
					&.loading
						overflow hidden
						cursor not-allowed
						&:before
							opacity 1
					&:before
						content ''
						opacity 0
						absolute 0
						background var(--color-button-bg) url(../assets/img/button-loader.svg) center center no-repeat
						background-size auto 8px
			.forgot-password-sended
				absolute 24px
				background-color @background
				flex center center
				flex-direction column
				text-align center
				line-height 1.2em
				color gray_light
				.sended-title
					flex center center
					flex-direction column
					color var(--color-text)
					font-size 24px
					line-height 1em
					font-family var(--josefin)
					text-transform uppercase
					>>> svg
						width 40px
						height 40px
						margin 0 0 8px 0
					> p
						margin 0 0 8px 0
			.close
				position absolute
				right 0
				top 0
				width 48px
				height 48px
				flex center center
				cursor pointer
				color var(--color-text)
				transition 0.1s easeOutQuart
				opacity 0.25
				&:hover
					transform scale(1.1)
					opacity 1
				&:active
					transform scale(0.9)

		
	
</style>
