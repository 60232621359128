<template>
	<div class="ui-info-box" :class="{ 'fullscreen-video': info_box.style === 'Fullscreen' }">
		<div class="bg"></div>
		<div class="scroll" v-scrollbar>
			<div>
				<div class="content">
					<div v-if="ago" v-show="ago !== 'less than a minute ago'" class="ago">Received {{ ago }}</div>
					<div v-if="info_box.title" class="title">{{ info_box.title }}</div>
					<div v-if="info_box.name" class="title">{{ info_box.name }}</div>
					<ui-text v-if="info_box.description" class="text" :text="description" :tooltip-position="info_box.style === 'Fullscreen' ? 'bottom' : ''"></ui-text>
					
					<div v-if="info_box.style && info_box.style === 'Public_satisfaction_outcome' && percent !== false && game.scenario.meter_status" class="outcome">
						<div class="public-opinion">
							<div class="bar">
								<div class="progress" :style="'width:' + (percent) + '%'"></div>
								<div class="ticks">
									<div class="tick t25"></div>
									<div class="tick t50"></div>
									<div class="tick t75"></div>
								</div>
							</div>
							<div v-if="is_cold_ward_scenario" class="percent">{{ game.room.meter_label.replace('None', '') }}</div>
							<div v-else class="percent">{{ (percent) }}%</div>
						</div>
					</div>

					<div v-if="info_box.style && [ 'Review', 'Comment', 'Comment_Review' ].includes(info_box.style)" class="review">
						<comment-review :info_box="info_box" />
					</div>

					<div class="buttons">
						<div v-if="info_box.type === 'decision'" class="button" v-hammer:tap="() => see_decison()">See Decision</div>
						<div v-else-if="info_box.type === 'document'" class="button" v-hammer:tap="() => see_document()">See Document</div>
						<div v-else-if="info_box.style === 'Review'" class="button" v-hammer:tap="() => close()">Close</div>
						<!-- <div v-else class="button" v-hammer:tap="() => close()">OK</div> -->
					</div>

					<!-- <div v-if="info_box.closable || info_box.style === 'Fullscreen' || info_box.style === 'Review'" class="close" v-hammer:tap="() => close()"><ui-icon name="close-blue-dark" :size="16" /></div> -->
					<div class="close" v-hammer:tap="() => close()"><ui-icon name="close-blue-dark" :size="16" /></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import UiText from '@/components/ui/ui-text'
import CommentReview from '@/components/game/review/comment-review'
import formatDistance from 'date-fns/formatDistance'
let timer = null

export default {
	name: 'ui-info-box',
	data() {
		return {
			created_date: null,
			current_date: null,
			percent: false
		}
	},
	props: {
		info_box: { default: null }
	},
	computed: {
		...mapGetters([ 'app', 'game', 'progress', 'current_question', 'current_question_conversation' ]),
		description() {
			let str = ''
			if (this.info_box && this.info_box.description) {
				str = this.info_box.description
				if (this.info_box.params) {
					Object.keys(this.info_box.params).forEach((k) => {
						str = str.replace(new RegExp('###' + k + '###', 'gi'), '**' + this.info_box.params[k] + '**')
					})
				}
				if (this.info_box.details) {
					if (this.info_box.details.p_role_id) {
						let n = 1
						this.info_box.details.p_role_id.forEach((role_id) => {
							const player = this.game.players.find(p => p && p.role && parseInt(p.role.id) === parseInt(role_id))
							if (player && player.role) {
								str = str.replace('[positive' + n + ']', '<span class="primary">' + player.role.name + '</span>')
								n++
							}
						})
					}
					if (this.info_box.details.n_role_id) {
						let n = 1
						this.info_box.details.n_role_id.forEach((role_id) => {
							const player = this.game.players.find(p => p && p.role && parseInt(p.role.id) === parseInt(role_id))
							if (player && player.role) {
								str = str.replace('[negative' + n + ']', '<span class="primary">' + player.role.name + '</span>')
								n++
							}
						})
					}
				}
			}
			return str
		},
		is_cold_ward_scenario() {
			return parseInt(this.game.scenario.id) === 23
		},
		ago() {
			if (this.created_date && this.current_date) {
				return formatDistance(this.created_date, this.current_date, { addSuffix: true })
			}
			return false
		}
	},
	methods: {
		close() {
			this.$store.dispatch('remove_info_box', { info_box: this.info_box })
		},
		see_decison() {
			if (this.current_question_conversation && this.current_question_conversation.sender_id_id) {
				if (this.app.device === 'mobile' || this.app.device === 'tablet-vertical' || this.app.device === 'tablet-horizontal') {
					this.$router.push({ path: '/game/chirp/messages' })
					this.$store.dispatch('set_chirp_route', { name: 'messages' })
				} else {
					this.$store.commit('EDIT_CHIRP_STATE', { route: {
						meta: { backButton: true, menu: 'messages' },
						name: 'conversation-question',
						params: { group: false, player: this.game.players.find(p => p.role && parseInt(p.role.id) === parseInt(this.current_question_conversation.sender_id_id)) }
					} })
					this.$store.dispatch('start_tuto', { slug: 'dm-decision' })
				}
			}
			else if (this.current_question && this.$route.name === 'decisions') {
				this.$store.dispatch('set_progress', { selected_question: this.current_question })
			} else {
				this.$router.push({ path: '/game/decisions' })
				this.$store.dispatch('start_tuto', { slug: 'decision' })
			}
			this.close()
		},
		see_document() {
			if (this.info_box.params && this.info_box.params.doc && this.info_box.params.doc.id) {
				this.$router.push({ path: '/game/files/documents/' + this.info_box.params.doc.id })
			}
			this.close()
		}
	},
	mounted() {
		if (this.info_box.params && this.info_box.params.public_opinion) {
			if (this.is_cold_ward_scenario)
				this.percent = this.game.room.public_opinion
			else
				this.percent = this.info_box.params.public_opinion
			if (this.info_box.params.impact) this.percent -= this.info_box.params.impact
			setTimeout(() => {
				if (this.is_cold_ward_scenario)
					this.percent = this.game.room.public_opinion
				else
					this.percent = this.info_box.params.public_opinion
			}, 1000)
		}
	},
	async created() {
		this.created_date = new Date()
		this.current_date = new Date()

		timer = setInterval(() => {
			this.current_date = new Date()
		}, 1000)

		if (this.info_box.details && this.info_box.details.question_id) {
			this.$store.dispatch('update_unit_details')
		}
	},
	beforeDestroy() {
		if (timer) clearInterval(timer)
	},
	components: { UiText, CommentReview }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


body.red-paper
	.ui-info-box .content
		.title, >>> h1
			font-family 'Courier New Bold'
		.buttons .button
			line-height 50px
		.percent
			text-transform uppercase
			font-size 2rem

#app.mobile
	.ui-info-box
		.scroll
			padding 8px
		.content
			padding 40px 24px 32px 24px
			.title, >>> h1
				font-size 2.4rem
				line-height 1.2em
			.text
				font-size 1.6rem
				line-height 1.4em
				>>> h2
					font-size 2rem
				>>> h3
					font-size 2rem
				>>> p
					font-size 1.6rem


#app.mobile, #app.tablet-vertical, #app.tablet-horizontal, #app.desktop
	.ui-info-box.fullscreen-video
		.scroll
			padding 0
			overflow hidden !important
			> div
				display block !important
				height 100%
				width 100%
				.content
					width 100%
					height 100%
					padding 0
					border-radius 0
					border none
					flex center center
					flex-direction column
					.ago, .title, .buttons
						display none !important
					>>> .ui-text
						width 100%
						height 100%
						margin 0
						> *
							width 100%
							height 100%
						.video
							width 100%
							height 100%
							background #000
							video
								width 100%
								height 100%
								max-width none
							
					.close
						top 16px
						right 16px
						width 64px
						height 64px
						box-shadow none
						.ui-icon
							width 24px !important
							height 24px !important

.ui-info-box
	absolute 0
	z-index 3
	flex center center
	.bg
		absolute 0
		background-color var(--color-infobox-bg)
	.scroll
		width 100%
		height 100%
		padding 32px
		> div
			flex center center
			display flex !important
			min-height 100%
	.content
		width 700px
		padding 48px 32px
		border 4px solid var(--color-infobox-content-border)
		background-color var(--color-infobox-content-bg)
		border-radius 12px
		text-align center
		.ago
			position absolute
			left 0px
			top 0px
			height 24px
			padding 0 12px
			border-radius 6px 0 8px 0
			color var(--color-infobox-content-border-date-text)
			font-size 1.2rem
			text-transform uppercase
			line-height (@height + 0px)
			font-weight 700
			font-family var(--josefin)
			background-color var(--color-infobox-content-border)
		.title, >>> h1
			margin 0 0 16px 0
			font-family var(--josefin)
			color var(--color-infobox-content-h1)
			font-size 3.4rem
			font-weight 700
			text-transform uppercase
			line-height 44px
		.text
			margin 0 0 32px 0
			color var(--color-infobox-content-text)
			font-size 1.6rem
			line-height 1.8em
			>>> h2
				font-family var(--poppins)
				font-size 2.4rem
				color var(--color-infobox-content-h2)
			>>> h3
				font-family var(--poppins)
				font-size 2.4rem
				color var(--color-infobox-content-h3)
			>>> p
				font-family var(--poppins)
				font-size 2.2rem
				color var(--color-infobox-content-text)
			>>> iframe
				height 360px
			>>> span.primary
				color var(--color-infobox-content-h1)
				font-weight 700
		
		.public-opinion
			height 20px
			margin 0 0 32px 0
			flex center center
			.bar
				box-shadow 0 0 0 2px var(--color-infobox-content-border)
				height 100%
				width 90%
				max-width 400px
				margin 0 16px 0 0
				border-radius 40px
				background-color var(--color-bottom-bar-meter-bg)
				overflow hidden
				.progress
					position absolute
					left 0
					top 0
					bottom 0
					border-top-left-radius 40px
					border-bottom-left-radius 40px
					background linear-gradient(to left, var(--color-bottom-bar-meter-progress-1), var(--color-bottom-bar-meter-progress-2))
					transition 1.5s linear
				.ticks
					position absolute
					left 0
					bottom 0
					right 0
					height 50%
					.tick
						position absolute
						bottom 0
						width 4px
						height 100%
						margin-left -2px
						background-color var(--color-infobox-content-border)
						&.t25
							left 25%
						&.t50
							left 50%
						&.t75
							left 75%
			.percent
				width 64px
				height 20px
				text-align right
				font-size 3.2rem
				line-height @height
				color var(--color-infobox-content-text)

		.buttons
			text-align center
			.button
				display inline-block
				height 48px
				padding 0 20px
				margin 0 4px
				border-radius 4px
				background-color var(--color-infobox-content-button-bg)
				cursor pointer
				line-height (@height + 6px)
				font-weight 700
				color var(--color-infobox-content-button-text)
				text-transform uppercase
				font-size 2rem
				font-family var(--josefin)
				&:active
					transform translateY(2px)
		
		.close
			position absolute
			right -12px
			top -12px
			width 40px
			height 40px
			border-radius 50%
			background-color var(--color-infobox-content-button-bg)
			color var(--color-infobox-content-button-text)
			flex center center
			cursor pointer
			box-shadow 0 0 0 4px var(--color-infobox-content-button-text)
			&:active
				transform translateY(1px)

</style>
