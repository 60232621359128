<template>
	<form class="form-comment" @submit.prevent="post_review">
		<div class="row">
			<textarea id="post-review" :value="value" @input="$emit('input', $event.target.value)" class="input" :disabled="sent_review !== ''" :class="{ big: value && !sent_review }" placeholder="Write your comment here"></textarea>
			<div class="buttons">
				<button type="submit" class="button" :class="{ sent: sent_review !== '' }" :disabled="!reviewable || sent_review !== ''">
					<template v-if="sent_review">Sent</template>
					<template v-else>Send</template>
				</button>
			</div>
		</div>
	</form>
</template>

<script>
export default {
	data() {
		return {
			sent_review: '',
		}
	},
	props: [ 'value' ],
	computed: {
		reviewable() {
			return this.value?.length > 5
		}
	},
	methods: {
		async post_review() {
			if (this.reviewable) {
				this.sent_review = this.value
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.form-comment
	.row
		flex flex-start flex-start column
		gap 8px
		.input
			width 100%
			height 96px
			padding 16px
			border none
			background var(--color-summary-row-bg)
			border-radius 8px
			font inherit
			color inherit
			outline none
			resize none
			transition height 0.25s easeOutQuart
			&.big
				height 200px
			&:focus
				outline 2px solid var(--color-summary-row-text)
				outline-offset 2px
			&::placeholder
				color inherit
				opacity 0.35
		.buttons
			width 100%
			flex flex-end flex-end
			.button
				flex-shrink 0
				height 48px
				padding 4px 16px 0 16px
				border-radius 8px
				border none
				background var(--color-button-bg)
				font inherit
				font-size 2rem
				font-family var(--josefin)
				font-weight 600
				color var(--color-button-text)
				cursor pointer
				&[disabled]
					cursor not-allowed
					opacity 0.5
				&:not([disabled]):not(.sending):hover
					background var(--color-button-bg-hover)
					color var(--color-button-text-hover)
				&.sending
					opacity 0.5
					cursor wait
				&.sent
					flex center center
					gap 8px
					&:after
						content ''
						width 24px
						height 24px
						background url('../../../assets/img/check-white.svg') center center no-repeat
						background-size contain
						transform translateY(-2px)

</style>