<template>
	<div v-if="chirp_item" class="chirp-item" :class="{ 'has-media': chirp_item.media.length > 0 }">
		<div class="part-left">
			<div class="avatar">
				<!-- <img :src="avatar_url"> -->
				<div class="img" v-bg="{ name: chirp_item.sender.avatar, type: 'avatar' }">
					<div v-if="chirp_item.sender.student_number !== 'N/A'" class="online"></div>
				</div>
				<div v-if="chirp_item.message_type == 'Story'" class="flag"></div>
			</div>
		</div>
		<div class="part-right">
			<p class="chirp-date">{{ ago }}</p>
			<p class="name">{{ chirp_item.sender.name }}</p>
			<p class="username">@{{ chirp_item.sender.chirp_username }}</p>
			<p class="chirp-text">{{ chirp_item.message }}</p>
			
			<div v-if="chirp_item.media && chirp_item.media.length" class="medias" :class="'medias-' + chirp_item.media.length">
				<div v-for="(url, index) in chirp_item.media" class="media" v-hammer:tap="() => view_media(url)" :key="index">
					<div class="img">
						<img :src="url">
					</div>
				</div>
			</div>

			<div class="chirp-menu">
				<div class="chirp-menu-item" :class="{ disabled: !allow_interactions }" v-hammer:tap="() => like()">
					<transition name="increment">
						<ui-icon v-show="!liked" name="chirp/like-off" :size="24" class="icon" />
					</transition>
					<transition name="increment">
						<ui-icon v-show="liked" name="chirp/like-on" :size="24" class="icon liked" />
					</transition>
					{{ chirp_item.like }}
				</div>
				<div class="chirp-menu-item" :class="{ disabled: !allow_interactions }" v-hammer:tap="() => share()">
					<transition name="increment">
						<ui-icon v-show="!shared" name="chirp/rechirp-off" :size="24" class="icon" />
					</transition>
					<transition name="increment">
						<ui-icon v-show="shared" name="chirp/rechirp-on" :size="24" class="icon shared" />
					</transition>
					{{ chirp_item.share }}
				</div>
				<!-- <div class="chirp-menu-item" v-hammer:tap="() => increment('answer')">
					<transition name="increment">
						<ui-icon v-show="!answer" name="chirp/answer-off" :size="24" class="icon" />
					</transition>
					<transition name="increment">
						<ui-icon v-show="answer" name="chirp/answer-on" :size="24" class="icon" />
					</transition>
					0
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatDistance from 'date-fns/formatDistance'

export default {
	name: 'chirp-item',
	data() {
		return {
			// loading: true,
		}
	},
	props: {
		chirp_item: { default: false },
		allow_interactions: { default: true }
	},
	computed: {
		...mapGetters([ 'app', 'logged_user', 'chirp', 'game' ]),
		ago() {
			if (this.game.room.timer !== null) {
				if (this.chirp_item.in_game_day_created === this.game.room.episode.episode_number) {
					return formatDistance(new Date(this.chirp_item.in_game_created * 1000), new Date((this.game.room.timer_total - this.game.room.timer) * 1000), { addSuffix: true })
				}
			}
			return "From a previous session"
		},
		liked() {
			if (this.chirp_item)
				return this.chirp_item.like_by.includes(this.logged_user.id)
			return false
		},
		shared() {
			if (this.chirp_item)
				return this.chirp_item.share_by.includes(this.logged_user.id)
			return false
		}
	},
	methods: {
		view_media(url) {
			this.$store.dispatch('set_chirp_current', { media_viewer: {
				chirp_item: this.chirp_item,
				media: { url }
			} })
		},
		like() {
			if (this.allow_interactions && !this.app.loading) {
				if (!this.liked) this.$store.dispatch('like_chirp', { chirp: this.chirp_item })
				else this.$store.dispatch('unlike_chirp', { chirp: this.chirp_item })
			}
		},
		share() {
			if (this.allow_interactions && !this.app.loading) {
				if (!this.shared) this.$store.dispatch('share_chirp', { chirp: this.chirp_item })
				else this.$store.dispatch('unshare_chirp', { chirp: this.chirp_item })
			}
		}
	},
	mounted() {
		if (!this.chirp_item.read_by.includes(this.logged_user.id)) {
			this.$store.dispatch('read_message', { message: this.chirp_item })
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.increment-enter-active
	transition 0.35s easeOutQuart
.increment-leave-active
	transition 0.35s easeOutQuart
.increment-enter, .increment-leave-to
	transform scale(5)
	opacity 0


#app.tablet-vertical, #app.tablet-horizontal
	.chirp-item
		&.has-media
			.part-right
				&:after
					content ''
					display block
					clear both
				.chirp-text, .chirp-menu
					float left
					width 60%
				.chirp-text
					line-height 22px
					padding-right 8px
				.medias
					float right
					width 40%
					&.medias-1
						height 150px
					&.medias-2
						height 75px
					&.medias-3
						height (150px + 75px + 8px)
						.media
							height 75px
							&:first-child
								height 150px
					&.medias-4
						height 158px
						.media
							height 75px

// .chirp-item.loading
// 	.part-left
// 		.avatar
// 			border-radius 50%
// 			background linear-gradient(45deg, rgba(169,224,246,1) 0%, rgba(198,212,217,1) 100%)
// 			background linear-gradient(45deg, blue_lighter 0%, gray_light 50%, blue_lighter 100%)
// 			background-size 400% 400%
// 			animation element_loading 3s linear infinite forwards
// 			img, .flag
// 				display none
// 	.part-right
// 		.name, .username, .chirp-text, .chirp-menu .chirp-menu-item
// 			&:after
// 				content ''
// 				position absolute
// 				left 0
// 				top 0
// 				height 100%
// 				width 100%
// 				background-color desaturate(blue_lighter, 75%)
// 				background linear-gradient(45deg, rgba(169,224,246,1) 0%, rgba(198,212,217,1) 100%)
// 				background linear-gradient(45deg, blue_lighter 0%, gray_light 50%, blue_lighter 100%)
// 				background-size 400% 400%
// 				box-shadow inset 0 0 0 2px blue
// 				animation element_loading 3s linear infinite forwards
// 		.name:after
// 			width 60%
// 		.username:after
// 			width 75%
// 		.chirp-text
// 			height 60px
// 			overflow hidden
// 			:after
// 				box-shadow inset 0 0 0 2px blue, 0 0 0 2px blue
// 		.medias
// 			&.medias-1, &.medias-2, &.medias-3, &.medias-4
// 				display none
// 		.chirp-menu
// 			.chirp-menu-item
// 				&:after
// 					top calc(50% - 12px)
// 					height 24px
// 					width 75%
				

body.brown
	.chirp-item
		.part-left .avatar .img
			background-color #AAE1C2
			.online
				display none
		.part-right
			padding-bottom 0px
			border-bottom none
			.chirp-date, .chirp-menu, .username
				display none
			.name
				font-weight 400
				margin 0 0 4px 0
			.chirp-text
				padding 16px
				border-radius 8px
				background-color #fff
				color #2A1A0A


.chirp-item
	padding 16px
	color var(--color-chirp-item-color)
	&:last-child
		.part-right
			border-bottom none
			padding-bottom 0
	.part-left
		float left
		width 64px
		flex flex-start flex-start
		.avatar
			width 64px
			height 64px
			// img
			// 	height 100%
			// 	width 100%
			// 	object-fit cover
			// 	border-radius 50%
			.img
				width 100%
				height 100%
				background center center no-repeat
				background-size cover
				border-radius 50%
				.online
					position absolute
					right 0
					top 0
					width 16px
					height 16px
					background-color var(--color-chirp-item-online-dot)
					box-shadow 0 0 0 4px var(--color-chirp-bg)
					border-radius 50%
			.flag
				position absolute
				left calc(50% - 16px)
				top 64px
				width 32px
				height 60px
				background url('../../../../assets/img/chirp/story-flag.svg') top center no-repeat
				background-size 100% auto
	.part-right
		padding 0 0 32px 0
		margin-left (64px + 16px)
		border-bottom 2px solid var(--color-chirp-item-border)
		line-height 20px
		.name
			margin 0
			// font-size 1.6rem
			font-weight 600
			text-transform uppercase
		.username
			margin 0 0 8px 0
			padding 0 0 0 8px
		.chirp-date
			// position absolute
			// right 0
			// top 0
			margin 0 0 0px 0
			opacity 0.5
			font-size 1.2rem
		.chirp-text
			margin 0 0 8px 0
			font-size 1.6rem
		.medias
			margin 0 0 8px 0
			height auto
			flex space-between center
			flex-wrap wrap
			.media
				width calc(50% - 4px)
				min-height 100px
				margin 0 0 8px 0
				overflow hidden
				.img
					display flex
					align-items center
					justify-content center
					img
						width 100%
						height auto
			&.medias-1
				// height 200px
				flex flex-start flex-start
				.media
					width 100%
					// height 100%
			&.medias-2
				height 100px
			&.medias-3
				height 308px
				flex space-between center
				flex-wrap wrap
				.media
					width calc(50% - 4px)
					height 100px
					&:first-child
						float none
						width 100%
						height 200px
						margin 0 0 8px 0
			&.medias-4
				height 208px
			.media
				background-color var(--color-chirp-item-media-bg)
				cursor pointer
				// img
				// 	width 100%
				// 	height 100%
				// 	object-fit cover
				.img
					width 100%
					height 100%
					background center center no-repeat
					background-size cover
		.chirp-menu
			height 40px
			flex flex-start center
			.chirp-menu-item
				height 100%
				width 80px
				flex flex-start center
				cursor pointer
				padding-left 32px
				&.disabled
					cursor default
				.icon
					position absolute
					left 0
					top 8px
					width 24px
					height 24px
					margin 0 8px 0 0
					&.liked
						color var(--color-chirp-item-like)
					&.shared
						color var(--color-chirp-item-rechirp)
	

@keyframes element_loading
	0%
		background-position 0% 50%
	50%
		background-position 100% 50%
	100%
		background-position 0% 50%
	
</style>
