<template>
	<div class="news-story">
		<div class="side">
			<ui-icon :name="'news/' + paper.slug" :size="80" />
		</div>
		<div class="content">
			<div class="story-title"><ui-text :text="story.title" /></div>
			<div class="story-author">{{ story.sender.name }} <span class="separator">|</span> {{ ago }}</div>
			<div class="story-content"><ui-text :text="story.message" /></div>
			<div class="story-images">
				<img v-for="(img, i) in story.media" :key="i" :src="img">
			</div>
	
			<ui-likes-editor v-if="logged_user.type === 'game-master'" :message="story" />

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiText from '@/components/ui/ui-text'
import UiLikesEditor from '@/components/ui/ui-likes-editor'
import formatDistance from 'date-fns/formatDistance'

export default {
	name: 'news-story',
	data() {
		return {

		}
	},
	props: {
		story: { default: null }
	},
	computed: {
		...mapGetters([ 'game', 'chirp', 'logged_user' ]),
		ago() {
			if (this.game.room.timer !== null) {
				if (this.story.in_game_day_created === this.game.room.episode.episode_number) {
					return formatDistance(new Date(this.story.in_game_created * 1000), new Date((this.game.room.timer_total - this.game.room.timer) * 1000), { addSuffix: true })
				} else
					return "From a previous session"
			}
			return null
		},
		paper() {
			if (this.story) {
				return this.chirp.news_papers.find((p) => {
					return this.story.tags.includes(p.slug)
				})
			}
			return null
		}
	},
	mounted() {
		if (!this.story.read_by.includes(this.logged_user.id)) {
			this.$store.dispatch('read_message', { message: this.story })
		}
	},
	components: { UiText, UiLikesEditor }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

body.red-paper
	.news-story
		.content
			.story-content
				color #000
		
#app.mobile
	.news-story
		flex-direction column
		margin 0 0 24px 0
		padding 0 24px
		&:last-child
			.content
				border none
		.side
			width 100%
		.content
			width 100%
			padding 0 0 24px 0
			border none
			border-bottom 2px solid var(--color-primary)
			
#app.tablet-vertical
	.news-story
		padding 0 24px

#app.tablet-horizontal
	.news-story
		padding 0

.news-story
	flex flex-start flex-start
	margin 0 0 40px 0
	.side
		width (80px + 24px)
		color var(--color-primary)
	.content
		width calc(100% - 104px)
		padding 0 24px
		border-left 2px solid var(--color-primary)
		.story-title
			// margin 0 0 8px 0
			text-transform uppercase
			font-size 2.4rem
			color var(--color-primary)
			font-weight 700
		.story-author
			color var(--color-news-story-text)
			margin 0 0 16px 0
			.separator
				margin 0 4px
		.story-content
			color var(--color-news-story-text)
		.story-images
			// text-align center
			img
				width 100%
				max-width 400px
				height auto
				vertical-align middle
				margin 0 0 16px 0
				&:last-child
					margin 0
		>>> .ui-likes-editor
			margin 16px 0 0 0
		>>> .ui-text
			p:last-child
				margin 0
			iframe
				background-color #001923


</style>
