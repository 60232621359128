<template>
	<div class="main-view" :class="{ 'has-side': $route.name != 'master' }">
		
		<div class="content">
			<router-view />

			<ui-link to="/master/logout" class="logout">
				<svg class="hexagon" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path fill="currentColor" d="m485.291 129.408-224-128c-3.285-1.877-7.296-1.877-10.581 0l-224 128c-3.328 1.899-5.376 5.44-5.376 9.259v234.667c0 3.819 2.048 7.36 5.376 9.259l224 128c1.643.939 3.456 1.408 5.291 1.408s3.648-.469 5.291-1.408l224-128c3.328-1.899 5.376-5.44 5.376-9.259v-234.667c-.001-3.819-2.049-7.36-5.377-9.259z"/></g></svg>
				<ui-icon name="logout" :size="48" />
				<div class="text">Log out</div>
			</ui-link>
		</div>
		
		<master-window v-if="$route.meta.window" :window_content="$route.meta.window" />

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MasterWindow from '@/components/master/master-window'

export default {
	name: 'view-game-master',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'app' ])
	},
	components: { MasterWindow }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


#app.mobile, #app.tablet-vertical
	.main-view
		height 100%
		&.has-side
			.content
				absolute 0
				margin 0
				width 100%
				padding 0


.main-view
	width 100%
	// height calc(100% - 80px)
	height 100%
	// margin-left 97px
	color #fff
	.content
		// width calc(100% - 97px)
		width 100%
		height calc(100% - 80px)
		background-color blue_dark
		// padding 0 16px
		>>> .view
			// max-width 700px
			width 100%
			height 100%
			.main-title
				height 40px
				margin 0 0 40px 0
				flex flex-start center
				color blue_lighter
				text-transform uppercase
				font-size 2.4rem
				font-family var(--josefin)
				> .ui-icon
					margin 0 16px 0 0
		.logout
			position absolute
			left 24px
			bottom 96px
			width 100px
			height 100px
			flex center center
			flex-direction column
			cursor pointer
			color #6E2121
			&:hover > svg
				color lighten(#B93636, 5%)
			&:active
				transform translateY(2px)
				> svg
					color darken(#B93636, 10%)
			> svg
				absolute 0
				width 100%
				height 100%
				color #B93636
			> .ui-icon
				margin-top -4px
			.text
				display block
				margin 4px 0 0 0
				font-weight 700
				text-transform uppercase
				font-size 1.2rem
				color #6E2121
				font-family var(--josefin)
		.loading
			absolute 0
			max-width 700px
			background alpha(blue_dark, 50%) url(../assets/img/button-loader.svg) center center no-repeat
			background-size 64px auto
	
</style>
