<template>
	<div class="settings">
		<div class="bg" v-hammer:tap="close"></div>
		<div class="content">
			<div class="title">Settings</div>

			<div class="rows">
				<div class="row">
					<div class="label">Theme</div>
					<div class="value">
						<select class="input" v-model="theme">
							<option :selected="theme === 'dark'" value="dark">Dark</option>
							<option :selected="theme === 'light'" value="light">Light</option>
							<option :selected="theme === 'red-paper'" value="red-paper">Red paper</option>
							<option :selected="theme === 'brown'" value="brown">Brown</option>
							<option v-if="app.is_ipad_theme" :selected="theme === 'purple'" value="purple">Purple</option>
						</select>
					</div>
				</div>
			</div>

			<div class="buttons">
				<div class="button" v-hammer:tap="close">Close</div>
			</div>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'settings',
	data() {
		return {
			theme: 'dark'
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
	},
	watch: {
		theme(theme) {
			if (theme) this.$store.commit('EDIT_APP_STATE', { theme })
		},
	},
	methods: {
		close() {
			this.$store.commit('EDIT_APP_STATE', { settings: false })
		}
	},
	created() {
		this.theme = localStorage.getItem('ithrive-theme') ? localStorage.getItem('ithrive-theme') : 'dark'
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.settings
	fixed 0
	z-index 500
	flex center center
	transition 0.25s easeOutQuart
	user-select none
	&.settings-enter, &.settings-leave-to
		opacity 0
		.content
			transform translateY(-24px)
	.bg
		absolute 0
		background-color var(--color-settings-bg)
	.content
		width calc(100% - 32px)
		max-width 400px
		padding 32px
		background-color var(--color-settings-content-bg)
		box-shadow 0 0 0 3px var(--color-settings-content-border),  0 0 16px 2px alpha(#000, 50%)
		color var(--color-settings-content-text)
		border-radius 4px
		transition 0.25s easeOutQuart
		.title
			margin 0 0 32px 0
			text-align center
			font-size 3.2rem
			font-family var(--josefin)
			font-weight 700
			
		.rows
			margin 0 0 32px 0
			border-top 2px solid var(--color-settings-content-row-border)
			.row
				border-bottom 2px solid var(--color-settings-content-row-border)
				margin 0 0 8px 0
				padding 16px 0
				line-height 32px
				flex space-between center
				&:last-child
					margin 0
				.value
					font-weight 700
					.input
						width 120px
						background var(--color-settings-content-row-input-bg) url(../../assets/img/chevron-down-dark.svg) right 12px center no-repeat
						background-size 20px
						padding 0 20px
						border-radius 4px
						height 40px
						border none
						font inherit
						color #000
						box-shadow 0 0 4px 0 alpha(#000, 25%)
						outline none
						appearance none
		.buttons
			flex center center
			.button
				display inline-block
				padding 12px 32px
				background-color var(--color-settings-button-bg)
				color var(--color-settings-button-text)
				border-radius 4px
				text-align center
				font-size 1.6rem
				font-weight 700
				text-transform uppercase
				cursor pointer
				&:active
					transform translateY(2px)


</style>
