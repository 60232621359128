<template>
	<div class="algorithmic-results">
		<div class="bg"></div>

		<div class="content" :style="{ height: height + 'px' }">

			<div v-if="step === 'counter'" class="step loading">
				<div class="title">Results are in</div>
				<div class="timer">
					<transition name="counter" mode="out-in" appear>
						<div v-if="counter === 2" class="counter" key="3">3</div>
						<div v-if="counter === 1" class="counter" key="2">2</div>
						<div v-if="counter === 0" class="counter" key="1">1</div>
					</transition>
				</div>
			</div>

			<transition name="role" mode="out-in" appear>
				<div v-if="step === 'roles' && current_role && current_role.player" class="step role" :key="current_role.id">
					<div class="title">
						<div class="role-title-avatar" v-bg="{ url: current_role.player.avatar }"></div>
						<div class="role-title-content">
							<div class="name">{{ current_role.name }}</div>
							<div v-if="current_role.player.student_name" class="description">({{ current_role.player.student_name }})</div>
							<div class="description">has reduced by</div>
						</div>
					</div>
					<div class="role-content">
						<div class="units">{{ current_role.unit_details.unit }} units</div>
						<div class="bar-wrap">
							<div class="label">0%</div>
							<div class="bar"><div class="progress" :style="{ width: units_percent + '%' }"></div></div>
							<div class="label">100%</div>
						</div>
					</div>
					<div class="timer">
						<svg><circle r="18" cx="20" cy="20"></circle></svg>
					</div>
				</div>
			</transition>

			<transition name="end" mode="out-in" appear>
				<div v-if="step === 'end'" class="step end">
					<div class="title">Decisions have ended for this turn</div>
					<div class="description">Review each countries participation in the stats section in your menu.</div>

					<div v-if="logged_user.type === 'player'" class="bars">
						<div v-if="personnal_answer" class="personnal-stat">
							<div class="label">Since you pledged</div>
							<div class="label units"><strong>{{ personnal_answer.unit_detail.unit }} units</strong> ({{ personnal_answer.answer.answer }})</div>
							<div class="personnal-stat-content">
								<div class="bar primary">
									<div class="progress" :style="{ width: personnal_answer.answer.answer }"></div>
								</div>
							</div>
						</div>
						<div class="personnal-stat">
							<div class="label">And the global carbon reduction total was</div>
							<div class="personnal-stat-content">
								<div class="bar">
									<div class="progress" :class="{ green: (global_units * 100 / app.algorithmic_results.question.majority_answer.unit_max) >= 80, red: (global_units * 100 / app.algorithmic_results.question.majority_answer.unit_max) < 80 }" :style="{ width: (global_units * 100 / app.algorithmic_results.question.majority_answer.unit_max) + '%' }"></div>
									<!-- <div class="separator l80"></div> -->
								</div>
								<div class="value">{{ app.algorithmic_results.question.majority_answer.unit_total }}</div>
							</div>
						</div>
						<div class="personnal-stat">
							<div class="label">Your cost amounts to</div>
							<div class="personnal-stat-content">
								<div class="bar">
									<div class="progress" :class="{ green: personnal_score <= 50, red: personnal_score > 50 }" :style="{ width: personnal_score + '%' }"></div>
									<!-- <div class="separator l50"></div> -->
								</div>
								<div class="value">{{ personnal_score }}</div>
							</div>
						</div>
					</div>

					<ui-button v-hammer:tap="goto_stats">Review</ui-button>
				</div>
			</transition>

			<!-- <div v-if="step !== 'end'" class="actions">
				<p v-if="step === 'counter'" class="description">Get ready to react by clicking these icons:</p>
				<div class="buttons">
					<div v-for="(react, index) in reacts" :key="index" class="button" :class="[ react.name, { active: react.active } ]" v-hammer:tap="() => push_react(react)">
						<div class="icon">
							<div class="default"></div>
							<transition name="active">
								<div v-show="react.active" class="active"></div>
							</transition>
						</div>
						<transition-group name="add" tag="div" class="adds">
							<div v-for="add in react.adds" :key="add.id" class="add" :style="{ left: add.x + '%', bottom: add.y + '%' }">+{{ add.value }}</div>
						</transition-group>
					</div>
				</div>
			</div> -->

		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getRandomID, random } from '@/js/utils/utils'
import UiButton from '@/components/ui/ui-button'
import Apollo from '@/apollo/apollo-client'

export default {
	name: 'algorithmic-results',
	data() {
		return {
			height: 440,
			counter: 3,
			step: 'counter',
			roles: [],
			role_index: -1,
			react_1: { name: "like", count: 0, adds: [], active: false },
			react_2: { name: "shock", count: 0, adds: [], active: false },
			react_3: { name: "sad", count: 0, adds: [], active: false },
			react_4: { name: "dislike", count: 0, adds: [], active: false },
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'logged_user' ]),
		personnal_answer() {
			if (this.logged_user) {
				const my_answer = this.app.algorithmic_results.majority_answer.details.find(d => parseInt(d.role_id) === parseInt(this.logged_user.role.id))
				if (my_answer && my_answer.answer) {
					return {
						answer: this.app.algorithmic_results.question.answer.find(a => parseInt(a.id) === parseInt(my_answer.answer)),
						unit_detail: this.app.algorithmic_results.majority_answer.unit_details.find(u => parseInt(u.role_id) === parseInt(this.logged_user.role.id)),
					}
				}
			}
			return null
		},
		reacts() {
			return [ this.react_1, this.react_2, this.react_3, this.react_4 ]
		},
		current_role() {
			const role = this.roles[this.role_index]
			if (role) {
				return { ...role, player: this.game.players.find(p => parseInt(p.role.id) === parseInt(role.id)) }
			}
			return null
		},
		units_total() {
			if (this.game.scenario_config && this.current_role) {
				const config = this.game.scenario_config.find(c => parseInt(c.id) === parseInt(this.app.algorithmic_results.question.config_id))
				if (config && config.value) {
					const value = JSON.parse(config.value)
					const decision = value.decision.find(c => c.role_id.includes(parseInt(this.current_role.id)))
					if (decision && decision.answers) {
						return decision.answers[decision.answers.length - 1].value
					}
				}
			}
			return 0
		},
		units_percent() {
			if (this.current_role && this.units_total) {
				return this.current_role.unit_details.unit * 100 / this.units_total
			}
			return 0
		},


		personnal_score() {
			const role = this.roles.find(p => parseInt(p.id) === parseInt(this.logged_user.role.id))
			if (role && role.unit_details) {
				return role.unit_details.score
			}
			return 0
		},
		global_units() {
			return this.roles.reduce((acc, p) => acc += p.unit_details && p.unit_details.unit ? p.unit_details.unit : 0, 0)
		},
	},
	watch: {
		step(step) {
			this.height = step === 'end' ? 670 : 440
		},
		current_role() {
			this.react_1 = { ...this.react_1, count: 0, adds: [], active: false }
			this.react_2 = { ...this.react_2, count: 0, adds: [], active: false }
			this.react_3 = { ...this.react_3, count: 0, adds: [], active: false }
			this.react_4 = { ...this.react_4, count: 0, adds: [], active: false }
		},
		'react_1.count'(newValue, oldValue) {
			if (newValue > oldValue) this.new_adds(this.react_1, newValue - oldValue)
		},
		'react_2.count'(newValue, oldValue) {
			if (newValue > oldValue) this.new_adds(this.react_2, newValue - oldValue)
		},
		'react_3.count'(newValue, oldValue) {
			if (newValue > oldValue) this.new_adds(this.react_3, newValue - oldValue)
		},
		'react_4.count'(newValue, oldValue) {
			if (newValue > oldValue) this.new_adds(this.react_4, newValue - oldValue)
		},
	},
	methods: {
		decrement() {
			this.counter--
			if (this.counter >= 0) setTimeout(this.decrement, 1500)
			else setTimeout(this.next_step, 1000)
		},
		next_step() {
			if (this.step === 'counter') {
				this.step = 'roles'
				this.next_role()
			}
			else if (this.step === 'roles') {
				this.step = 'end'
			}
		},
		new_adds(react, diff) {
			for (let i = 0; i < diff; i++) {
				const add = { id: getRandomID(), value: 1, x: random(80, 20), y: random(100, 0) }
				react.adds.push(add)
				setTimeout(() => {
					react.adds = react.adds.filter(a => a.id !== add.id)
				}, (500 + 200 * i))
			}
		},
		next_role() {
			this.role_index++
			if (this.role_index >= this.roles.length) {
				this.next_step()
			} else {
				setTimeout(() => {
					this.next_role()
				}, 2500)
			}
		},
		close() {
			this.$store.commit('EDIT_APP_STATE', { algorithmic_results: null })
		},
		goto_stats() {
			if (this.logged_user.type === 'player' && this.$route.name !== 'stats') this.$router.push({ name: 'stats' })
			this.close()
		},
		push_react(react) {
			if (this.current_role) {
				if (!react.active) {
					// if (!react.active) react.count += 1
					Apollo.mutate('SET_OUTCOME_STAT', {
						room_id: parseInt(this.game.room.id),
						question_id: parseInt(this.app.algorithmic_results.question.id),
						role_id: parseInt(this.current_role.id),
						stat_name: react.name,
						stat_value: 1,
					})
				}
				react.active = !react.active
			}
		},
		async pulling() {
			if (this.current_role) {
				// console.log('Pulling stats')
				const results = await Apollo.query('GET_OUTCOME_STAT', { room_id: parseInt(this.game.room.id), question_id: parseInt(this.app.algorithmic_results.question.id) })
				if (results && results.unit_details && this.current_role) {
					const result = results.unit_details.find(r => parseInt(r.role_id) === parseInt(this.current_role.id))
					if (result) {
						this.reacts.forEach((react) => {
							if (result[react.name] !== undefined) react.count = result[react.name]
						})
					}
				}
			}
			setTimeout(() => {
				this.pulling()
			}, 500)
		}
	},
	mounted() {
		this.pulling()
		// setTimeout(() => {
		this.decrement()
		// }, 500)
	},
	created() {
		if (this.app.algorithmic_results && this.app.algorithmic_results.question && this.app.algorithmic_results.question.deciders) {
			this.app.algorithmic_results.question.deciders.forEach((role_id) => {
				const role = this.game.roles.find(r => parseInt(r.id) === parseInt(role_id) && !r.tag.includes('observer'))
				if (role) {
					const obj = {
						...role,
						unit_details: this.app.algorithmic_results.majority_answer.unit_details.find(u => parseInt(u.role_id) === parseInt(role_id))
					}
					this.roles.push(obj)
				}
			})
			this.roles.sort((a, b) => {
				if (a.name < b.name) return -1
				if (a.name > b.name) return 1
				return 0
			})
			// this.roles = this.app.algorithmic_results.question.deciders.map(id => this.game.roles.find(r => parseInt(r.id) === parseInt(id)))
		}
	},
	components: { UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

.algorithmic-results
	absolute 0
	flex center center
	z-index 3
	user-select none
	&.algorithmic-results-enter-active, &.algorithmic-results-leave-active
		transition 0.2s easeOutQuart
		.bg
			transition 0.2s easeOutQuart
		.content
			transition 0.2s easeOutQuart
	&.algorithmic-results-enter, &.algorithmic-results-leave-to
		.bg
			opacity 0
		.content
			transform scale(1.1)
			opacity 0
	.bg
		absolute 0
		background-color var(--color-infobox-bg)
	.content
		max-width 700px
		width calc(100% - 64px)
		height 440px
		padding 48px 32px
		border 4px solid var(--color-infobox-content-border)
		background-color var(--color-algo-content-bg)
		border-radius 12px
		text-align center
		transition 0.25s easeOutQuart
		flex center center column

		> .step
			absolute 0
			font-family var(--josefin)
			padding 32px
			color var(--color-algo-text)
			flex center center column
			.title
				position absolute
				top 48px
				left 0
				right 0
				font-size 4rem
				font-weight 700
				text-transform uppercase
				flex center center
				text-align left
				.description
					font-size 1.6rem
					font-weight 400
					text-transform none
					color var(--color-algo-text)
					font-family var(--poppins)
				.role-title-avatar
					width 80px
					height 80px
					border-radius 50%
					margin 0 16px 0 0
					background center center no-repeat
					background-size cover
					box-shadow 0 0 4px alpha(#000, 15%)
					// background-color var(--color-infobox-content-bg)
					// filter brightness(1.5)
				.role-title-content
					max-width calc(100% - 250px)
		
			&.loading
				padding 120px 32px 32px 32px
				.timer
					// margin 0 0 80px 0
					font-size 14rem
					.counter
						&.counter-enter-active, &.counter-leave-active
							transition .5s easeOutQuart
						&.counter-enter
							opacity 0
							transform scale(2)
						&.counter-leave-to
							opacity 0
			
			&.role
				padding 120px 32px 32px 32px
				&.role-enter-active
					transition 0.5s easeOutQuart
					.title
						transition 0.5s easeOutQuart
					.role-content
						transition 0.5s easeOutQuart
						.bar-wrap .bar .progress
							width 0 !important
				&.role-enter
					.title
						transform scale(1.4)
						opacity 0
					.role-content
						transform scale(1.4)
						opacity 0
				.role-content
					flex center center column
					.units
						margin 0 0 8px 0
						font-size 6.4rem
						font-weight 700
						text-transform uppercase
					.bar-wrap
						flex center center
						.label
							margin 0 12px
						.bar
							width 300px
							height 12px
							border-radius 16px
							background-color var(--color-algo-progress-bar-bg)
							overflow hidden
							-webkit-mask-image -webkit-radial-gradient(white, black)
							.progress
								position absolute
								left 0
								top 0
								height 100%
								background-color var(--color-algo-progress-bar-content)
								transition width 0.5s easeOutQuart
				> .timer
					position absolute
					right 8px
					top 8px
					width 40px
					height 40px
					svg
						position absolute
						top 0
						right 0
						width 40px
						height 40px
						transform rotateY(-180deg) rotateZ(-90deg)
						circle
							stroke-dasharray 113px
							stroke-dashoffset 0px
							stroke-linecap round
							stroke-width 6px
							stroke var(--color-infobox-content-border)
							fill none
							animation countdown 2.5s linear infinite forwards
							transform scale(0.5)
							transform-origin center center
			
			&.end
				transition 0.5s easeOutQuart
				&.end-enter
					opacity 0
				.title
					position relative
					top auto
					left auto
					right 0
					margin 0 0 8px 0
					font-size 2.8rem
				.description
					margin 0 0 32px 0
					font-size 1.6rem
					font-family var(--poppins)
					color var(--color-algo-text)

		.actions
			position absolute
			left 0
			right 0
			bottom 48px
			.description
				margin 0 0 16px 0
				font-size 1.6rem
				color var(--color-algo-text)
			.buttons
				flex center center
				.button
					height 64px
					width 64px
					// background-color var(--color-infobox-content-border)
					margin 0 16px
					flex center center
					cursor pointer
					color var(--color-algo-text)
					font-weight 700
					line-height 1em
					&.active .icon .default
						opacity 0
					&.like
						.icon .default
							background-image url(../assets/img/algorithmic-results/like.svg)
						.icon .active
							background-image url(../assets/img/algorithmic-results/like-active.svg)
					&.sad
						.icon .default
							background-image url(../assets/img/algorithmic-results/sad.svg)
						.icon .active
							background-image url(../assets/img/algorithmic-results/sad-active.svg)
					&.shock
						.icon .default
							background-image url(../assets/img/algorithmic-results/shock.svg)
						.icon .active
							background-image url(../assets/img/algorithmic-results/shock-active.svg)
					&.dislike
						.icon .default
							background-image url(../assets/img/algorithmic-results/dislike.svg)
						.icon .active
							background-image url(../assets/img/algorithmic-results/dislike-active.svg)
					.icon
						absolute 0
						> .default, > .active
							absolute 0
							background center center no-repeat
						.active-enter-active
							transition 0.25s easeOutBack
						.active-enter
							transform scale(2)
							opacity 0
						.active-leave-active
							transition 0.1s easeOutQuart
						.active-leave-to
							opacity 0
					.nb
						position absolute
						right -12px
						top -12px
						width 32px
						height 32px
						flex center center
						border-radius 50%
						background-color red
						border 2px solid var(--color-infobox-content-bg)
					.adds
						position absolute
						bottom calc(100% + 8px)
						left 0
						right 0
						height 24px
						.add
							position absolute
							left calc(50% - 12px)
							bottom 0
							width 24px
							height 24px
							transition 0.5s easeOutQuart
							color var(--color-algo-plus-one)
							font-size 2rem
							transform translate(-50%, 0)
							for i in 1..20
								&:nth-child({ i })
									transition-delay (0.2s * (i - 1))
							&.add-enter
								transform translate(-50%, 16px)
								opacity 0
							&.add-leave-to
								transform translate(-50%, -16px)
								opacity 0
		.bars
			// flex center center column
			// margin 0 0 32px 0
			border-top 2px solid var(--color-algo-text)
			width 100%
			padding-top 16px
			.personnal-stat
				width 100%
				margin 0 0 16px 0
				padding 24px
				border-radius 8px
				color var(--color-algo-personnal-stat-text)
				// background-color var(--color-algo-personnal-stat-bg)
				&:first-child
					margin-left 0
				&:last-child
					margin-right 0
					border-right none
				.label
					display block
					margin 0 0 16px 0
					text-transform uppercase
					font-size 2rem
					font-weight 400
					font-family var(--josefin)
					&.units
						font-size 3.2rem
						margin 0 0 8px 0
				.personnal-stat-content
					flex flex-start flex-start
					.bar
						height 24px
						width calc(100% - 48px)
						// border 1px solid
						box-shadow inset 0 0 0 1px var(--color-algo-personnal-stat-bar-border)
						border-radius 24px
						overflow hidden
						-webkit-mask-image -webkit-radial-gradient(white, black)
						&.primary
							box-shadow none
							width 100%
							height 16px
							background-color var(--color-algo-personnal-stat-bar-primary-bg)
							.progress
								background-color var(--color-algo-personnal-stat-bar-primary-progress)
						.progress
							height @height
							&.green
								background-color var(--color-stats-meter-progress-green)
							&.red
								background-color var(--color-stats-meter-progress-red)
								
						.separator
							position absolute
							top 25%
							height 50%
							width 1px
							background-color var(--color-algo-personnal-stat-bar-border)
							&.l50
								left 50%
							&.l80
								left 80%
					.value
						width 48px
						height 24px
						line-height @height
						text-align right
						font-size 2.4rem
						white-space nowrap
						
@keyframes countdown
	from
		stroke-dashoffset 0px
	to
		stroke-dashoffset 113px
		
</style>
