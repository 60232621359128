import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'
import { slugify } from '@/js/utils/utils'
import { Medias } from '@/js/utils/medias'
import router from '@/router'
import Pulling from '@/js/pulling'



// === STATE =====================================================================================
const state = {
	user: false
}








// === GETTERS =====================================================================================
const getters = {
	logged_user(state) {
		return state.user
	},
	current_journal(state) {
		if (state.user.role && state.user.role.tag)
			return state.user.role.tag.find((t) => { return [ 'the-buzz', 'capitol-news', 'world-news-network', 'usa-news', 'tri-force-news', 'star-news', 'nola-post', 'the-white-house' ].includes(t) })
		return false
	},
}







// === ACTIONS =====================================================================================
const actions = {

	async login({ commit, dispatch }, data) {
		let r = { status: 'error', message: '', field: false }

		commit('EDIT_APP_STATE', { loading: true, main_loader: true })
		
		if (data.type === 'player' && data.room_code && data.student_number) {
			let room = await Apollo.query('GET_ROOM_BY_PIN', { pin: data.room_code })
			// const active_rooms = await Apollo.query('GET_ACTIVE_ROOM')
			// let room = active_rooms.find((room) => { return room.pin === data.room_code })

			if (room) {
				const players = await Apollo.query('GET_PLAYER_BY_ROOM', { room_id: room.id })
				const current_player = players.find(p =>
					(p.student_number && p.student_number.toLowerCase() === data.student_number.toLowerCase().trim()) ||
					(p.student_email && p.student_email.toLowerCase() === data.student_number.toLowerCase().trim())
				)
				if (current_player) {
					let user = { ...current_player }
					// let user = await Apollo.query('GET_PLAYER', { id: current_player.id })
					if (user) {
						await Medias.init({ scenario: room.scenario_id })

						let scenario = null
						const scenarios = await Apollo.query('ALL_SCENARIOS')
						if (scenarios && scenarios.length) {
							scenario = scenarios.find((s) => parseInt(s.id) === parseInt(room.scenario_id))
						}

						commit('EDIT_GAME_STATE', { players, room, scenario })
						let theme = room.episode.theme
						if (theme === 'red') theme = 'red-paper'
						commit('EDIT_APP_STATE', { theme, is_ipad_theme: theme === 'purple' })

						localStorage.setItem('sre-user', btoa(JSON.stringify({ created: new Date().getTime(), type: data.type, room_code: data.room_code, student_number: data.student_number })))

						try {
							if (user.video_status) user.video_status = JSON.parse(atob(user.video_status))
							if (user.infobox_status) user.infobox_status = JSON.parse(atob(user.infobox_status))
						} catch (e) {
							console.error('error json parse video_status/infobox_status', e)
						}

						// Load questions
						let selected_answers = []
						let questions = []
						// if (user.data) {
						// 	try {
						// 		const user_data = JSON.parse(atob(user.data))
						// 		if (user_data) selected_answers = Object.values(user_data).map((a) => { return a.answer_id })
						// 	} catch (e) {
						// 		console.error("Unable to parse user.data", e)
						// 	}
						// }
						if (user.role) {
							questions = await Apollo.query('ALL_QUESTIONS_BY_ROLES', { role_id: user.role.id, scenario_id: scenario.id })
							if (questions.length > 0) {
								questions = questions.filter((q) => { return q.day === room.episode.episode_number }).sort((a, b) => {
									if (a.timer > b.timer) return 1
									if (a.timer < b.timer) return -1
									return 0
								})
							}
						}

						try {
							if (localStorage.getItem('selected_answers_' + room.id)) {
								selected_answers = JSON.parse(localStorage.getItem('selected_answers_' + room.id))
							}
						} catch (e) {
							console.error("Unable to parse localStorage selected_answers", e)
						}
						commit('EDIT_PROGRESS_STATE', { questions, selected_answers })
						
						// Save user
						user.id = parseInt(user.id)
						user.type = 'player'
						if (user.role) user.role.slug = slugify(user.role.name)
						delete user.data
						commit('EDIT_USER_STATE', { user })

						if (room.state === 'ending') router.push({ path: '/game/summary' })
						else if (router.currentRoute.name !== 'game') router.push({ path: '/game' })

						// if (process.env.NODE_ENV === 'development') dispatch('load_all_questions_by_role', { user })

						await Promise.all([
							dispatch('load_roles', { scenario }),
							dispatch('load_player_files'),
							dispatch('load_chirps'),
							dispatch('load_info_boxes', { scenario }),
							dispatch('load_polling_results'),
							dispatch('load_scenario_config'),
							dispatch('load_outcomes', { room }),
							dispatch('update_unit_details'),
						])

						Pulling.start()

						r.status = 'success'
						r.message = ''
					} else {
						r.message = "Student does not exist"
						r.field = 'student_number'
					}
				} else {
					r.message = "Student does not exist"
					r.field = 'student_number'
				}
			} else {
				r.message = "Room code is incorrect"
				r.field = 'room_code'
			}

		}

		else if (data.type === 'game-master' && data.username && data.password) {
			commit('EDIT_APP_STATE', { theme: 'dark' })

			const user = await Apollo.query('LOGIN_GAME_MASTER', { username: data.username, password: data.password })
			if (user && user.username) {
				localStorage.setItem('sre-user', btoa(JSON.stringify({ created: new Date().getTime(), type: data.type, username: data.username, password: data.password })))
				// user.restricted = false
				commit('EDIT_USER_STATE', { user: { id: parseInt(user.id), username: data.username, type: data.type, restricted: user.restricted } })
				router.push({ path: '/master' })

				await Promise.all([
					dispatch('load_roles'),
					// dispatch('load_players'),
				])

				let scenarios = await Apollo.query('ALL_SCENARIOS')
				if (!user.restricted) {
					scenarios = scenarios.filter((s) => !s.restricted)
				}
				commit('EDIT_MASTER_STATE', { scenarios })

				r.status = 'success'
				r.message = ''
			} else {
				r.message = "Login is incorrect"
				r.field = 'username'
			}
		}

		commit('EDIT_APP_STATE', { loading: false, main_loader: false })

		return r
	},


	update_video_status({ commit, getters }, data) {
		if (data.key) {
			let video_status = { ...getters.logged_user.video_status }
			if (!video_status) video_status = {}
			video_status[data.key] = data.value
			commit('EDIT_USER_INFO', { video_status })
			Apollo.mutate('UPDATE_PLAYER_VIDEO', { player_id: getters.logged_user.id, key: data.key, value: data.value })
		}
	},


	update_infobox_status({ commit, getters }, data) {
		if (data.key) {
			let infobox_status = { ...getters.logged_user.infobox_status }
			if (!infobox_status) infobox_status = {}
			infobox_status[data.key] = data.value
			commit('EDIT_USER_INFO', { infobox_status })
			Apollo.mutate('UPDATE_PLAYER_INFOBOX', { player_id: getters.logged_user.id, key: data.key, value: data.value })
		}
	},


	logout({ commit, getters, rootGetters }, data) {
		commit('EDIT_CHIRP_STATE', { feed: [], messages: [], stories: [], screen: 'home', visible: false })
		commit('EDIT_FILES_STATE', { documents: [], images: [] })
		commit('EDIT_USER_STATE', { user: false })
		commit('EDIT_PROGRESS_STATE', { act: { visible: false, closable: true }, questions: [], selected_question: false, selected_answer: false })
		commit('EDIT_GAME_STATE', { players: [], roles: [], room: false, scenario_config: null, time: { remaining: 0, total: 0 } })
		commit('EDIT_APP_STATE', { algorithmic_results: null })
		localStorage.removeItem('ithrive-text-chirp')
		localStorage.removeItem('ithrive-text-news-title')
		localStorage.removeItem('ithrive-text-news-story')
		localStorage.removeItem('sre-user')
		localStorage.removeItem('sre-first-solo-launch')
		router.push({ path: '/login' })
	},



}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_USER_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	EDIT_USER_INFO(state, data) {
		for (var i in data) {
			Vue.set(state.user, i, data[i])
		}
	}

}

export default { state, getters, actions, mutations }
