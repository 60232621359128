<template>
	<div class="main-bar" :class="[ logged_user.type + '-bar', { 'two-meters': is_co2_scenario && logged_user.type === 'player' } ]">

		<div v-if="logged_user.type === 'game-master' && game.room" class="code">
			<span class="label">Access code :</span>
			<span class="value">{{ game.room.pin }}</span>
			<div class="url" v-hammer:tap="() => copy(room_login_url)">
				<div class="url-label">Student access :</div>
				<input class="url-value" id="room-login-url" :value="room_login_url" readonly>
				<transition name="copied">
					<div v-show="copied" class="copied">Copied!</div>
				</transition>
			</div>
		</div>

		<div v-if="is_jury_duty_scenario" class="decision">
			<div v-if="current_question_endless_timer" class="decision-content">
				<ui-icon class="hourglass" name="hourglass" :size="32" />
				<div class="label">Awaiting next decision</div>
				<div class="decision-bar">
					<div class="progress" :style="{ transform: 'translateX(' + (-decision_progress_percent_endless) + '%)' }"></div>
				</div>
				<div class="remaining-time">{{ remaining_time_endless }}</div>
			</div>
		</div>

		<div v-else class="decision">
			<div v-if="current_question" class="decision-content">
				<ui-icon class="hourglass" name="hourglass" :size="32" />
				<div class="label">Current Decision</div>
				<div class="decision-bar">
					<div class="progress" :style="{ transform: 'translateX(' + (-decision_progress_percent) + '%)' }"></div>
				</div>
				<div class="remaining-time">{{ remaining_time }}</div>
			</div>
		</div>

		<div v-if="is_co2_scenario && logged_user.type === 'player'" class="meter">
			<div v-if="game.scenario && game.scenario.meter_status && !app.algorithmic_results" class="public-satisfaction">
				<p class="label">My country's annual cost</p>
				<div class="value">
					<div class="bar">
						<div class="progress" :class="{ green: personnal_score <= 50, red: personnal_score > 50 }" :style="'width:' + personnal_score + '%'"></div>
						<div class="ticks">
							<div class="tick t25"></div>
							<div class="tick t50"></div>
							<div class="tick t75"></div>
						</div>
					</div>
					<div v-if="game.room && game.room.meter_label" class="percent">{{ personnal_score }}</div>
					<div v-else class="percent"></div>
				</div>
			</div>
		</div>

		<div class="meter">
			<div v-if="game.scenario && game.scenario.meter_status && !app.algorithmic_results" class="public-satisfaction">
				<p class="label">{{ game.scenario.meter_label }}</p>
				<div class="value">
					<div class="bar">
						<div v-if="!is_co2_scenario" class="progress" :style="'width:' + public_satisfaction + '%'"></div>
						<div v-else class="progress" :class="{ green: public_satisfaction >= 80, red: public_satisfaction < 80 }" :style="'width:' + public_satisfaction + '%'"></div>
						<div class="ticks">
							<div class="tick t25"></div>
							<div class="tick t50"></div>
							<div class="tick t75"></div>
						</div>
					</div>
					<div v-if="game.room && game.room.meter_label" class="percent">{{ game.room.meter_label.replace('None', '') }}</div>
					<div v-else class="percent"></div>
				</div>
			</div>
		</div>

		<div class="timer" :class="[ game.room.clock_option, 'user-' + logged_user.type ]">
			<p v-if="game.room.clock_option !== 'hidden' || (game.room.clock_option === 'hidden' && logged_user.type === 'game-master')" class="label">
				<span v-if="game.room.clock_option === 'timer'">Sim Clock:</span>
				<span v-else>Time</span>
			</p>
			<p v-if="game.room.clock_option !== 'hidden' || (game.room.clock_option === 'hidden' && logged_user.type === 'game-master')" class="value" :class="{ ending: game.room.timer <= 30 && game.room.timer > 0 }">{{ formated_time }}</p>
			<transition name="clock-alert">
				<div v-if="game.room.clock_option !== 'hidden' && game.room.timer <= 30 && game.room.timer > 0" class="clock-alert">{{ game.room.timer }} seconds left</div>
			</transition>
		</div>

		<div v-if="game.room.clock_option === 'timer' && game.room.state !== 'ending' && is_solo_scenario && logged_user.type !== 'game-master'" id="timer-paused-button" class="timer-paused-button" :class="{ 'pause': pause_button_str === 'Pause', loading, 'on-top': $route.name !== 'summary' }" v-hammer:tap="toggle_pause">
			<span class="timer-paused-button-text">{{ pause_button_str }} scenario</span>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { wait } from '@/js/utils/utils'

export default {
	name: 'main-bar',
	data() {
		return {
			loading: false,
			copied: false,
			temp_game_state: null
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'logged_user', 'current_question', 'current_question_endless_timer', 'current_question_conversation', 'progress', 'chirp' ]),
		isPaused() {
			return this.game.room.state === 'paused'
		},
		pause_button_str() {
			if (this.temp_game_state) {
				return this.temp_game_state === 'paused' ? 'Resume' : 'Pause'
			} else {
				return this.isPaused ? 'Resume' : 'Pause'
			}
		},
		is_solo_scenario() {
			return [ 12, 22, 9, 21, 24 ].includes(parseInt(this.game.scenario.id))
		},
		is_co2_scenario() {
			return this.game.scenario && this.game.scenario.enable_stats
		},
		is_jury_duty_scenario() {
			return parseInt(this.game.scenario.id) === 24
		},
		room_login_url() {
			if (this.game.room) {
				return window.location.protocol + "//" + window.location.host + "/" + window.location.pathname.split('/')[1] + '#/login/' + this.game.room.pin
			}
			return ''
		},
		formated_time() {
			// if (this.game.room) return this.game.room.timer + ' / ' + this.game.room.timer_total
			if (this.game.room) return this.game.room.game_clock
			return ''
		},
		remaining_time() {
			if (this.game.room && this.current_question) {
				let t = this.current_question.timeout - (this.game.room.timer_total - (this.game.room.timer + this.current_question.timer))
				let minutes = Math.floor(t / 60)
				let seconds = t - minutes * 60
				return ('0' + minutes).slice(-2) + ' : ' + ('0' + seconds).slice(-2)
			}
			return '00 : 00'
		},
		decision_progress_percent() {
			if (this.game.room && this.current_question) {
				return ((this.game.room.timer_total - (this.game.room.timer + this.current_question.timer)) * 100 / this.current_question.timeout)
			}
			return 0
		},
		decision_progress_percent_endless() {
			if (this.game.room && this.current_question_endless_timer) {
				return ((this.game.room.timer_total - (this.game.room.timer + this.current_question_endless_timer.timer)) * 100 / this.current_question_endless_timer.timeout)
			}
			return 0
		},
		remaining_time_endless() {
			if (this.game.room && this.current_question_endless_timer) {
				let t = this.current_question_endless_timer.timeout - (this.game.room.timer_total - (this.game.room.timer + this.current_question_endless_timer.timer))
				let minutes = Math.floor(t / 60)
				let seconds = t - minutes * 60
				return ('0' + minutes).slice(-2) + ' : ' + ('0' + seconds).slice(-2)
			}
			return '00 : 00'
		},
		public_satisfaction() {
			if (this.game.room && this.game.room.public_opinion) {
				return this.game.room.public_opinion
			}
			return 0
		},
		selected_answer() {
			if (this.current_question && this.current_question.answer) {
				return this.current_question.answer.find(a => this.progress.selected_answers.includes(parseInt(a.id)))
			}
			return null
		},
		last_question() {
			const questions = this.progress.questions.filter(q => q.majority_answer && parseInt(q.day) === parseInt(this.game.room.episode.episode_number))
			if (questions.length) {
				questions.sort((a, b) => a.timer > b.timer ? 1 : a.timer < b.timer ? -1 : 0)
				return questions[questions.length - 1]
			}
			return null
		},
		personnal_score() {
			if (this.logged_user.type === 'player' && this.last_question && this.progress.unit_details && this.progress.unit_details[this.last_question.id] && this.progress.unit_details[this.last_question.id].unit_details) {
				const detail = this.progress.unit_details[this.last_question.id].unit_details.find(u => parseInt(u.role_id) === parseInt(this.logged_user.role.id))
				if (detail) return detail.score
			}
			return 0
		},
	},
	watch: {
		remaining_time() {
			if (this.logged_user.type === 'player' && this.current_question && !this.selected_answer) {
				const t = this.current_question.timeout - (this.game.room.timer_total - (this.game.room.timer + this.current_question.timer))
				if (t <= 15) {
					if (this.$route.name !== 'decisions') this.$router.push({ path: '/game/decisions' })
					if (this.chirp.route) this.$store.commit('EDIT_CHIRP_STATE', { route: null })
				}
			}
		},
		'current_question.id'(newValue) {
			if (newValue && this.current_question && this.current_question.id && this.current_question.question) {
				this.$store.dispatch('add_info_box', {
					info_box: {
						id: 'question-' + this.current_question.id,
						title: "A new decision is available",
						description: this.current_question.question,
						type: 'decision'
					}
				})
			}
		},
		'current_question_conversation.id'(newValue) {
			if (newValue && this.current_question_conversation && this.current_question_conversation.id && this.current_question_conversation.question) {
				if (! (this.chirp.route && this.chirp.route.params && this.chirp.route.params.player && this.chirp.route.params.player.role && this.current_question_conversation.sender_id_id &&
						this.chirp.route.name === 'conversation-question' && this.chirp.route.params.group === false && parseInt(this.chirp.route.params.player.role.id) === parseInt(this.current_question_conversation.sender_id_id)
					)
				) {
					this.$store.dispatch('add_info_box', {
						info_box: {
							id: 'question-' + this.current_question_conversation.id,
							title: "A new decision is available",
							description: this.current_question_conversation.question,
							type: 'decision'
						}
					})
				}
			}
		}
	},
	methods: {
		async toggle_pause() {
			if (!this.loading) {
				this.loading = true
				this.temp_game_state = this.game.room.state === 'running' ? 'paused' : 'running'
				let videoRef = document.querySelector('.fullscreen-video video')
				if (!videoRef) videoRef = document.querySelector('.video-player video')
				if (this.temp_game_state === 'running') {
					if (videoRef) videoRef?.play()
					await this.$store.dispatch('restart_game_session', { room_id: this.game.room.id })
				} else {
					if (videoRef) videoRef?.pause()
					await this.$store.dispatch('change_room_state', { room_id: this.game.room.id, state: this.temp_game_state })
				}
				await wait(3000)
				this.temp_game_state = null
				this.loading = false
			}
		},
		show_act_window() {
			if (this.current_question) {
				this.$store.dispatch('set_progress_act', { visible: true, closable: true })
			}
		},
		copy() {
			var copyText = document.querySelector("#room-login-url")
			copyText.select()
			document.execCommand("copy")
			this.copied = true
			setTimeout(() => {
				this.copied = false
			}, 2000)

		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


#app.ipad-theme
	.main-bar
		background alpha(#fff, 10%)
		backdrop-filter blur(10px)
	@media (max-height: 750px)
		.main-bar
			background none
			backdrop-filter none
			pointer-events none
			.timer-paused-button
				pointer-events auto

#app.desktop
	.timer-paused-button
		position fixed
		top 16px
		left -8px
		width auto
		background alpha(#000, 20%) url('../assets/img/main-bar/play.svg') left 16px center no-repeat
		flex center center
		padding 8px 16px 8px 48px
		border-radius 0 8px 8px 0
		text-transform uppercase
		font-weight 600
		background-size 24px
		transition transform 0.15s easeOutQuart
		cursor pointer
		z-index 10000000
		&:hover:not(.loading)
			background-color alpha(#000, 20%)
			transform translateX(4px)
		&.loading
			cursor wait
		&.pause
			background-image url('../assets/img/main-bar/pause.svg')
		.timer-paused-button-text
			display block
		

#app.tablet-horizontal, #app.tablet-vertical
	.main-bar.player-bar
		width 100%
		gap 0px 0px
		padding 8px 0
		display grid
		grid-template-columns 170px calc(100% - 334px) 100px 64px
		grid-template-rows 1fr
		&.two-meters
			grid-template-rows 1fr 1fr
		.decision
			&:before
				content ''
				position absolute
				right -2px
				top 8px
				bottom 8px
				width 2px
				background-color var(--color-bottom-bar-border)
		.meter
			width 100%
			max-width 600px
			flex center center
			&:first-child
				grid-area 1 / 2 / 2 / 3
			&:last-child
				grid-area 2 / 2 / 3 / 3
			&:before
				display none
			.public-satisfaction
				flex center center
				.label
					display inline-block
					width 65%
					padding-right 16px
					text-align right
				.value
					flex flex-start center
					width 35%
					margin 0
					.percent
						padding-top 4px
		.timer
			grid-area 1 / 3 / 3 / 4

#app.tablet-horizontal, #app.tablet-vertical, #app.mobile
	.main-bar.player-bar
		.code
			display none
		.decision
			grid-area 1 / 1 / 3 / 2
			.decision-content
				flex center flex-start column
				padding 0 16px
			>>> .ui-icon, .decision-bar
				display none
			.label, .remaining-time
				position relative
				bottom auto
				left auto
				right auto
				font-size 1.3rem
				line-height 1.2em
			.remaining-time
				margin 4px 0 0 0
				font-size 1.6rem

#app.mobile
	.main-bar
		bottom auto
		top 0
		height 64px
		.decision
			width 80px
			.decision-content
				padding 0 8px
			.label, .remaining-time
				font-size 1rem !important
			.remaining-time
				font-size 1.2rem
		.meter
			width 100%
			flex-shrink 1
			padding 0 12px
			font-size 1rem
			.value
				margin 4px 0 0 0
				.percent
					font-size 1.2rem
		.timer
			padding-right 12px
			min-width 52px
			width 80px
			white-space nowrap
			&:before
				content ''
				position absolute
				left -2px
				top 8px
				bottom 8px
				width 2px
				background-color var(--color-bottom-bar-border)
			&.hidden.user-player
				display none
			.label
				font-size 1rem
			.value
				font-size 1.2rem
		.timer-paused-button
			width 32px
			height 32px
			

.main-bar
	position absolute
	left 0
	right 0
	bottom 0
	width 100%
	height 80px
	background-color var(--color-bottom-bar-bg)
	font-family var(--josefin), sans-serif
	flex flex-start center
	// user-select none
	&.game-master-bar
		left 0
		width 100%
	
	.decision
		height 100%
		width 100%
		font-size 1.3rem
		.decision-content
			height 100%
			width 100%
		.label
			position absolute
			bottom 36px
			left 64px
			line-height 24px
			text-transform uppercase
			color var(--color-bottom-bar-text)
		.hourglass
			position absolute
			left 16px
			bottom calc(50% - 18px)
			height 32px
			width 32px
			color var(--color-bottom-bar-decision-bar-hourglass)
			// background url(../assets/img/hourglass.svg) center center no-repeat
			// background-size auto 100%
		.remaining-time
			position absolute
			bottom 36px
			right 32px
			line-height 24px
			text-transform uppercase
			color var(--color-bottom-bar-text)
		.decision-bar
			position absolute
			bottom 20px
			left 64px
			width calc(100% - 96px)
			height 12px
			background-color var(--color-bottom-bar-decision-bar-bg)
			border-radius 8px
			-webkit-mask-image: -webkit-radial-gradient(white, black);
			overflow hidden
			.progress
				position absolute
				left 0
				top 0
				width 100%
				height @height
				background-color var(--color-bottom-bar-decision-bar-progress)
				// border-radius 8px
				transition transform 1s linear

	.code
		flex center center
		flex-shrink 1
		height 100%
		padding 8px 16px 0 16px
		font-size 1.2rem
		color var(--color-bottom-bar-text)
		.label
			text-transform uppercase
		.value
			font-size 2.4rem
			margin-left 16px
			font-weight 700
		.url
			margin 0 0 0 16px
			.url-label
				position absolute
				left 0
				bottom calc(100% + 2px)
				opacity 0.5
			.url-value
				height 32px
				width 250px
				padding 10px 12px 8px 12px
				border none
				background-color var(--color-bottom-bar-url-bg)
				color var(--color-bottom-bar-url-text)
				cursor pointer
				// box-shadow inset 1px 1px 0 0 alpha(#000, 15%)
				font inherit
				color inherit
				border-radius 4px
				&:hover, &:focus
					background-color var(--color-bottom-bar-url-bg)
			.copied
				position absolute
				left 50%
				bottom calc(100% + 4px)
				height 32px
				padding 0 16px
				background-color var(--color-bottom-bar-copied)
				text-align center
				line-height 36px
				font-size 1.6rem
				border-radius 4px
				transform translateX(-50%)
				// text-transform uppercase
				font-family var(--josefin), sans-serif
				transition 0.25s easeOutQuart
				&.copied-enter, &.copied-leave-to
					opacity 0
					transform translate(-50%, 4px)

	.meter
		width calc(418px - 100px)
		height 100%
		padding 0 16px
		flex-shrink 0
		flex flex-start center
		// display flex
		color var(--color-bottom-bar-text)
		line-height 20px
		font-size 1.3rem
		line-height 1.2em
		text-transform uppercase
		&:before
			content ''
			position absolute
			left -2px
			top 8px
			bottom 8px
			width 2px
			background-color var(--color-bottom-bar-border)
		.blink
			color var(--color-bottom-bar-timer-alert-bg)
			font-weight 700
			animation blink 3s infinite linear
		.public-satisfaction
			width 100%
			.value
				flex center flex-start
				height 12px
				margin 8px 0 0 0
				line-height @height
				white-space nowrap
				.bar
					height 100%
					width calc(100% - 40px)
					border-radius 40px
					background-color var(--color-bottom-bar-meter-bg)
					overflow hidden
					-webkit-mask-image: -webkit-radial-gradient(white, black);
					.progress
						position absolute
						left 0
						top 0
						bottom 0
						// border-top-left-radius 40px
						// border-bottom-left-radius 40px
						background linear-gradient(to left, var(--color-bottom-bar-meter-progress-1), var(--color-bottom-bar-meter-progress-2))
						&.green
							background var(--color-bottom-bar-meter-green)
						&.red
							background var(--color-bottom-bar-meter-red)
					.ticks
						position absolute
						left 0
						bottom 0
						right 0
						height 6px
						.tick
							position absolute
							bottom 0
							width 2px
							height 100%
							margin-left -1px
							background-color var(--color-bottom-bar-bg)
							&.t25
								left 25%
							&.t50
								left 50%
							&.t75
								left 75%
				.percent
					width 40px
					padding-left 12px
					text-align left
					font-size 1.2rem
					white-space nowrap
	
	.timer
		width 100px
		height 100%
		padding 4px 16px 0 0
		flex center flex-end column
		flex-shrink 0
		color var(--color-bottom-bar-text)
		text-transform uppercase
		user-select none
		white-space nowrap
		.label
			display block
			margin 0 0 4px 0
			font-size 1.2rem
			// opacity 0.5
			&.ending
				color var(--color-bottom-bar-timer-alert-bg)
				animation blink 3s infinite linear
		.value
			font-size 1.8rem
			// opacity 0.5
			text-align right
		.clock-alert
			position absolute
			right 8px
			bottom calc(100% + 12px)
			background-color var(--color-bottom-bar-timer-alert-bg)
			padding 12px 16px 10px 16px
			border-radius 8px
			text-transform none
			color var(--color-bottom-bar-timer-alert-text)
			transition 0.25s easeOutBack
			z-index 2
			white-space nowrap
			&.clock-alert-enter
				transform translateY(16px)
				opacity 0
			&.clock-alert-leave-to
				transform translateY(-16px)
				opacity 0
			&:before
				content ''
				position absolute
				right 24px
				bottom -8px
				width 0
				height 0
				border-style solid
				border-width 8px 8px 0 8px
				border-color var(--color-bottom-bar-timer-alert-bg) transparent transparent transparent

	.timer-paused-button
		width 40px
		height 40px
		margin 0 8px 0 0
		flex-shrink 0
		text-align center
		background url('../assets/img/main-bar/play.svg') center center no-repeat
		background-color alpha(#000, 10%)
		background-size 50%
		border-radius 50%
		cursor pointer
		&.on-top
			z-index 10000000
		&.pause
			background-image url('../assets/img/main-bar/pause.svg')
		&:hover
			background-color alpha(#000, 20%)
		&.loading
			cursor wait
			opacity 0.25
		.timer-paused-button-text
			display none

		
@keyframes blink
	0%, 100%
		opacity 1
	25%, 75%
		opacity 0
	50%
		opacity 1


</style>
