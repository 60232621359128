import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'



// === STATE =====================================================================================
const state = {
	questions: [],
	selected_question: false,
	selected_answers: [],
	majority_pulling: null,
	unit_details: null,
	act: {
		visible: false,
		closable: true
	}
}








// === GETTERS =====================================================================================
const getters = {
	progress(state) { return state },
	questions(state, rootGetters) {
		return state.questions.filter((q) => q.question_category === 'CO' || rootGetters.game.room.questions.includes(parseInt(q.id)))
	},
	current_question(state, getters, rootGetters) {
		// return getters.questions[0]
		if (rootGetters.game.room && state.questions.length > 0) {
			// const questions = state.questions.filter((q) => q.question_category === 'CO' || rootGetters.game.room.questions.includes(parseInt(q.id)))
			const t = rootGetters.game.room.timer_total - rootGetters.game.room.timer
			return getters.questions.find((q) => {
				return q.timer < t && q.timer + q.timeout > t &&
					!q.direct_message &&
					// (
					// 	(q.question_category === 'CO') ||
					// 	(q.question_category === 'OP' && rootGetters.game.room.questions && rootGetters.game.room.questions.includes(parseInt(q.id)))
					// ) &&
					(
						(q.type !== 'Solo') ||
						(!state.selected_answers || q.answer.findIndex((a) => { return state.selected_answers.includes(parseInt(a.id)) }) === -1)
					)
			})
		}
		return null
	},
	current_question_endless_timer(state, getters, rootGetters) {
		if (rootGetters.game.room && state.questions.length > 0) {
			const t = rootGetters.game.room.timer_total - rootGetters.game.room.timer
			return getters.questions.find((q) => {
				return q.timer < t && q.timer + q.timeout > t && !q.direct_message
			})
		}
		return null
	},
	current_question_conversation(state, getters, rootGetters) {
		// return getters.questions[0]
		if (rootGetters.game.room && state.questions.length > 0) {
			// const questions = state.questions.filter((q) => q.question_category === 'CO' || rootGetters.game.room.questions.includes(parseInt(q.id)))
			const t = rootGetters.game.room.timer_total - rootGetters.game.room.timer
			return getters.questions.find((q) => {
				return q.timer < t && q.timer + q.timeout > t && q.direct_message && q.sender_id_id &&
					// (
					// 	(q.question_category === 'CO') ||
					// 	(q.question_category === 'OP' && rootGetters.game.room.questions && rootGetters.game.room.questions.includes(parseInt(q.id)))
					// ) &&
					(
						(q.type !== 'Solo') ||
						(!state.selected_answers || q.answer.findIndex((a) => { return state.selected_answers.includes(parseInt(a.id)) }) === -1)
					)
			})
		}
		return null
	},
	current_question_conversation_endless_timer(state, getters, rootGetters) {
		// return getters.questions[0]
		if (rootGetters.game.room && state.questions.length > 0) {
			// const questions = state.questions.filter((q) => q.question_category === 'CO' || rootGetters.game.room.questions.includes(parseInt(q.id)))
			const t = rootGetters.game.room.timer_total - rootGetters.game.room.timer
			return getters.questions.find((q) => {
				return q.timer < t && q.timer + q.timeout > t && q.direct_message && q.sender_id_id
			})
		}
		return null
	},
	selected_question_config(state, getters, rootGetters) {
		if (state.selected_question && state.selected_question.id && state.selected_question.type === 'Algorithmic' && rootGetters.game.scenario_config) {
			const config = rootGetters.game.scenario_config.find(c => parseInt(c.id) === parseInt(state.selected_question.config_id))
			if (config && config.value) {
				const value = JSON.parse(config.value)
				return {
					question_id: state.selected_question.id,
					units: value.decision.find(c => c.role_id.includes(parseInt(rootGetters.user.user.role.id))),
					outcome: value.outcome
				}
			}
			return { question_id: null, units: [], outcome: [] }
		}
		return null
	}
}







// === ACTIONS =====================================================================================
const actions = {

	set_progress({ commit }, data) {
		commit('EDIT_PROGRESS_STATE', data)
	},

	set_progress_act({ commit }, data) {
		commit('EDIT_PROGRESS_ACT', data)
	},


	async load_polling_results({ commit, rootGetters, state }) {
		const t = rootGetters.game.room.timer_total - rootGetters.game.room.timer
		state.questions.forEach(async (question) => {
			if (question.type !== 'Solo' && question.timer + question.timeout < t) {
				let result = await Apollo.query('GET_POLLING_RESULT', { room_id: parseInt(rootGetters.game.room.id), question_id: parseInt(question.id), calculate: true })
				if (result) {
					result.result_answer = question.answer.find((a) => { return parseInt(a.id) === parseInt(result.result) })
					commit('EDIT_PROGRESS_QUESTION', {
						question,
						majority_answer: result
					})
				}
			}
		})
	},


	async send_answer({ commit, rootGetters, state }, data) {
		if (data.question && data.answer && data.answer.id) {
			const answer_id = parseInt(data.answer.id)
			let r = await Apollo.mutate('SET_PLAYER_CHOICE', { id: parseInt(rootGetters.logged_user.id), answer_id })
			if (r) {
				let selected_answers = [ ...state.selected_answers ]
				if (!selected_answers.includes(answer_id)) selected_answers.push(answer_id)
				commit('EDIT_PROGRESS_STATE', { selected_answers })
				localStorage.setItem('selected_answers_' + rootGetters.game.room.id, JSON.stringify(selected_answers))
			}
		}
	},


	async end_question({ rootGetters, state, commit }, data) {
		console.info('END QUESTION')
		if (data.question) {
			if (!state.majority_pulling || (state.majority_pulling.question && state.majority_pulling.question.id !== data.question.id)) {

				// Marquer la réponse par défaut comme choisi si non répondu (côté front uniquement)
				const selected_answer = data.question.answer.find((a) => { return state.selected_answers.includes(parseInt(a.id)) })
				if (!selected_answer) {
					let default_answer = data.question.answer.find((a) => { return a.default_for.includes(parseInt(rootGetters.logged_user.role.id)) })
					if (default_answer) {
						let selected_answers = [ ...state.selected_answers ]
						if (!selected_answers.includes(parseInt(default_answer.id))) {
							selected_answers.push(parseInt(default_answer.id))
							commit('EDIT_PROGRESS_STATE', { selected_answers })
							localStorage.setItem('selected_answers_' + rootGetters.game.room.id, JSON.stringify(selected_answers))
						}
					}
				}

				// Lancement du pulling de majorité si question de majorité
				if (data.question.type !== 'Solo') {
					const majority_pulling = { question: data.question }
					commit('EDIT_PROGRESS_STATE', { majority_pulling })
				}
			}
		}
	},


	async update_unit_details({ commit, rootGetters }) {
		if (rootGetters.game.scenario.enable_stats) {
			const summary = await Apollo.query('GET_OUTCOME_SUMMARY', { room_id: parseInt(rootGetters.game.room.id) })
			if (summary && summary.summary) {
				try {
					const unit_details = JSON.parse(summary.summary)
					commit('EDIT_PROGRESS_STATE', { unit_details })
				} catch (e) {
					console.error('UPDATE UNIT DETAILS', e)
				}
			}
		}
	}


	// FOR DEV ONLY
	// async load_all_questions_by_role({ rootGetters, commit }, data) {
	// 	let questions = await Apollo.query('ALL_QUESTIONS_BY_ROLES', { role_id: parseInt(data.user.role.id) })
	// 	commit('EDIT_PROGRESS_STATE', { questions })
	// },
	// END FOR DEV ONLY

	

}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_PROGRESS_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	EDIT_PROGRESS_ACT(state, data) {
		for (var i in data) {
			Vue.set(state.act, i, data[i])
		}
	},

	EDIT_PROGRESS_QUESTION(state, data) {
		if (data.question) {
			for (var i in data) {
				if (i !== 'question') Vue.set(data.question, i, data[i])
			}
		}
	},

}

export default { state, getters, actions, mutations }
