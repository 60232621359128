<template>
	<div class="tuto" @click="next">

		<div v-show="!highlight" class="bg"></div>
		<div v-show="highlight" class="highlight">
			<div class="hole" :style="highlightStyle"></div>
			<div class="outline" :style="highlightStyle"></div>
		</div>

		<transition name="box" mode="out-in">
			<div v-if="currentStep" :key="currentStepIndex" class="box" :class="[ currentStep.position || 'center' ]">
				<div class="bg"></div>
				<ui-text :text="currentStep.text" />
				<div class="next-button"><ui-icon name="chevron-down" :size="48" /></div>
			</div>
		</transition>

		<!-- <ui-skip-button @click="close" /> -->

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { wait } from '@/js/utils/utils'
import UiText from '@/components/ui/ui-text'

export default {
	data() {
		return {
			loading: false,
			currentStepIndex: 0,
			highlight: null,
			// paused_time: null,
		}
	},
	props: {
		tuto: { type: Object, required: true }
	},
	computed: {
		// ...mapGetters([ 'game' ]),
		steps() {
			return this.tuto?.steps || []
		},
		currentStep() {
			return this.steps?.[this.currentStepIndex] || null
		},
		isLast() {
			return this.currentStepIndex >= this.steps.length - 1
		},
		highlightStyle() {
			if (this.highlight) {
				const border = 12
				return {
					left: (this.highlight.x - border) + 'px',
					top: (this.highlight.y - border) + 'px',
					width: (this.highlight.width + border * 2) + 'px',
					height: (this.highlight.height + border * 2) + 'px'
				}
			}
			return {}
		},
	},
	watch: {
		currentStepIndex: {
			handler() {
				this.highlight = null
				if (this.currentStep.highlight) {
					setTimeout(() => {
						const el = document.querySelector(this.currentStep.highlight)
						if (el) {
							this.highlight = el.getBoundingClientRect()
						}
					}, 300)
				} else
					this.highlight = null
					
			},
			immediate: true
		}
	},
	methods: {
		async next() {
			if (!this.loading) {
				this.loading = true
				if (this.isLast) {
					this.close()
				}
				else {
					if (this.currentStep.highlight_tap) {
						const el = document.querySelector(this.currentStep.highlight_tap)
						if (el) {
							if (el.hammerTapRef) {
								el.hammerTapRef()
							}
							else if (el.hammer && el.hammer.handlers && el.hammer.handlers.tap) {
								el.hammer.handlers.tap[0]({ srcEvent: {} })
							} else {
								el.click()
							}
						}
					}
					this.currentStepIndex++
					await wait(300)
					this.loading = false
				}
			}
		},
		close() {
			// if (this.paused_time) {
			// 	const minimum_time = 5000
			// 	const remaining = Date.now() - this.paused_time
			// 	setTimeout(() => {
			// 		// this.$store.dispatch('restart_game_session', { room_id: this.game.room.id })
			// 		this.clickPauseButton()
			// 	}, remaining > minimum_time ? 0 : minimum_time - remaining)
			// }
			this.$emit('close', this.tuto)
		},
		keyup(e) {
			if ([ 'Enter', 'Space', 'NumpadEnter' ].includes(e.code)) {
				this.next()
			}
			if ([ 'Escape' ].includes(e.code)) {
				this.close()
			}
		},
		// clickPauseButton() {
		// 	const el = document.querySelector('#timer-paused-button')
		// 	if (el) {
		// 		if (el.hammerTapRef) {
		// 			el.hammerTapRef()
		// 		}
		// 		else if (el.hammer && el.hammer.handlers && el.hammer.handlers.tap) {
		// 			el.hammer.handlers.tap[0]({ srcEvent: {} })
		// 		} else {
		// 			el.click()
		// 		}
		// 	}
		// }
	},
	mounted() {
		if (this.tuto.slug === 'decision') this.$store.commit('EDIT_CHIRP_STATE', { route: null })
		this.$store.commit('EDIT_APP_STATE', { info_boxes: [] })
		window.addEventListener('keyup', this.keyup)

		// if (this.game.room.state !== 'paused') {
		// 	this.paused_time = Date.now()
		// 	this.clickPauseButton()
		// 	// this.$store.dispatch('change_room_state', { room_id: this.game.room.id, state: 'paused' })
		// }
	},
	beforeUnmount() {
		window.removeEventListener('keyup', this.keyup)
	},
	components: { UiText }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'
	
.tuto
	position fixed
	left 0
	top 0
	right 0
	bottom 0
	z-index 30

	&.tuto-enter-active
		transition 0.15s easeOutQuart
		pointer-events none
		.box
			transition transform 0.15s easeOutBack, opacity 0.15s easeOutQuart
		.ui-skip-button
			transition 0.15s easeOutBack
	&.tuto-enter
		opacity 0
		.box
			transform scale(0.5)
			opacity 0
		.ui-skip-button
			transform translateX(110%)

	&.tuto-leave-active
		transition 0.15s easeInQuart
		pointer-events none
		.box
			transition 0.15s easeInQuart
		.ui-skip-button
			transition 0.15s easeInQuart
	&.tuto-leave-to
		opacity 0
		.box
			transform translateY(32px)
		.ui-skip-button
			transform translateX(110%)

	> .bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color var(--color-tuto-bg)

	.highlight
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		.hole
			position absolute
			box-shadow 0 0 0 9999px var(--color-tuto-bg)
			transition 0.35s easeOutQuart
			border-radius 16px
		.outline
			position absolute
			border-radius 16px
			box-shadow 0 0 8px 8px var(--color-tuto-highlight)
			animation outline 1s linear infinite alternate
			transition 0.35s easeOutQuart
			@keyframes outline
				100%
					opacity 0.5

	.box
		position absolute
		width 500px
		height 192px
		padding 32px 48px
		font-size 1.8rem
		text-align center
		flex center center
		color var(--color-tuto-box-text)

		&.top-center
			top 64px
			left calc(50% - 250px)
		&.bottom-center
			bottom 64px
			left calc(50% - 250px)
		&.left-center
			left 64px
			top calc(50% - 96px)
		&.right-center
			right 64px
			top calc(50% - 96px)
		&.center
			left calc(50% - 250px)
			top calc(50% - 96px)
		
		&.box-enter-active
			transition transform 0.15s easeOutBack, opacity 0.15s easeOutQuart
		&.box-enter
			opacity 0
			transform translateY(-32px)
		&.box-leave-active
			transition transform 0.15s easeInQuart, opacity 0.15s easeInQuart
		&.box-leave-to
			opacity 0
			transform translateY(32px)
		
		> .bg
			position absolute
			left 0
			top 0
			right 0
			bottom 0
			background center center no-repeat
			background-size 100% 100%
			border-radius 8px
			box-shadow 0 0 8px var(--color-tuto-box-shadow)
			// border 4px solid var(--color-infobox-content-border)
			background-color var(--color-tuto-box-bg)
		.next-button
			position absolute
			bottom 16px
			right 16px
			width 32px
			height 32px
			flex center center
			animation down 1s easeInQuart infinite alternate 0.5s
			@keyframes down
				0%
					transform translateY(0)
				100%
					transform translateY(6px)

</style>