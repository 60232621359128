<template>
	<div class="single-decision" :class="{ expanded, pending }">
		<div class="head">
			<div class="head-content">
				<div class="question-text">{{ question.question }}</div>
				<div class="infos">
					<div class="info">
						<div class="label">Decision type</div>
						<div class="value">{{ question.type }}</div>
					</div>
					<div class="info participants">
						<div class="label">Participant<span v-if="participants.length > 1">s</span></div>
						<div class="value">{{ participants_names ? participants_names : '?' }}</div>
					</div>
					<!-- <div class="info">
						<div class="label">Start</div>
						<div class="value">{{ timer }}</div>
					</div>
					<div class="info">
						<div class="label">Duration</div>
						<div class="value">{{ timeout }}</div>
					</div> -->
				</div>
			</div>
			<div v-show="!expanded" class="head-buttons">
				<div class="button" :class="{ loading }" v-hammer:tap="expand">
					<span v-if="pending" class="text">Pending</span>
					<ui-icon v-if="loading || pending" name="button-loader" :size="40" />
					<span v-if="!loading && !pending" class="text">View</span>
				</div>
			</div>
		</div>
		<div v-if="expanded" class="content">

			<div v-if="winning_answer" class="winning-vote">
				<div class="title">Winning vote</div>
				<div class="answers">
					<div class="answer-content">
						<!-- <div class="answer"><ui-icon name="check-2" :size="16" /> {{ winning_answer.result_answer.answer }}</div> -->
						<div class="users">
							<vote v-for="player in answer_users[winning_answer.result]" :tiebreaker="parseInt(player.role.id) === tiebreaker" :player="player" :key="player.id" />
						</div>
					</div>
				</div>
				<br>
				<div v-if="winning_answer.result_answer && winning_answer.result_answer.feedback" class="title">Outcome</div>
				<p v-if="winning_answer.result_answer && winning_answer.result_answer.feedback">{{ winning_answer.result_answer.feedback }}</p>
			</div>

			<div class="losing-votes">
				<div class="title">Not chosen</div>
				<div v-if="losing_answers.length > 0" class="answers">
					<div v-for="answer in losing_answers" :key="answer.id" class="answer-content">
						<div class="answer"><ui-icon name="close-blue-lighter" :size="14" /> {{ answer.answer }}</div>
						<div v-if="answer_users[answer.id]" class="users">
							<vote v-for="player in answer_users[answer.id]" :player="player" :key="player.id" />
						</div>
						<div v-else class="no-answers">No vote for this answer</div>
					</div>
				</div>
			</div>
			<div class="hide-button" v-hammer:tap="() => expanded = false">Hide</div>
		</div>
		<div v-if="expanded" class="close-button" v-hammer:tap="() => expanded = false"><ui-icon name="close" :size="20" /></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import Vote from '@/components/master/room/game-access/decisions/vote'

export default {
	name: 'single-decision',
	data() {
		return {
			loading: false,
			expanded: false
		}
	},
	props: {
		question: { default: null }
	},
	computed: {
		...mapGetters([ 'game', 'progress' ]),
		pending() {
			const t = this.game.room.timer_total - this.game.room.timer
			return this.question.day === this.game.room.episode.episode_number && this.question.timer <= t && this.question.timer + this.question.timeout >= t
		},
		participants() {
			return this.game.players.filter((p) => p.role && (this.question.deciders.includes(parseInt(p.role.id)) || this.question.contributors.includes(parseInt(p.role.id))))
		},
		participants_names() {
			return this.participants.map((p) => { return p.student_name ? p.student_name : 'NPC' }).join(', ')
		},
		timer() {
			let s = this.question.timer
			return (s - ( s %= 60 )) / 60 + (9 < s ? ':' : ':0') + s
		},
		timeout() {
			let s = this.question.timeout
			return (s - ( s %= 60 )) / 60 + (9 < s ? ':' : ':0') + s
		},
		winning_answer() {
			if (this.question.majority_answer) return this.question.majority_answer
			return null
		},
		losing_answers() {
			if (this.winning_answer) return this.question.answer.filter((a) => parseInt(a.id) !== parseInt(this.winning_answer.result))
			return []
		},
		answer_users() {
			let user_groups = {}
			if (this.winning_answer.details) {
				this.winning_answer.details.forEach((d) => {
					if (!user_groups[d.answer]) user_groups[d.answer] = []
					user_groups[d.answer].push(this.participants.find((p) => parseInt(p.role.id) === parseInt(d.role_id)))
				})
			}
			return user_groups
		},
		tiebreaker() {
			const nb_winning_users = this.answer_users[this.winning_answer.result].length
			const n = Object.values(this.answer_users).filter((g) => g && g.length > 0 && g.length === nb_winning_users).length
			return n > 1 ? this.winning_answer.tiebreaker : null
		}
	},
	methods: {
		async expand() {
			if (!this.pending) {
				this.loading = true
				let result = await Apollo.query('GET_POLLING_RESULT', { room_id: parseInt(this.game.room.id), question_id: this.question.id, calculate: false })
				if (this.question.type === 'Solo' || this.question.type === 'Algorithmic') result.result = result.answers[0]
				result.result_answer = this.question.answer.find((a) => { return parseInt(a.id) === parseInt(result.result) })
				this.$store.commit('EDIT_PROGRESS_QUESTION', {
					question: this.question,
					majority_answer: result
				})
				this.expanded = true
				this.loading = false
			}
		}
	},
	components: { Vote }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/variables'


.single-decision
	background-color #00374D
	margin 0 0 16px 0
	border-radius 4px
	color blue_lighter
	border 2px solid transparent
	&.expanded
		margin 0 0 48px 0
		border-color blue_lighter
		.head .head-content
			width 100%
			.question-text
				padding-right 48px
	&.pending
		background-color darken(@background-color, 50%)
		.head
			.head-buttons
				.button
					flex-direction column
					// line-height 32px
					background-color darken(#00374D, 25%)
					cursor wait
					&:hover, &:active
						transform none
						background-color @background-color
					>>> .ui-icon
						// transform translateY(-8px)
						height 20px !important
	.head
		padding 16px
		flex flex-start flex-start
		.head-content
			width calc(100% - 150px)
			.question-text
				padding 8px 0 16px 0
				margin 0 0 8px 0
				border-bottom 1px solid blue_lighter
				font-size 1.6rem
				line-height 20px
			.infos
				.info
					flex flex-start flex-start
					min-height 32px
					margin 0 0 4px 0
					line-height @height
					// white-space nowrap
					&:last-child
						margin 0
					&.participants
						.value
							width calc(100% - 150px)
							background-color darken(@background-color, 25%)
							border-radius 4px
							// overflow hidden
					.label
						width 150px
						opacity 0.5
						padding 4px 0
					.value
						padding 4px 12px
		.head-buttons
			position absolute
			right 16px
			bottom 8px
			width 120px
			.button
				flex center center
				background-color #074E6B
				border-radius 4px
				height (48px + 8px + 1px)
				// line-height (@height + 4px)
				text-align center
				text-transform uppercase
				color #fff
				font-size 1.6rem
				font-family var(--josefin)
				cursor pointer
				&:hover
					background-color lighten(#074E6B, 10%)
				&:active, &.loading
					transform translateY(4px)
					background-color darken(#074E6B, 40%)
				&.loading
					cursor wait
				.text
					padding-top 4px
	.content
		.winning-vote
			background-color #002E43
		.winning-vote, .losing-votes
			padding 16px
			.title
				padding 0 0 4px 0
				margin 0 0 16px 0
				border-bottom 1px solid blue_lighter
				font-size 1.6rem
				line-height 20px
				text-transform uppercase
			.answers
				.answer-content
					margin 0 0 16px 0
					&:last-child
						margin 0
					.answer
						flex flex-start flex-start
						line-height 20px
						>>> .ui-icon
							margin 0 8px 0 0
							transform translateY(3px)
							flex-shrink 0
					.users
						padding 0 0 0 24px
					.no-answers
						padding 0 0 0 24px
						opacity 0.5
		.hide-button
			position absolute
			left 50%
			bottom -32px
			height 32px
			line-height 32px
			padding 0 16px
			transform translateX(-50%)
			white-space nowrap
			flex center center
			text-transform uppercase
			border-radius 0 0 4px 4px
			color blue_dark
			font-weight 700
			background-color blue_lighter
			cursor pointer
			&:before
				content ''
				margin 0 4px 0 0
				width 0
				height 0
				border-style solid
				border-width 0 8px 8px 8px
				border-color transparent transparent blue_dark transparent

	.close-button
		position absolute
		right 16px
		top 16px
		cursor pointer

</style>
