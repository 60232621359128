<template>
	<div class="main-loader-screen">
		<div class="main-loader-screen-content">
			<!-- <p v-show="!logged_user" class="text">Logging in...</p> -->
			<p class="text">Loading...</p>
			<div class="loading"><ui-icon name="button-loader" :size="64" /></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'main-loader-screen',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'app', 'logged_user' ])
	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


.main-loader-screen
	absolute 0
	position fixed
	background-color var(--color-bg)
	z-index 3
	padding 32px
	user-select none
	cursor default
	color var(--color-primary)
	.main-loader-screen-content
		position absolute
		left 50%
		top 50%
		padding 32px
		width 90%
		max-width 500px
		transform translate(-50%, -50%)
		text-align center
		font-weight 700
		font-family var(--josefin)
		text-transform uppercase
		font-size 2rem
		line-height 24px
		.loading
			display block
			



</style>
